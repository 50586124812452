.ps-dropdown--fullscreen {
    .ps-dropdown__content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        z-index: 999;
        padding: 30px 0;
        background-color: #fff;
        @include hidden;
        transform: scale3d(1, 1, 0);
        transition: all 0.4s ease;
    }

    &:hover {
        .ps-dropdown__content {
            @include show;
            transform: scale3d(1, 1, 1);
        }
    }
}

.ps-dropdown-toggle {
    &:before {
        content: '';

    }
}
