.ps-checkbox {
  position: relative;
  display: block;

  > input {
    position: absolute;
    visibility: hidden;
  }

  label {
    margin-bottom: 0;
    position: relative;
    padding-left: 24px;
    color: $color-1st;
    font-weight: 400;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      height: 16px;
      width: 16px;
      z-index: 10;
      border-radius: 4px;
      border: 1px solid #d3d9e5;
      transition: all 0.4s ease;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: 2px solid #fff;
      border-top: none;
      border-left: none;
      z-index: 10;
      opacity: 0;
      @include transform(rotate(0deg));
      transition: all 0.4s ease;
    }
  }

  input[type="checkbox"]:checked ~ label {
    &:before {
      background-color: $color-1st;
      border-color: $color-1st;
    }

    &:after {
      @include transform(rotate(45deg));
      opacity: 1;
    }
  }

  &.color {
    label {
      padding: 0;
      width: 25px;
      height: 25px;

      &:before {
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: var(--bgColor);
        border: none;
      }

      &:after {
        top: 6px;
        left: 10px;
        width: 6px;
        height: 12px;
      }
    }

    input[type="checkbox"]:checked ~ label {
      &:before {
        background-color: var(--bgColor);
      }
    }
  }
}

.ps-rating {
  i {
    margin-right: 4px;
    font-size: 14px;

    &:last-child {
      margin-right: 0;
    }

    &.fa-star {
      color: $color-orange-new;
    }

    &.empty {
      color: rgba($color-1st, 0.2);
    }
  }

  .total {
    display: inline-block;
    padding-left: 10px;
    font-size: 12px;
  }
}

.form-group {
  margin-bottom: 20px;
}
.ps-form--review .ps-form__input,
.ps-form--review .ps-form__textarea {
  border-radius: 5px;
}
