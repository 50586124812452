.box {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  width: 475px;
}

.box-rounded {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 15px;
  width: 475px;
}

.card-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 15px !important;
}

.menu-card-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
}

//Position

.block-center {
  display: flex;
  justify-content: center;
}

// Padding and Margin

.padding-30 {
  padding: 30px;
}

.padding-left-55 {
  padding-left: 55px;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-right-55 {
  padding-right: 55px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-15-per {
  padding-top: 15%;
}

.padding-top-7-per {
  padding-top: 7%;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-8 {
  padding: 8px;
}

.p-15 {
  padding: 15px;
}

.p-30 {
  padding: 30px;
}

.p-20 {
  padding: 20px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.margin-50 {
  margin: 50px;
}

.margin-horizontal-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pt-60 {
  padding-top: 60px;
}

.margin-left-40-minus {
  margin-left: -40px !important;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-45 {
  margin-top: 45px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-7 {
  margin-left: 7px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.color-red {
  color: $color-danger !important;
}

.mr-33 {
  margin-right: 33px;
}

.ml-33 {
  margin-left: 33px;
}

.mr-23 {
  margin-right: 23px;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.m-15 {
  margin: 15px;
}

.m-10 {
  margin: 10px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

//Width

.width-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.width100 {
  width: 100px !important;
}

.width-190 {
  width: 190px !important;
}

.width-30 {
  width: 30px;
}

.width-120 {
  width: 120px;
}

.width-15-per {
  width: 15% !important;
}

.width-10-per {
  width: 10% !important;
}

.width-20-per {
  width: 20% !important;
}

.width-30-per {
  width: 30% !important;
}

.width-40-per {
  width: 40% !important;
}

.width-50-per {
  width: 50% !important;
}

.width-60-per {
  width: 60% !important;
}

.width-17-per {
  width: 17% !important;
}

.width-100-per {
  width: 100% !important;
}

.max-width-75-per {
  max-width: 75% !important;
}

.width-75-per {
  width: 75% !important;
}

.width-150 {
  width: 150px;
}

.max-width-unset {
  max-width: unset !important;
}

.width-unset {
  width: unset !important;
}

.max-width-45-per {
  max-width: 45%;
}

.max-width-400 {
  max-width: 400px;
}

//height

.height-75-per {
  height: 75%;
}

.height-90-per {
  height: 90%;
}

.height-100-per {
  height: 100%;
}

.height-100 {
  height: 100px;
}

.max-width-13-per {
  max-width: 13%;
}

.height-265 {
  height: 265px;
}

.paragraph {
  font-size: 18px;
  font-weight: 200;
}

// Button

.social-btn {
  line-height: 1.3333333;
  padding: 10px 16px;
  border-radius: 5px;
  border: 1px solid $color-1st;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.social-button {
  max-width: 100% !important;
  width: 100% !important;
}

.icon {
  height: 25px;
  width: 25px;
}

.fb.icon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  padding: 7px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

//hide or show
.hide {
  display: none !important;
}

.show {
  display: block;
}

// login name

.display-flex {
  display: flex;
}

.login-username {
  border: 1px solid lightgrey;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 10px;
  padding-top: 4px;
  /* margin-top: 0px; */
  align-self: center;
  // padding-left: 5px;
  font-size: 13px;
  width: 28px;
  height: 28px;
  text-align: center;
}

// disabled

.disabled {
  pointer-events: none;
}

// loader

.form-loader .ps-section__loading {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #103178;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.form-loader .ps-section__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: cssAnimation 0s ease-in 1s forwards;
  animation-fill-mode: forwards;
}

.form-loader .ps-section__overlay_delayed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: cssAnimation 0s ease-in 60s forwards;
  animation-fill-mode: forwards;
}

// color
.ps-status.outstock {
  color: red;
}

.border-color-red {
  border: 1px solid red;
}

.disable-btn-color {
  background-color: #ff992361;
  border: 1px solid #ffffff;
}

.disable-btn {
  cursor: not-allowed !important;
}

.background-color-orange-new {
  background-color: $color-orange-new;
}

.color-unset {
  color: unset !important;
}

.color-blue {
  color: #103178 !important;
}

.color-yellow {
  color: #ff9923;
}

.color-green {
  color: #12a05c;
}

.color-white {
  color: white !important;
}

// boder
.b-1 {
  border: 1px solid lightgray;
}

.border-bottom-unset {
  border-bottom: unset !important;
}

.bb-1 {
  border-bottom: 1px solid lightgrey;
}

.bt-1 {
  border-top: 1px solid lightgrey;
}

.b-1 {
  border: 1px solid #f0f2f5 !important;
}

.br-5 {
  border-radius: 5px;
}

.ps-product--grid p.outstock {
  margin-top: 10px;
  font-size: 10px;
  text-align: left;
  border: 1px solid;
  border-radius: 5px;
}

// navigation-left
.navigation__leftmenu {
  padding: 0px 0px 0px 0px;
  list-style-type: none;
}

.navigation__leftmenu > li > a {
  position: relative;
  display: inline-block;
  padding: 15px 20px 15px 25px;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  color: #103178;

  text-transform: capitalize;
  transform-style: preserve-3d;
}

.navigation__leftmenu > li {
  border-bottom: 1px solid #d3d3d361;
}

.navigation__leftmenu > li:last-child {
  border-bottom: 0;
}

.navigation-menu {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.navigation__leftmenu > li > .active {
  color: #ff9923;
}

// ---------------------------------------------
/* Profile container */
.profile {
  margin: 20px 0;
}

/* Profile sidebar */
.profile-sidebar {
  padding: 20px 0 0px 0;
  background: #fff;
}

.profile-userpic img {
  float: none;
  display: block;
  margin: auto;
  width: 50%;
  height: 50%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #103178;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b6c8f;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}

.profile-usermenu {
  margin-top: 30px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #103178;
  font-size: 16px;
  font-weight: 200;
  padding-top: 15px;
  padding-bottom: 15px;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
  color: #5b6c8f;
}

.profile-usermenu ul li.active {
  border-bottom: none;
}

.profile-usermenu ul li.active a {
  color: #5b6c8f;
  background-color: #f6f9fb;
  border-left: 2px solid #5b6c8f;
  margin-left: -2px;
}

/* Profile Content */
.profile-content {
  padding: 20px;
  background: #fff;
  min-height: 460px;
}

.nav > li {
  position: relative;
  display: block;
}

//---------------------------------------------
.background-color-white {
  background-color: white;
}

.position-fixed {
  position: fixed;
}

.header-myaccount {
  position: fixed;
  z-index: 5;
  background-color: white;
  width: 100%;
}

.profile-user-img {
  float: none;
  display: block;
  margin: auto;
  width: 30%;
  height: 30%;
}

// font size

.font-size-16 {
  font-size: 16px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

// ---------------model---------------

.btn-close {
  display: block;
  width: 15px;
  height: 10px;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      #103178 46%,
      #103178 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #103178 46%, #103178 56%, transparent 56%, transparent
          100%);
  transition: all 0.3s ease;
  border: none;
  margin-top: 10px;
}

.radio-button {
  height: 20px;
  flex: 0.2 0.2 !important;
}

.radio-button-gender {
  height: 20px;
  flex: 0.1 0.2 !important;
}

.gender-radio label {
  padding-right: 15px;
  padding-left: 5px;
}

.address-radio label {
  padding-right: 15px;
  padding-left: 5px;
}

.card .card-body p {
  font-size: 15px;
  color: #5b6c8f;
}

#myaddress .card-header {
  border-bottom: unset;
}

#myaddress .card {
  box-shadow: rgba(0, 0, 0, 0) 0px 2px;
  min-height: 215px;
}

.tag-success {
  background-color: #37bc9b;
}

.tag {
  display: inline-block;
  padding: 0.35em 0.4em;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.18rem;
  padding: 5px;
  /* margin: 5px; */
  min-width: 80px;
}

.dark-blue {
  color: #103178;
}

.address-card {
  padding: 1.25rem;
}

.hidden {
  display: none !important;
}

.status-green {
  color: #298f31;
}

.order-image {
  height: 90px;
  width: auto;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.align-self-center {
  align-self: center;
}

.order-summary {
  border-radius: 4px;
  padding: 10px;
}

.order-total {
  border-top: 1px solid #f0f2f5;

  margin: 0;

  margin-top: 10px;
}

.ps-text--order {
  margin-bottom: 0;
  font-size: 15px;
}

.border-radius-50 {
  border-radius: 50px;
}

// ---------------start order status timeline css-------------------------
ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li:before {
  content: "\2713";
  background: #fff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 0;
  left: 5px;
  width: 50px;
  height: 50px;
  z-index: 400;
  text-align: center;
  line-height: 50px;
  color: #d4d9df;
  font-size: 24px;
  border: 2px solid #d4d9df;
  color: green;
  font-weight: 800;
}

ul.timeline > li.active:before {
  content: "\2713";
  background: #28a745;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 0;
  left: 5px;
  width: 50px;
  height: 50px;
  z-index: 400;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 30px;
  border: 2px solid #28a745;
}

// ---------------end order status timeline css-------------------------

.order-pading {
  padding-top: 5px;
  padding-bottom: 5px;
}

.order-list-image {
  height: auto !important;
  width: 50px !important;
}

.ps-order-view-btn {
  font-size: 15px !important;
  padding: 7px 10px !important;
}

.order-list {
  margin-right: 50px;
  margin-bottom: 50px;
}

.order-status-green {
  color: #28a745 !important;
}

.order-basic-detail {
  padding: 15px;
  border-bottom: 1px solid #f0f2f5;
  margin-bottom: 15px;
}

.order-product-img .middle {
  transition: 0.5s ease;
  /*opacity: 0;*/
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-more {
  opacity: 0.3;
}

.order-product-img .text {
  background-color: transparent;
  color: black;
  font-size: 13px;
  padding: 20px 20px;
}

.order-box-rounded {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 15px;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.order-status-danger {
  color: #dc3545 !important;
}

.br-1 {
  border-right: 1px solid #f0f2f5;
}

.order-status-green-lable {
  cursor: alias;
  font-size: 18px;
  text-transform: capitalize;
  padding: 5px 10px;
  max-width: 210px;
  border-color: #12a05c;
  background-color: #12a05c;
  color: white;
  display: inline-block;
  font-weight: 300;
  border-radius: 5px;
  box-shadow: none;
  text-shadow: none;
  text-align: center;
  line-height: 24px;
  transition: all 0.4s ease;
}

.box-rounded-login {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 15px;

  .form-padding {
    padding: 30px;
    align-self: center;
  }

  .side-image {
    display: block;
  }
}

.login .active {
  border: 1px solid #ff9923;
}

.footer-padding {
  // padding-left: 100px;
  // padding-right: 100px;
  padding-top: 100px;
}

.ps-drawer__footer {
  .header__action {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    color: #ff9923;
    font-size: 22px;

    &-badge {
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: 22px;
      height: 22px;
      font-size: 11px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #103178;
      color: white;
    }
  }

  .header__actions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
  }
}

.ps-account .ps-form__group .toogle-password {
  min-width: 30px;
  padding-right: 10px;
  background-color: #f0f2f5;
  display: inline-flex;
  align-items: center;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  font-size: 14px;
}

.bn {
  border: none !important;
}

.list-image {
  padding: 0px 15px 0px 15px;
}

.ps-table--with-border thead tr th {
  border-bottom: unset;
}

#table-wishlist tbody tr td:nth-child(2) {
  width: 0px !important;
}

#table-wishlist tbody tr td {
  vertical-align: middle;
  width: 0px;
  text-align: center;
}

#table-wishlist tbody tr td:last-child {
  width: 0px;
}

#table-wishlist tbody tr td:first-child {
  width: 40px;
  text-align: center;
  padding: 0.75rem;
}

#myaddress .card-header {
  border-radius: 15px !important;
}

.ps-cart--primary .ps-cart__coupon input {
  margin-right: 10px;
  border-radius: unset;
  height: 46px;
  border-color: #f0f2f5;
  background-color: #f0f2f5;
}

.ps-table--shopping-cart tbody tr {
  border: none;
  border-bottom: 1px solid #f0f2f5;
}

#orderitem tbody tr:last-child {
  border: none;
  border-bottom: unset;
}

.product-search {
  background-color: #f0f2f5;
  padding: 10px;
  color: #5b6c8f;
  padding-top: 10px;
  margin-top: 10px;
  position: absolute;
  z-index: 1000;
  width: 490px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 15px;
  overflow-y: auto;
}

.product-search li {
  padding: 10px;
  border-bottom: 1px solid lightgray;
  font-size: 12px;
}

.product-search li:last-child {
  border-bottom: none;
}

.reviewform {
  padding: 30px;
  border: 1px solid #f0f2f5;
  border-radius: 4px;
}

.text-line-through {
  text-decoration-line: line-through;
}

// ----------------------product variant radio button---------------

.radio_container {
  // display: flex;
  align-items: center;
}

.radio_container input[type="radio"] {
  appearance: none;
  display: none;
}

.radio_container label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 18px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: #103178;
  border-radius: 30px;
  border: 1px solid #f0f2f5;
  cursor: pointer;
}

.radio_container input[type="radio"]:checked + label {
  border-color: #103178;
}

.ps-checkbox {
  z-index: 0 !important;
}

// ---------------filter tag css------------
.tags-input-container {
  padding: 15px;
  border-radius: 3px;
  // margin-top: s1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: #f0f2f5;
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  color: #103178;
}

.tag-item .remove {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 15px;
  cursor: pointer;
  // color: #9babcd;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

// ---------------end filter tag css------------

.transition-none {
  transition: width 2s !important;
}

#orderitem tbody tr td {
  padding-top: 13px;
  // padding-bottom: 10px;
}

.color-lightblue {
  color: #9babcd !important;
}

.ant-slider-track {
  background-color: #103178 !important;
}

.ant-slider-handle {
  border: 2px solid #103178 !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #103178 !important;
}

.modal-open {
  overflow: hidden !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modelbody {
  height: 425px;
  overflow-y: scroll;
}

// ------------ collapse css---------------

.openfilter {
  opacity: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  transition: opacity 1s;

  display: block;
}

.openfilter.fade {
  opacity: 0;
  animation: cssAnimation 0s 5s forwards;
  display: none;
}

.filtergroup {
  max-height: 200px;

  overflow-y: auto;
  overflow: hidden;
}

.filtergroup:hover {
  overflow-y: auto;
}

.filtergroup::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.filtergroup::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.filtergroup::-webkit-scrollbar-thumb {
  background: rgb(196, 195, 195);
}

/* Handle on hover */

.filtergroup::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filtergroupli {
  margin-bottom: 40px;
  border-bottom: 1px solid #f0f2f5;
  padding-bottom: 36px;
}

// ------------ end collapse css---------------
.modelbody {
  overflow-y: scroll;
  margin-bottom: 25px;
}

.modelbody::-webkit-scrollbar {
  width: 10px;
}

.warningbtn {
  border: 1px solid #ff9923;
  background-color: white;
  color: #ff9923;
  font-size: 18px;
  text-transform: capitalize;

  max-width: 210px;
  display: inline-block;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
  text-align: center;
  padding: 10px 25px;
  line-height: 24px;
  font-size: 16px;
  transition: all 0.4s ease;
}

.icon-image {
  height: 18px;
  width: auto;
}

.primarybtn {
  border: 1px solid #103178;
  background-color: white;
  color: #103178;
  font-size: 18px;
  text-transform: capitalize;
  padding: 10px 25px;
  max-width: 210px;
  display: inline-block;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
  text-align: center;
  padding: 10px 25px;
  line-height: 24px;
  font-size: 16px;
  transition: all 0.4s ease;
}

.ps-cart--primary .ps-cart__actions {
  display: unset;
}

.display-unset {
  display: unset !important;
}

.carttable {
  border-left: unset !important;
  border-right: unset !important;
}

.ps-cart-coupon {
  display: flex;
  grid-template-columns: 1fr 392px;
  grid-gap: 30px;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.filtergroup .ps-checkbox label {
  padding-top: 4px !important;
}

.shopaction-filter {
  border-radius: 50px;
}

.title-font {
  font-weight: 200 !important;
  color: #103178 !important;
}

.price-font {
  font-size: 14px;
  font-weight: 400;
}

.ps-table--shopping-cart tbody tr td {
  border: none;
  border-bottom: unset !important;
}

.cart-font-title {
  font-weight: 200 !important;
  color: #103178 !important;
  font-size: 16px;
}

.productreview {
  border: none;
  border-bottom: 1px solid #d9dee8 !important;
  padding: 25px 20px 20px 20px !important;
}

.order-summary-title {
  font-size: 15px;
  font-weight: 600;
}

.order-title-font {
  font-weight: 200 !important;
  font-size: 16px;
}

.removecart {
  position: absolute;
  top: -12px;
  left: -39px;
}

.widget-filter {
  margin-bottom: unset !important;
  padding-bottom: unset !important;
  border-bottom: unset !important;
}

.ps-review--product .ps-review__desc {
  padding-left: 25px !important;
}

.ps-review__desc .title-font {
  word-break: break-word;
}

.color-gray-new {
  color: $color-gray-new;
}

.review-user-img {
  float: none;
  display: block;
  margin: auto;
  object-fit: cover;
  border-radius: 50%;
}

.producttitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.producttitle-breadcrumb {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow-auto {
  overflow: auto !important;
}

.wishlist {
  overflow: auto;
}

.order-status {
  margin-left: 25px;
  text-align: right;
  align-self: center;
}

.order-detail-btn {
  text-align: right;
  align-self: center;
}

.transaction {
  text-align: center;
  align-self: center;
}

.ps-mobile-cart .ant-drawer-content-wrapper {
  width: 400px;
}

.title-font p,
.title-font span {
  font-weight: 200 !important;
  color: #103178 !important;
}

.product--detail .price-font {
  font-size: 25px;
  font-weight: 400;
}

.contactus-addres {
  margin-top: 20px;
}

.ps-social--color .ps-social__link {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  display: block;
  border-radius: 50px;
}

.contactus-instagram {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: black !important;
}

.contactus-youtube {
  background-color: #cb2027;
}

.ps-table--shopping-cart tbody tr:last-child {
  border-bottom: unset;
}

.contactus-info {
  padding-top: 30px;
  padding-left: 35px;
  padding-right: 40px;
  padding-bottom: 70px;
}

.wishlist tbody tr:last-child td {
  border-bottom: unset;
}

.ps-table--shopping-cart tbody {
  border: unset;
  border-top: 1px solid #f0f2f5;
}

.ordersucess .ant-alert-icon {
  margin-right: 15px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.ps-table--with-border {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
  border-bottom: unset;
}

.react-simple-star-rating {
  margin-top: -5px;
  margin-right: 10px;
}

.price-percentage {
  font-size: 11px;
  margin-left: 15px;
  color: red;
  font-weight: 200 !important;
}

.content-card {
  width: 100vh;
}

.badge {
  line-height: 1.2;
  white-space: unset;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

#tablewishlist {
  border-bottom: unset !important;
}

.ps-table--shopping-cart .form-group--number input {
  max-width: unset;
  border-radius: 50px;
  height: 46px;
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  color: #103178;
  font-weight: 600;
  font-size: 14px;
}

.address-type {
  margin-left: 2px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.filtericon {
  padding-bottom: 2px !important;
  padding-right: 10px;
}

// -------------------faq css------------------
.panel-group .panel {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #eeeeee;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: #fafafa;
  border-color: #eeeeee;
}

.panel-title {
  font-size: 18px;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}

.more-less {
  float: right;
  color: #212121;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #eeeeee;
}

// -------------------end faq css------------------

#cartlist {
  border: none !important;
}

.word-break-all {
  word-break: break-all;
}

.homemenu.ps-drawer--with-menu .ps-drawer__wrapper {
  display: unset;
  grid-template-columns: unset;
  grid-template-rows: unset;
}

.homemenu {
  padding: 0 15px 30px 30px !important;
}

.mobile-header-swichers {
  padding: 35px 0 !important;
}

.profile-image {
  height: 40px;
  width: auto;
}

.mobile-menu-card {
  padding: 20px 20px 0px 10px;
}

.order-status-date {
  font-size: 12px;
}

.cursor-alias {
  cursor: alias !important;
}

.react-date-picker__wrapper {
  border: none;
}

.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: unset !important;
  border-color: unset !important;
  border-radius: unset !important;
  border-style: unset !important;
  border-width: unset !important;
  box-sizing: border-box;
}

.css-1u9des2-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  background-color: unset !important;
  margin-bottom: 18px;
  margin-top: 8px;
  box-sizing: border-box;
}

.css-t3ipsp-control {
  background-color: unset !important;
  border-color: unset !important;
  border-radius: unset !important;
  border-style: unset !important;
  border-width: unset !important;
  box-shadow: unset !important;
  box-sizing: border-box;
}

.css-1dimb5e-singleValue {
  color: #5b6c8f !important;
}

.combo-select {
  padding: 3px 15px !important;
}

.css-1nmdiq5-menu {
  left: 0 !important;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.grecaptcha-badge {
  z-index: 4 !important;
}

.payment-image {
  width: 55px;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.paymentdiv {
  padding-left: 0px;
  padding-right: 0px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 25px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  // box-shadow: 2px 2px 3px #999;
  z-index: 100;
  border: 1px solid #25d366;
  text-align: center;
  align-self: center;
}

.back-top {
  right: 45px !important;
  bottom: 100px;
}

.ps-banner {
  min-height: 0;
  position: relative;
}

.ps-section--banner {
  min-height: 0;
}

.productimage {
  width: 50px !important;
  height: auto;
}

.ps-table--shopping-cart thead tr th:first-child {
  padding-left: 7px;
}

.show {
  display: block !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.max-width-245 {
  max-width: 245px !important;
}

.image-delete {
  position: absolute;
  padding-left: 5px;
}

.filterlable:before {
  top: 1px !important;
}

.filterlable:after {
  top: 2px !important;
}

.my-float {
  margin-top: 16px;
}

.m-5 {
  margin: 5px !important;
}

.left-unset {
  left: unset !important;
}

.menuul {
  display: inline-block;
  margin: 0 10px;
}

.menu--single li a {
  text-transform: capitalize;
}

.max-height-unset {
  max-height: unset !important;
}

.header--one .header__bottom {
  max-height: unset !important;
}

.ps-section--categories .ps-categories__link img {
  max-width: 45px;
}

.customercart {
  border: 1px solid #dbdada !important;
  border-radius: 15px !important;
}

.customer-home-image {
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.whyus-detail {
  font-size: 16px;
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.home-slider .slick-dots {
  visibility: unset;
  opacity: unset;
  // padding-top: 50px;
  padding-top: 30px;
}

.sliderimage img {
  max-height: 100px !important;
}

.error-404 {
  font-size: 150px !important;
}

.error-padding {
  padding-top: 10%;
  padding-bottom: 20%;
}

.btn-Left-Top {
  position: absolute;
  margin: 30px 70px;
  top: 10%;
  left: 0%;
  transform: translate(-50%, -50%);
}

.btn-Left-Middle {
  position: absolute;
  margin: 0px 160px;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
}

.btn-Left-Bottom {
  position: absolute;
  margin: 0px 70px;
  bottom: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
}

.btn-Center-Top {
  position: absolute;
  margin: 30px 0px;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-Center-Middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-Center-Bottom {
  position: absolute;
  margin: 0px 0px;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-Right-Top {
  position: absolute;
  margin: 30px -15px;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.btn-Right-Middle {
  position: absolute;
  margin: 0px 75px;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.btn-Right-Bottom {
  position: absolute;
  margin: 0px 0px;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.carousel-item .ps-banner a {
  opacity: 0;
  display: none;
}

.slick-active .carousel-item .ps-banner a {
  opacity: 1;
  display: block;
  z-index: 100000;
}

.popup-model .modal-dialog {
  margin: 200px auto;
}

.close-Right-Top {
  position: absolute;
  margin: 15px 15px;
  top: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.popup-model .modal-body {
  padding: 0;
}

.popupimage {
  height: 315px;
  width: auto;
}

.categoryimage {
  object-fit: cover;
}

.color-black {
  color: black !important;
}

.ant-menu-light .ant-menu-item-active {
  color: black !important;
  background-color: white;
}

.ant-menu-vertical .ant-menu-item:after {
  background-color: white;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: white;
}

.ant-dropdown .ant-dropdown-menu-item span {
  font-size: 14px !important;
  font-weight: 400;
}

.productdescription p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
strong,
label,
input i,
ul,
li,
ol {
  font-family: "Jost", sans-serif !important;
}

#homepage-one .ps-top-banners .slick-dots {
  z-index: 0;
}

// -----------------------change color css----------------
.header--desktop .header__action {
  color: #e31e29 !important;
}

.ps-btn.ps-btn--warning,
button.ps-btn.ps-btn--warning {
  border: 1px solid #e31e29;
  background-color: #e31e29;
  color: white;
}

.ps-btn,
button.ps-btn {
  &:hover {
    color: #e31e29;
  }
}

.ps-footer--block .ps-block__list li a:hover {
  color: #e31e29;
}

.ps-footer--contact .ps-footer__fax i {
  color: #e31e29;
}

.background-color-orange-new {
  background-color: #e31e29 !important;
}

.menu--desktop > li > a:hover {
  color: #e31e29;
}

.menu--single li a:hover {
  color: #e31e29;
}

.disable-btn-color {
  background-color: #ef0c0c61 !important;
  border: 1px solid #ffffff !important;
}

.ps-section--categories .ps-categories__name:hover {
  color: #e31e29;
}

.ps-product__title a:hover {
  color: #e31e29;
}

.ps-product--detail .ps-product__ecomerce-actions a:hover {
  color: #e31e29;
}

.ps-btn--primary:hover {
  background-color: #e31e29 !important;
  border-color: #e31e29 !important;
  color: #fff !important;
}

a:hover {
  color: #e31e29;
}

.warningbtn {
  border: 1px solid #e31e29;
  color: #e31e29;
}

.ps-btn.ps-btn--warning:hover,
button.ps-btn.ps-btn--warning:hover {
  background-color: white;
  color: #e31e29;
}

.ant-back-top .ps-btn--backtop:hover {
  background-color: #e31e29;
}

.ant-back-top .ps-btn--backtop:hover i {
  background-color: #e31e29;
  color: #fff !important;
}

.ps-product .ps-product__action:hover {
  background-color: #e31e29;
  color: white !important;
}

.ps-btn--orange,
button.ps-btn--orange {
  background-color: #e31e29;
  color: #fff;
  font-size: 16px;
}

.ps-btn:hover,
.ps-btn:active,
button.ps-btn:hover,
button.ps-btn:active {
  color: #e31e29;
  background-color: #fff;
  border: 1px solid #e31e29;
}

.navigation--bottom .navigation__item {
  text-align: center;
  color: #e31e29;
}

.ps-drawer__footer .header__action {
  color: #e31e29;
}

// --------------------end-----------------------
#header-sticky-mobile {
  border-bottom: 1px solid #f0f2f5;
}

.header--one .header__top {
  padding: 0px 0;
}

.header__center {
  padding: 15px;
}

.header--desktop .ps-logo img {
  max-height: 65px;
}

.header__bottom.active {
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
}

// --------------------------------
.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 1em 0 4em;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 220px;
  max-width: 388px;
  max-height: 360px;
  width: 32%;
  height: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 15px !important;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 1;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 200;
}

.grid figure h2 span {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 1s;
}

figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
}

figure.effect-goliath p {
  text-transform: none;
  font-size: 65%;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translate3d(0, 50px, 0);
  padding: 15px 5px !important;
  margin-left: 30px;
  margin-bottom: 20px;
}

figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0, -75px, 0);
  transform: translate3d(0, -75px, 0);
  padding-bottom: 0px;
}

figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.grid-figure-secound {
  width: 61% !important;
  min-width: 324px !important;
  max-width: 469px !important;
  max-height: 277px !important;
}

.grid-figure-last {
  width: 28% !important;
  min-width: 324px !important;
  max-width: 445px !important;
  max-height: 280px !important;
}

// ----------------------------------------
.producteffect .ps-category__item .ps-product__thumbnail {
  transition: all 1s;
}

.producteffect .ps-category__item .ps-product__thumbnail:hover {
  animation-name: bounce;
  animation-timing-function: ease;
  transform: translateY(-15px) !important;
  transition: all 600ms ease-in-out;
}

.producteffect .ps-product__actions {
  padding-top: 30px !important;
}

.cat-view-btn {
  border: 1px solid #e31e29;
  color: #e31e29;
  background-color: white;
  font-size: 18px;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
  text-align: center;
  padding: 10px 25px;
  line-height: 0px;
  font-size: 16px;
  transition: all 0.4s ease;
}

.ps-banner {
  object-fit: cover;
}

.sectionname {
  font-size: 22px !important;
  font-weight: 100 !important;
  font-style: italic;
  font-family: "Open Sans", sans-serif !important;
}

.secure-detail {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 100;
}

.footer-font {
  color: #103178 !important;
  font-weight: 100 !important;
  font-size: 17px !important;
}

.ps-footer--block .ps-block__title {
  font-size: 20px !important;
}

.ps-footer--contact .ps-footer__title {
  font-size: 20px !important;
}

.footer-icon {
  color: #103178 !important;
  font-weight: 100 !important;
}

.slider-left .slick-track {
  height: 0 !important;
}

.namkeen {
  box-shadow: unset !important;
}

.login-welcome {
  background: #e6eff3;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.login-welcome .form-padding h3 {
  line-height: 1.6;
  font-size: 30px;
  align-self: center;
}

.ps-logo-signup img {
  max-height: 75px !important;
}

.whyus-icon {
  width: 75px;
  height: auto;
  padding-bottom: 30px;
  transition: all 0.3s linear;
}

.whyus-icon:hover {
  max-width: 100%;
  transform: scale(1.2);
  cursor: pointer;
}

.whyus-title {
  font-size: 22px;
  color: #103178;
  font-weight: 100;
}

.card-background {
  // background: #ff00000d;
  border-radius: 15px;
  border: 1px solid #e31e29;
}

#homepage-one .ps-section--banner {
  margin-bottom: unset !important;
}

.ps-product--detail .ps-product__variants img {
  border: 1px solid #f0f2f5;
}

.namkeen-div {
  padding-bottom: 400px;
  padding-top: 100px;
}

.home-section-div.namkeen-div {
  background-image: url("../../../../public/static/img/home/namkeen_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 100%;
  height: auto;
  min-height: 300px;
  margin-bottom: 0px;
  .color-lightblue {
    color: rgb(255, 241, 241) !important;
  }
  .producttitle {
    color: #103178 !important;
  }
  .form-control {
    background-color: #ffffff;
  }
  .shop-btn {
    background-color: #103178 !important;
    color: #ffffff !important;
  }
  .ps-product__price {
    color: #ffffff !important;
  }
}

.instafeed {
  background-image: url("../../../../public/static/img/home/instagram_bg_2560.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 100%;
  height: auto;
  min-height: 300px;
}

.video-link-btn {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #80808066;
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  :hover {
    color: white;
  }
}

/* Media Queries for Different Screens */

@media (max-width: 2560px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_2560.png");
    padding-top: 250px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_2560.png");
    height: 1200px;
    .category-fig {
      padding-top: 500px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 1920px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_1920.png");
    padding-top: 200px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_1920.png");
    height: 1100px;
    .category-fig {
      padding-top: 450px;
      padding-bottom: 20px;
    }
  }
}

@media (max-width: 1366px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_1440.png");
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_1440.png");
    height: 780px;
    .category-fig {
      padding-top: 300px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 1024px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_1024.png");
    padding-bottom: 300px;
    padding-top: 170px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_1024.png");
    height: 700px;
    .category-fig {
      padding-top: 200px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 768px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_768.png");
    padding-bottom: 300px;
    padding-top: 100px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_768.png");
    height: 780px;
    .category-fig {
      padding-top: 300px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 480px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_480.png");
    padding-bottom: 120px;
    padding-top: 100px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_480.png");
    height: 780px;
    .category-fig {
      padding-top: 180px;
      padding-bottom: 20px;
    }
  }
}

@media (max-width: 360px) {
  .home-section-div.namkeen-div {
    background-image: url("../../../../public/static/img/home/namkeen_bg_360.png");
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-slider .slick-dots {
    padding-top: 0px;
  }
  .instafeed {
    background-image: url("../../../../public/static/img/home/instagram_bg_360.png");
    height: 780px;
    .category-fig {
      padding-top: 180px;
      padding-bottom: 20px;
    }
  }
}

.shop-combo-btn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  font-size: 10px;
  border-radius: 30px;
  font-weight: 100;
  overflow: hidden;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  color: #103178;
  border: 1px solid #103178;
  white-space: nowrap;
  z-index: 0;
  background-color: white;
  margin-bottom: 5px;
}

.shop-combo-btn i {
  padding-right: 8px;
}

.shop-btn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  font-weight: 100;
  overflow: hidden;
  padding: 8px 30px;
  margin: 0px 40px 20px 40px;
  text-align: center;
  text-decoration: none;
  color: #103178;
  border: 1px solid #103178;
  white-space: nowrap;
  z-index: 0;
  background-color: white;
}

.shop-btn i {
  padding-right: 8px;
}

.shop-btn-fill-right::before {
  position: absolute;
  content: "";
  background: #103178;
  transition-duration: 0.9s;
  z-index: -1;
  color: white;
  inset: 0px auto auto 0px;
  width: 0px;
  height: 100%;
  opacity: 1;
}

.shop-btn-fill-right:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.shop-btn-fill-right:hover {
  color: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background-color 0.7s ease 0.7s;
}

.bordered-btn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
  font-weight: 400;
  overflow: hidden;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  color: #e31e29;
  border: 1px solid #e31e29;
  white-space: nowrap;
  z-index: 0;
  background-color: white;
}

.bordered-btn::before {
  position: absolute;
  content: "";
  background: #e31e29;
  transition-duration: 0.9s;
  z-index: -1;
  color: white;
  inset: 0px auto auto 0px;
  width: 0px;
  height: 100%;
  opacity: 1;
}

.bordered-btn:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.bordered-btn:hover {
  color: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background-color 0.7s ease 0.7s;
}

.effect-goliath.grid-figure-last {
  border-radius: 15px !important;
}

.category-fig figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.category-fig figure:hover::before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.category-grid-3 {
  .category-fig {
    .grid {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;

      figure {
        width: 100%;
        height: 260px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.category-grid-4 {
  .category-fig {
    .grid {
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;

      figure {
        width: 100%;
        height: 199px;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .category-grid-4 {
    .category-fig {
      .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .category-grid-4 {
    .category-fig {
      .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .category-grid-4 {
    .category-fig {
      .grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .category-grid-4 {
    .category-fig {
      .grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.productshortdescription p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
strong,
label,
input i,
ul,
li,
ol {
  font-family: "Jost", sans-serif !important;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.grid figure {
  transition: box-shadow 0.1s ease-out;
  box-shadow: 0.8px 0.9px 3px grey;
}

.grid figure:hover {
  box-shadow: 1px 8px 20px #e31e282d;
  transition: box-shadow 0.1s ease-in;
}

.cookie-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  right: 20px;
  width: 10%;
}

.chakri-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  right: 20px;
  width: 11%;
}

.cookie1-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  bottom: -75px;
  left: 20px;
  width: 8.5%;
}

.cookie10-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  bottom: 0px;
  left: 10px;
  width: 18%;
}

.cookie11-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  bottom: 0px;
  left: 20px;
  width: 15%;
}

.cookie2-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  top: 0px;
  left: 200px;
}

.cookie3-img {
  animation: pulse 2s ease-in-out 0s infinite alternate;
  position: absolute;
  top: 0;
  right: 100px;
}

.lg-img {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 400px;
    align-self: center;
  }
}

.storeimage {
  cursor: pointer;
}

.storeimage img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.youtubevideo div:first-child {
  height: 150% !important;
}

.our-story {
  background-color: #ffeeee;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ps-product--on-cart a {
  color: #103178 !important;
}

.similarproduct .slick-dots {
  visibility: unset;
  opacity: unset;
  padding-top: 50px;
}

.ant-dropdown {
  z-index: 105000 !important;
}

.search-image {
  width: 75%;
  height: auto;
}

.ps-banner__image {
  width: 100%;
}

.customer-img {
  width: 100px;
  height: 100px !important;
}

.navigation--bottom {
  z-index: 9990;
  opacity: 1;
}

.help-card {
  padding: 20px;
  margin-bottom: 35px;

  i {
    font-size: 30px;
    padding-right: 15px;
  }
}

.product-review-image {
  width: 75px;
  height: 60px;

  img {
    width: 60px;
    height: 60px;
  }
}

.product-review {
  grid-column: 2;
  grid-column-gap: 10px;
  grid-template-columns: 375px 1fr;
  display: grid;
}

.order-btn {
  font-weight: 200 !important;
  color: #103178 !important;
  max-width: 210px;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
  text-align: center;
  padding: 10px 0px;
  line-height: 24px;
  font-size: 16px;
  transition: all 0.4s ease;
  text-decoration: underline;
}

.header--desktop .header__right {
  display: grid;
  grid-template-columns: unset;
}

// -----------------------tab------------------------

.orderlist .nav-tabs {
  border: 1px solid #dee2e6;
  border-radius: 10px 10px;
}

.orderlist .nav-tabs > li,
.orderlist .nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.orderlist .nav-tabs,
.orderlist .nav-pills {
  text-align: center;
  margin-bottom: 20px;
}

.orderlist .nav-tabs > li.active:first-child,
.orderlist .nav-tabs > li.active:focus:first-child,
.orderlist .nav-tabs > li.active:hover:first-child {
  background-color: #f0f2f5;
  border-radius: 10px 0px 0 10px;
}

.orderlist .nav-tabs > li.active,
.orderlist .nav-tabs > li.active:focus,
.orderlist .nav-tabs > li.active:hover {
  background-color: #f0f2f5;
  border-radius: 0px 10px 10px 0px;
}

.orderlist .nav-tabs > li.active > a,
.orderlist .nav-tabs > li.active > a:focus,
.orderlist .nav-tabs > li.active > a:hover {
  color: #103178;
  cursor: default;
  border-radius: 10px;
}

.orderlist .nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  font-size: 12px;
}

.orderlist .nav > li > a {
  position: relative;
  display: block;
  padding: 8px 10px;
  border-radius: 10px;
}

.orderlist .nav-tabs > li,
.orderlist .nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.orderlist .nav-tabs > li {
  float: left;
}

.orderlist .nav-tabs > li:first-child {
  border-right: 1px solid #dee2e6;
}

.orderlist .nav > li {
  position: relative;
  display: block;
}

// -------------------end tab----------------------

.review-description {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.aboutusimg {
  border-radius: 15px;
}

.about-secure-title {
  color: #103178;
}

.about-secure-div {
  height: 150px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 15px;
  background-color: #ffeeee;

  p {
    padding-top: 20px;
    padding-bottom: 20px;

    i {
      border: 1px solid #e31e29;
      border-radius: 50%;
      padding: 10px 7px;
      color: #e31e29;
      width: 54px;
    }
  }
}

.about-store {
  height: auto;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;

  &::before {
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    inset: 0px;
    color: white;
  }

  .about-store-number {
    font-size: 60px;
    color: #e31e29;
  }

  p {
    color: white;
    font-size: 20px;
  }
}

.about-new {
  i {
    border: 1px solid #e31e29;
    border-radius: 50%;
    padding: 10px 7px;
    color: #e31e29;
    width: 54px;
  }

  p {
    padding-bottom: 20px;
  }
}

.aboutus-three {
  border: 1px solid #e31e29;
  padding: 15px;

  .secure-detail {
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 100;
  }
}

.about-store-four {
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;

  .about-secure-div {
    height: 150px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    border-radius: 15px;
    background-color: white;
  }

  .about-secure-div:hover {
    background-color: #e31e29;
    color: white;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
    border-radius: 15px;

    p {
      color: white;
    }

    span {
      color: white;
    }

    p i {
      border: 1px solid white;
      border-radius: 50%;
      padding: 10px 7px;
      color: white;
      width: 54px;
    }
  }

  .about-store-number {
    font-size: 70px;
    color: #e31e29;
  }

  p {
    color: white;
  }
}

.modelbody::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.modelbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modelbody::-webkit-scrollbar-thumb {
  background: rgb(196, 195, 195);
}

.modelbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// ----------------short description show more-------------
.productshortdescription {
  /* Box */
  .box {
    margin: 22px auto;
    width: unset;
    padding: 12px 0 10px;
    max-height: unset;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
    box-shadow: unset;
  }

  .box.open {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
  }

  /* Text */
  @keyframes open {
    from {
      line-clamp: 3;
      -webkit-line-clamp: 3;
    }

    to {
      line-clamp: initial;
      -webkit-line-clamp: initial;
    }
  }

  @keyframes close {
    from {
      line-clamp: initial;
      -webkit-line-clamp: initial;
    }

    to {
      line-clamp: 3;
      -webkit-line-clamp: 3;
    }
  }

  .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 12px 0;
    animation: close 0.1s linear 0.1s forwards;
  }

  .open .text {
    animation: open 0.1s linear 0s forwards;
  }

  /* Irrelavant css... */
  .arrow {
    display: inline-block;
    padding: 4px;
    color: #103178 !important;
  }

  .arrow::after {
    content: "Read More";
    font-style: initial;
  }

  .open .arrow {
    margin-top: 5px;
    content: "";
  }

  .open .arrow::after {
    content: "Less More";
    font-style: initial;
  }
}

p.notice-success {
  font-size: 12px;
  color: #12a05c;
  font-weight: 300;
  .hide {
    display: none;
  }
}

// ----------------end short description show more-------------

.pre-footer-6 {
  padding-top: 70px;
  padding-bottom: 55px;
  border-top: 1px solid #f0f4f7;

  background-color: #e4e9ed;

  p {
    color: #103178;
    font-size: 30px;
    font-weight: 100;
    text-align: center;
    padding-bottom: 25px;
  }

  .content {
    width: 100%;
    height: 60px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    border-radius: 60px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .subscription {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .subscription .add-email {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 20px;
  }

  .subscription .submit-email {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 2px);
    width: 125px;
    border: none;
    border-radius: 60px;
    outline: none;
    margin: 1px;
    padding: 0 20px;
    cursor: pointer;
    background: #e31e29;
    color: #ffffff;
  }

  .subscription.done .submit-email {
    width: calc(100% - 2px);
    background: #c0e02e;
  }

  .subscription .submit-email .after-submit {
    transition-delay: 0.35s;
  }

  .subscription:not(.done) .submit-email .before-submit,
  .subscription.done .submit-email .after-submit {
    visibility: visible;
    opacity: 1;
  }
}

.ps-shop__actions .ps-shop__pagination-range {
  padding-left: 25px;
  position: relative;
}

.productdescription {
  font-size: 16px;
}

.p-list-addtocart {
  padding: 7px 7px !important;
  line-height: 23px !important;
  font-size: 13px !important;
}

.qty-btn {
  margin-left: 13px;

  .form-control {
    border-radius: 5px;
    height: 40px;
    padding: 0px !important;
  }

  .up {
    left: 70% !important;
    width: 10px !important;
  }

  .down {
    left: 20% !important;
    width: 10px !important;
  }
}

.close-btn {
  height: 44px !important;
}

.pro-add-btn {
  padding: 8px 15px;
  margin: 0px !important;
  border-radius: 5px !important;
}

.pro-add-btn:hover {
  color: white !important;
}

.ps-shop-items .card-box-shadow {
  min-height: 412px;
}

.combo-add {
  .qty-btn {
    margin-left: 0px;

    .up:after {
      width: 10px !important;
    }

    .down:after {
      width: 10px !important;
    }

    .up:before {
      width: 10px !important;
    }

    .down:before {
      width: 10px !important;
    }

    .form-control {
      height: 30px !important;
    }
  }

  .pro-add-btn {
    padding: 2px 30px !important;
  }

  p.outstock {
    margin-top: 0px;
  }
}
.appstore {
  margin-bottom: 40px;
}
.ps-noti.header__notice .ps-noti__close {
  display: none;
}
.ps-noti.header__notice p {
  animation: scroll 30s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
