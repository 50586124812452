.ps-skeleton {
    overflow: hidden;

    .row {
        > * {
            overflow: hidden;
        }
    }
}

.ps-skeleton--product {
    margin-bottom: 30px;

    &.list-small {
        .row {
            margin: 0;
            display: grid;
            grid-template-columns: 60px 1fr;
            grid-gap: 10px;

            > * {
                padding: 0;
                display: block;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.ps-skeleton--table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    .ant-skeleton {
        display: block;
        max-width: 100%;

        .ant-skeleton-input {
            max-width: 300px;
        }
    }

    @media screen and (min-width: 480px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(4, minmax(100px, 25%)) ;
    }
}
