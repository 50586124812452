header {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.header {
  &__top-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-heading;
    width: 30px;
    height: 30px;
    font-size: 20px;

    &:hover {
      color: $color-orange-new;
    }
  }

  &__categories-toggle {
    transition: all 0.4s ease;
  }

  &__switcher {
    i {
      display: inline-block;
      margin-left: 6px;

      &:before {
        font-size: 10px;
      }
    }

    .ant-btn {
      box-shadow: 0 0 rgba(#000, 0);

      span {
        font-size: 14px;
        color: $color-gray-new;
      }
    }
  }

  &__switchers {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .ant-select {
      margin-right: 10px;

      .ant-select-selector {
        border: none;
        font-size: 14px;
        color: #5b6c8f;
        letter-spacing: 0.05em;
        outline: none !important;

        &:before {
          content: "\f078";
          font-family: FontAwesome;
          position: absolute;
          top: 50%;
          right: 0;
          font-size: 12px;
          transform: translateY(-50%);
        }
      }

      .ant-select-arrow {
        span {
          display: none !important;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-select-focused {
        .ant-select-selector {
          border-color: transparent;
          border: none !important;
          box-shadow: 0 0 transparent;
        }
      }
    }

    .ant-btn {
      border: none;
      background-color: transparent;

      &:after {
        display: none !important;
      }
    }
  }

  &__topbar-left {
    p {
      margin-bottom: 0;
      color: $color-gray-new;
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;

      strong {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  &__topbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .header__switcher {
      .ant-btn {
        span {
          font-size: 12px;
        }
      }
    }
  }

  &__topbar {
    background-color: #f0f2f5;

    .header__switchers {
      padding-right: 0;

      .ant-select {
        width: 100px !important;

        .ant-select-show-arrow {
          .ant-select-selector {
            &:before {
              font-size: 12px;
            }
          }
        }
      }

      &:after {
        display: none;
      }
    }

    .container {
      display: grid;
      grid-template-columns: 350px 1fr;
    }

    &.type--second {
      .container {
        display: flex;
        justify-content: flex-end;

        p {
          font-size: 14px;
        }
      }
    }
  }

  &__notice p {
    text-align: left;
  }
}

.header__supplies {
  position: relative;

  .ps-promo {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header--desktop {
  .ps-logo {
    img {
      max-height: 46px;
    }
  }

  .header__social-links {
    padding: 0 10px;
    border-right: 1px solid #d9dee8;

    li {
      margin-right: 0;

      a {
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 0;

        i {
          color: $color-gray-new;
          font-size: 14px;
          transition: all 0.4s ease;
        }

        &:hover {
          background-color: transparent;

          i {
            color: $color-2nd;
          }
        }
      }
    }
  }

  .ps-dropdown--fullscreen {
    position: static;
  }

  .header__categories-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    height: 46px;
    font-size: 17px;
    line-height: 30px;
    text-transform: capitalize;
    color: $color-heading;

    i {
      margin-right: 6px;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      transition: all 0.4s ease;
    }

    span {
      font-weight: 500;
      color: #103178;
      font-size: inherit;
      line-height: inherit;
      transition: all 0.4s ease;
    }

    &:hover {
      i {
        color: $color-2nd;
      }

      span {
        color: $color-2nd;
      }
    }
  }

  // .header__categories {
  // }

  .header__action {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    color: #ff9923;
    font-size: 22px;

    &-badge {
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: 22px;
      height: 22px;
      font-size: 11px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #103178;
      color: white;
    }
  }

  .header__actions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .header__left {
    .header__toggle-btn {
      display: none;
    }
  }

  .header__right {
    display: grid;
    grid-template-columns: 1fr 160px;

    .header__switchers {
      padding-right: 15px;
    }

    .header__actions {
      position: relative;

      &:before {
        content: "";
        @include vertical-align();
        left: 0;
        width: 1px;
        height: 1px;
        background-color: rgba($color-1st, 0.1);
      }
    }
  }

  .header__top {
    padding: 23px 0;

    > .container {
      display: grid;
      grid-template-columns: 310px 520px 1fr;
    }
  }

  .header__bottom {
    border-top: 1px solid $color-gray-light;
  }

  &.header--sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    animation: smoothScroll 1s forwards;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.05);
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
.header--one {
  .header__notice {
    p {
      text-align: center;
    }
  }

  .header__switchers {
    position: relative;
    padding-right: 40px;
  }

  .header__left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__top-toggle {
      display: none;
    }
  }

  .header__top {
    padding: 30px 0;
  }

  .header__bottom {
    max-height: 60px;
    border-bottom: 1px solid $color-gray-light;
  }

  &.header--sticky {
    .header__notice {
      display: none;
    }

    .header__left {
      .header__top-toggle {
        display: flex;
        margin-right: 25px;
      }
    }

    .header__top {
      padding: 0 0;
    }

    .header__bottom {
      position: relative;
      z-index: 99;
      height: 0;
      border: none;
      overflow: hidden;
      background-color: #ffffff00;

      &.active {
        height: auto;
        overflow: visible;
        border-bottom: 1px solid $color-gray-light;
      }
    }
  }

  &.with-primary-color {
    background-color: $color-1st;

    .header__notice {
      text-align: left;
      border-bottom: 1px solid rgba(#fff, 0.15);

      p {
        text-align: inherit;
        color: $color-2nd;
      }
    }

    .ps-text--contact-number {
      color: $color-gray;
    }

    .menu {
      > li {
        > a {
          color: #fff;

          &:hover {
            color: $color-2nd;
          }
        }
      }
    }

    .header__switcher {
      .ant-btn {
        i {
          &:before {
            color: $color-gray;
          }
        }

        span {
          color: $color-gray;
        }
      }
    }

    .header__categories-toggle {
      color: #fff;

      span {
        color: inherit;
      }

      &:hover {
        color: $color-orange-new;
      }
    }

    .header__action {
      .header__action-badge {
        background-color: $color-orange-new;
      }
    }

    .header__bottom {
      background-color: $color-1st;
      border-top-color: rgba(#fff, 0.15);
      border-bottom-color: rgba(#fff, 0.15);
    }

    &.header--sticky {
      .header__top-toggle {
        color: #fff;
      }

      .header__top {
        background-color: $color-1st;
        border-bottom: none;
      }
    }
  }

  &.without-border {
    .header__bottom {
      border-bottom: none;
    }
  }
}

.header--two {
  .header__switcher {
    i {
      &:before {
        font-size: 9px;
      }
    }
  }

  .header__switchers {
    .header__switcher {
      border-right: 1px solid #d9dee8;
    }
  }

  .header__top-toggle {
    display: none;
  }

  .header__left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ps-text--contact-number {
      margin-top: 0;
      color: $color-1st;

      strong {
        color: inherit;
      }
    }
  }

  .header__right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    form {
      width: 100%;
      max-width: 360px;
    }

    .header__actions {
      &:before {
        display: none;
      }
    }
  }

  .header__top {
    padding: 30px 0;

    .container {
      grid-template-columns: 1fr 550px;
      grid-gap: 30px;
    }
  }

  .header__bottom {
    border-bottom: 0;
  }

  &.header--sticky {
    .header__top-toggle {
      display: flex;
    }

    .ps-text--contact-number {
      display: none;
    }

    .header__topbar {
      display: none;
    }

    .header__notice {
      display: none;
    }

    .header__left {
      .header__top-toggle {
        display: flex;
        margin-right: 25px;
      }
    }

    .header__top {
      padding: 15px 0;
      background-color: #fff;
      border-bottom: 1px solid $color-gray-light;

      .container {
        grid-template-columns: 320px 1fr;
      }

      .header__right {
        form {
          max-width: 600px;
        }
      }
    }

    .header__bottom {
      position: relative;
      z-index: 99;
      height: 0;
      border: none;
      overflow: hidden;
      background-color: #fff;

      &.active {
        height: auto;
        overflow: visible;
        border-bottom: 1px solid $color-gray-light;
      }
    }
  }
}

.header--three {
  .header__topbar {
    background-color: $color-1st;

    p {
      color: #fff;
    }

    .menu--topbar {
      li {
        border-right: 1px solid rgba(#fff, 0.15);

        a {
          color: $color-gray-light;

          &:hover {
            color: $color-orange-new;
          }
        }
      }
    }

    .header__social-links {
      border-right: 1px solid rgba(#fff, 0.15);

      li {
        a {
          color: $color-gray-light;

          i {
            color: inherit;
          }
        }
      }
    }

    .header__switcher {
      .ant-btn {
        i {
          &:before {
            color: $color-gray-light;
          }
        }

        span {
          color: $color-gray-light;
        }
      }
    }
  }

  .header__notice {
    background-color: #ffff;
    text-align: left;

    p {
      color: $color-heading;
    }
  }

  .header__search-form {
    max-width: 360px;
  }

  .header__categories {
    .header__categories-toggle {
      padding: 0 25px;
      color: #fff;
      border-radius: 50px;
      height: 46px;
      background-color: $color-1st;

      span {
        color: inherit;
      }

      &:hover {
        color: #fff;
        background-color: $color-2nd;

        i {
          color: inherit;
        }
      }
    }
  }

  .header__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__top {
    position: relative;
    padding: 30px 0;

    > .container {
      grid-template-columns: 430px 1fr 1fr;
      grid-gap: 10px;
    }
  }

  &.header--sticky {
    .header__topbar,
    .header__notice {
      display: none;
    }

    .header__top {
      padding: 10px 0;
      border-bottom: 1px solid $color-gray-light;
    }
  }
}

.header--four {
  .header__notice {
    border-bottom: 1px solid $color-gray-light;

    p {
      text-align: left;
    }
  }

  .header__bottom {
    border-bottom: none;
  }

  .header__top {
    padding: 20px 0;
  }

  &.header--sticky {
    .header__top {
      padding: 10px 0;
    }
  }
}

.header--six {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  .header__topbar {
    background-color: $color-1st;

    p {
      color: #fff;
    }

    .menu--topbar {
      li {
        border-right: 1px solid rgba(#fff, 0.15);

        a {
          color: $color-gray-light;

          &:hover {
            color: $color-orange-new;
          }
        }
      }
    }

    .header__social-links {
      border-right: 1px solid rgba(#fff, 0.15);

      li {
        a {
          color: $color-gray-light;

          i {
            color: inherit;
          }
        }
      }
    }

    .header__switcher {
      .ant-btn {
        i {
          &:before {
            color: $color-gray-light;
          }
        }

        span {
          color: $color-gray-light;
        }
      }
    }
  }

  .header__categories-toggle {
    padding: 0;
  }

  .header__categories {
    .header__categories-toggle {
      color: #fff;
      border-radius: 50px;
      background-color: $color-1st;
      padding: 0 25px;

      span {
        color: inherit;
      }

      &:hover {
        color: $color-2nd;
      }
    }
  }

  .header__top {
    position: relative;
    padding: 30px 0 25px;
    background-color: transparent;

    .container {
      grid-template-columns: 1fr 330px;
    }
  }

  .header__actions {
    &:before {
      display: none;
    }
  }

  .header__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ps-logo {
      margin-right: 70px;
    }
  }

  .header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menu--desktop {
    > li {
      > a {
        padding: 16px 0;
      }
    }
  }

  .navigation--single {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: static;

    .header__categories,
    .header__supplies {
      position: static;
      margin-right: 30px;
    }
  }

  &.header--sticky {
    .header__topbar {
      display: none;
    }

    .header__top {
      padding: 10px 0;
    }
  }
}

.header--mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0 none;
  padding: 10px 16px;
  background-color: #fff;
  transition: all 0.4s $ease-in-out-quad;

  .ps-logo {
    line-height: 20px;

    img {
      max-height: 30px;
    }
  }

  .header__search {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: $color-heading;
    font-weight: 700;
  }

  .header__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .header__searchbox {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 10px;
    border-top: 1px solid $color-gray-light;
    background-color: #fff;
  }

  &.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 10px 16px;
    border-bottom: 1px solid $color-gray-light;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.05);
  }

  @media (min-width: 1200px) {
    display: none;
  }
}
