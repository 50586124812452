.box-rounded-login {
  @media screen and (max-width: 480px) {
    .side-image {
      display: none;
    }
  }
}

.footer-padding {
  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 100px;
  }
}

@media screen and (max-width: 768px) {
  .shop-btn {
    padding: 5px 12px;
    margin: 0px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .cookie-img {
    width: 18%;
  }

  .cookie1-img {
    display: none;
  }

  .cookie10-img {
    width: 30%;
  }

  .cookie11-img {
    display: none;
  }

  .lg-img {
    margin-top: 50px;
  }

  .addto-cart-section {
    .mobile-pl {
      text-align: center !important;
    }
  }

  .qty-btn {
    margin-left: 0px;

    .form-control {
      border-radius: 5px;
      height: 40px;
      padding: 0px !important;
    }

    .up {
      left: 50% !important;
      width: 10px !important;
    }

    .down {
      left: 0% !important;
      width: 10px !important;
    }
  }
}

.pro-add-btn {
  @media all and (min-width: 320px) and (max-width: 400px) {
    padding: 8px 35px !important;
    margin: 0px !important;
    border-radius: 5px !important;
  }

  @media all and (min-width: 400px) and (max-width: 500px) {
    padding: 8px 50px !important;
    margin: 0px !important;
    border-radius: 5px !important;
  }

  @media all and (min-width: 500px) and (max-width: 641px) {
    padding: 8px 75px !important;
    margin: 0px !important;
    border-radius: 5px !important;
  }

  @media all and (min-width: 641px) and (max-width: 768px) {
    padding: 8px 60px !important;
    margin: 0px !important;
    border-radius: 5px !important;
  }

  @media screen and (max-width: 768px) {
    padding: 8px 50px;
    margin: 0px !important;
    border-radius: 5px !important;
  }
}

.header__searchbox {
  @media screen and (max-width: 480px) {
    .product-search {
      width: 95%;
    }
  }

  @media screen and (max-width: 768px) {
    .product-search {
      width: 95%;
    }
  }

  @media screen and (max-width: 1024px) {
    .product-search {
      width: 95%;
    }
  }

  @media screen and (max-width: 1199px) {
    .product-search {
      width: 95%;
    }
  }
}

@media screen and (max-width: 480px) {
  .ps-product--detail .ps-product__price {
    margin-bottom: 30px;
    font-size: 30px;
  }

  .ps-review--product .ps-review__desc {
    padding-left: 26px !important;
  }

  .ps-table--responsive tbody tr td[data-label] {
    grid-template-columns: 110px 1fr;
  }

  .address-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ps-form--checkout {
    padding: 30px;
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .modelbody {
    height: 385px;
    overflow-y: scroll;
  }

  .ps-shop .ps-shop__actions-right {
    display: flex;
  }

  .orderno {
    max-width: 50%;
  }

  .transaction {
    text-align: center;
    align-self: center;
    margin-top: 20px;
  }

  .conatctus-form-padding {
    padding: 0px !important;
  }
}

.mobile-show {
  display: none;

  @media screen and (max-width: 1199px) {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
}

.reviewstar {
  padding-left: 20px;
  text-align: right;

  @media screen and (max-width: 480px) {
    padding-top: 10px;
    padding-left: 0px;
    text-align: left;
  }
}

.add-address {
  text-align: right;

  @media screen and (max-width: 480px) {
    text-align: left;
    padding-top: 20px;
  }
}

.content-card {
  width: 400px;

  @media screen and (max-width: 768px) {
    width: 100vh;
  }

  @media screen and (max-width: 480px) {
    width: 100vh;
  }
}

.content-card-login {
  width: 800px;

  @media screen and (max-width: 768px) {
    width: 50vh;
  }

  @media screen and (max-width: 480px) {
    width: 100vh;
  }
}

.content-card-signup {
  width: 1000px;

  @media screen and (max-width: 768px) {
    width: 80vh;
  }

  @media screen and (max-width: 480px) {
    width: 100vh;
  }
}

.myaccount .ps-layout__left {
  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width: 1199px) {
    display: block;
  }
}

.mobile-hide {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }

  @media screen and (max-width: 1024px) {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    display: none !important;
  }

  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.openmenu {
  opacity: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  transition: opacity 1s;
}

.openmenu.fade {
  @media screen and (max-width: 480px) {
    opacity: 0;
    animation: cssAnimation 0s 5s forwards;
    display: none;
  }

  @media screen and (max-width: 768px) {
    opacity: 0;
    animation: cssAnimation 0s 5s forwards;
    display: none;
  }

  @media screen and (max-width: 1024px) {
    opacity: 0;
    animation: cssAnimation 0s 5s forwards;
    display: none;
  }

  @media screen and (max-width: 1199px) {
    opacity: 0;
    animation: cssAnimation 0s 5s forwards;
    display: none;
  }
}

.profile-usermenu {
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 1199px) {
    margin-top: 0px;
  }
}

.order-detail-btn {
  @media screen and (max-width: 480px) {
    text-align: center;
    align-self: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    align-self: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 1024px) {
    text-align: center;
    align-self: center;
    margin-top: 20px;
  }
}

.order-status {
  @media screen and (max-width: 480px) {
    margin-left: 0px;
    text-align: left;
    align-self: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
    text-align: left;
    align-self: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0px;
    text-align: left;
    align-self: center;
    margin-top: 20px;
  }

  @media only screen and (max-width: 1250px) and (min-width: 1200px) {
    margin-left: 0px;
    text-align: right;
    align-self: center;
    margin-top: 20px;
  }
}

.orderno {
  max-width: 13%;

  @media screen and (max-width: 480px) {
    max-width: unset;
  }

  @media screen and (max-width: 768px) {
    max-width: unset;
  }

  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
}

.detail-btn {
  text-align: right;

  @media screen and (max-width: 480px) {
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
  }
}

.wishlisttd {
  width: 20% !important;
  vertical-align: middle !important;

  @media screen and (max-width: 480px) {
    width: unset !important;
  }

  @media screen and (max-width: 768px) {
    width: unset !important;
  }
}

.ps-document p {
  @media screen and (max-width: 480px) {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
}

.ps-shop .ps-shop__sortby {
  @media screen and (max-width: 480px) {
    border-right: unset;
    display: flex;
    padding-left: 10px;
    align-self: center;
    padding-right: 10px;
  }

  @media screen and (max-width: 768px) {
    border-right: unset;
    display: flex;
    padding-left: 10px;
    align-self: center;
    padding-right: 10px;
  }
}

.ps-shop__actions .ps-shop__pagination-range {
  @media screen and (max-width: 480px) {
    display: flex;
    padding-left: 10px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    padding-left: 17px;
  }
}

.filter-right {
  @media screen and (max-width: 480px) {
    justify-content: left !important;
  }

  @media screen and (max-width: 768px) {
    justify-content: left !important;
  }

  @media screen and (max-width: 1024px) {
    justify-content: left !important;
  }

  @media screen and (max-width: 1199px) {
    justify-content: left !important;
  }
}

.footer-payment-icon {
  text-align: right;

  @media screen and (max-width: 480px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    text-align: center;
  }
}

.productlist {
  margin: 15px;

  @media screen and (max-width: 480px) {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.similar-product {
  grid-column-gap: 20px;
}

.shopaction-container {
  padding-right: 15px;
  padding-left: 15px;

  @media screen and (max-width: 480px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (max-width: 1024px) {
    padding-right: 0;
    padding-left: 0;
  }
}

.ps-product .ps-product__actions {
  @media screen and (max-width: 480px) {
    margin-right: 15px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 15px;
  }
}

.shop-items-list {
  @media screen and (max-width: 480px) {
    display: grid;
    grid-gap: 15px;
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-gap: 15px;
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-gap: 15px;
  }
}

.ps-footer--1 .ps-footer--contact {
  @media screen and (max-width: 480px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 1024px) {
    padding-bottom: 0px;
  }
}

.ps-product--detail .ps-product__header {
  grid-gap: 45px;

  @media screen and (max-width: 480px) {
    grid-gap: 30px;
  }
}

.vegimg {
  position: absolute;
  top: 16px;
  right: 20px;
  height: 40px;
}
.vegonlyimg {
  height: 15px !important;
  width: 15px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-detail-image {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0;

  @media screen and (max-width: 1024px) {
    padding-left: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}

.ps-product--detail {
  @media screen and (max-width: 480px) {
    max-width: unset;
    margin: 0 auto;
  }
}

.ps-footer--1 .ps-footer__information {
  @media screen and (max-width: 480px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 1024px) {
    padding-bottom: 0px;
  }
}

.mobile-home .ant-drawer-content-wrapper {
  @media screen and (max-width: 768px) {
    width: 100% !important;
    left: -15px;
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;
    left: -15px;
  }
}

.ps-mobile-cart .ant-drawer-content-wrapper {
  @media screen and (max-width: 768px) {
    width: 100% !important;
    margin-left: 128px;
    left: -115px;
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;
    margin-left: 128px;
    left: -115px;
  }
}

.productdescription {
  @media screen and (max-width: 768px) {
    padding: 40px;
  }

  @media screen and (max-width: 480px) {
    padding: 40px;
  }
}

#tablewishlist tbody tr {
  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
}

#orderitem tbody tr {
  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
}

.wishlist-td-productname {
  width: 100%;
  word-break: break-all;

  @media screen and (max-width: 1199px) {
    width: 40%;
    word-break: break-all;
    margin-left: 0 !important;
  }

  @media screen and (max-width: 768px) {
    width: 60%;
    word-break: break-all;
    margin-left: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    word-break: break-all;
    margin-left: 0 !important;
  }
}

.cartlist-quantity {
  @media screen and (max-width: 768px) {
    width: 30% !important;
  }

  @media screen and (max-width: 480px) {
    width: 50% !important;
  }
}

.cartlist tbody tr {
  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
}

.modal-dialog {
  margin: 5.75rem auto;

  @media screen and (max-width: 768px) {
    margin: 3.75rem auto;
  }

  @media screen and (max-width: 480px) {
    margin: 3.75rem auto;
  }
}

.tags-input-container {
  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
    padding-bottom: 10px;
  }
}

.ps-table--shopping-cart tbody {
  @media screen and (max-width: 768px) {
    border: unset;
    border-top: unset;
  }

  @media screen and (max-width: 480px) {
    border: unset;
    border-top: unset;
  }
}

.carttitle {
  @media screen and (max-width: 768px) {
    margin-bottom: 30px !important;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 30px !important;
  }
}

.cart-title-header {
  padding-bottom: 20px !important;

  @media screen and (max-width: 768px) {
    margin-bottom: 0px !important;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 0px !important;
  }
}

.order-space-mobile {
  @media screen and (max-width: 768px) {
    padding-left: 25px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 25px !important;
  }
}

.product--image-detail {
  width: 98%;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 97%;
    height: auto;
  }

  @media screen and (max-width: 480px) {
    width: 97%;
    height: auto;
  }
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 30px;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 30px;
  }
}

.quantity-price-td {
  @media screen and (max-width: 768px) {
    padding-left: 30px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 30px !important;
  }
}

.quantity-td {
  @media screen and (max-width: 768px) {
    padding-left: 85px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 85px !important;
  }
}

.cart-quantity-td {
  @media screen and (max-width: 768px) {
    padding-left: 75px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 75px !important;
  }
}

.invoicedata {
  text-align: right;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
}

.btnaddress {
  text-align: right;
  width: 60% !important;

  // @media screen and (max-width: 1024px) {
  //   text-align: center;
  //   width: unset !important;
  // }
  @media screen and (max-width: 768px) {
    // text-align: center;
    width: 60% !important;
  }

  @media screen and (max-width: 480px) {
    width: 55% !important;
    // text-align: center;
  }
}

.address-name {
  width: 40% !important;

  // @media screen and (max-width: 1024px) {
  //   width: unset !important;
  // }
  @media screen and (max-width: 768px) {
    width: 40% !important;
  }

  @media screen and (max-width: 480px) {
    width: 45% !important;
  }
}

.card-mobile-pending {
  padding-bottom: 0px !important;

  @media screen and (max-width: 768px) {
    padding: 0 10px !important;
    padding-bottom: 0px !important;
  }

  @media screen and (max-width: 480px) {
    padding: 0 10px !important;
    padding-bottom: 0px !important;
  }
}

.back-top {
  right: 45px !important;
  bottom: 100px;

  @media screen and (max-width: 768px) {
    right: 45px !important;
    bottom: 133px;
  }

  @media screen and (max-width: 480px) {
    right: 35px !important;
    bottom: 133px;
  }
}

.float {
  @media screen and (max-width: 768px) {
    bottom: 70px !important;
  }

  @media screen and (max-width: 480px) {
    bottom: 70px !important;
  }
}

.warningbtn {
  @media screen and (max-width: 768px) {
    padding: 8px 20px;
  }

  @media screen and (max-width: 480px) {
    padding: 8px 20px;
  }
}

.whyus-card {
  border-right: 1px solid lightgray;

  @media screen and (max-width: 768px) {
    border: none;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 480px) {
    border: none;
    padding-bottom: 30px;
  }
}

.customer-cart {
  height: 295px;

  @media screen and (max-width: 768px) {
    height: 290px;
  }

  @media screen and (max-width: 480px) {
    height: 290px;
  }
}

.review-card-home {
  height: 350px;
}

.popup-model {
  @media screen and (max-width: 768px) {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 0px !important;
  }
}

.popup-model .modal-dialog {
  margin: 200px auto;

  @media screen and (max-width: 768px) {
    margin: 50px auto;
  }

  @media screen and (max-width: 480px) {
    margin: 50px auto;
  }
}

.popup-content {
  order: 1;

  @media screen and (max-width: 768px) {
    order: 2;
    padding-top: 20px;
  }

  @media screen and (max-width: 480px) {
    order: 2;
    padding-top: 20px;
  }
}

.popup-image {
  order: 2;

  @media screen and (max-width: 768px) {
    order: 1;
  }

  @media screen and (max-width: 480px) {
    order: 1;
  }
}

.popupmodel {
  height: 318px;
  margin-right: -16px;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
}

.popup-model .modal-content {
  width: 90% !important;

  @media screen and (max-width: 768px) {
    width: 95% !important;
    margin-left: 9px;
  }

  @media screen and (max-width: 480px) {
    width: 95% !important;
    margin-left: 9px;
  }
}

.product-search {
  max-height: 450px;

  @media screen and (max-width: 768px) {
    max-height: 310px;
  }

  @media screen and (max-width: 480px) {
    max-height: 310px;
  }
}

.home-section-category {
  margin-bottom: 100px;
  padding-top: 80px !important;

  @media screen and (max-width: 768px) {
    padding-top: 50px !important;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 480px) {
    padding-top: 50px !important;
    margin-bottom: 50px;
  }
}

.home-section-div {
  margin-bottom: 100px;
  margin-top: 20px !important;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 50px;
  }
}

.ps-section--categories .ps-categories__link {
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 50%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 50%;
  }
}

.header--mobile .ps-logo img {
  @media screen and (max-width: 768px) {
    max-height: 55px;
  }

  @media screen and (max-width: 480px) {
    max-height: 55px;
  }
}

@media (min-width: 1280px) {
  .ps-banner {
    height: auto;
  }

  .form-loader.homediv {
    margin-top: -61px;
  }
}

.grid figure {
  @media screen and (max-width: 768px) {
    min-width: unset;
    max-width: unset;
    max-height: unset;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    min-width: unset;
    max-width: unset;
    max-height: unset;
    width: 100%;
  }
}

.grid-figure-secound {
  @media screen and (max-width: 768px) {
    min-width: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
  }

  @media screen and (max-width: 480px) {
    min-width: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
  }
}

.grid-figure-last {
  @media screen and (max-width: 768px) {
    min-width: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
  }

  @media screen and (max-width: 480px) {
    min-width: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
  }
}

.isslider {
  grid-template-columns: 155px 1fr;
  display: grid;

  @media screen and (max-width: 1199px) {
    grid-template-columns: 155px 1fr;
    display: grid;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 155px 1fr;
    display: grid;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: unset;
    display: unset;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: unset;
    display: unset;
  }
}

.image-gap {
  grid-gap: 0px !important;
  grid-template-columns: 1fr 410px !important;

  @media screen and (max-width: 1199px) {
    grid-template-columns: 1fr 410px;
    grid-gap: 40px !important;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 410px;
    grid-gap: 40px !important;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 410px;
    grid-gap: 30px !important;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr 410px;
    grid-gap: 30px !important;
  }
}

.selection-title {
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #e31e29 !important;

  @media screen and (max-width: 768px) {
    font-size: 35px !important;
    line-height: 40px !important;
  }

  @media screen and (max-width: 480px) {
    font-size: 35px !important;
    line-height: 40px !important;
  }
}

.ps-footer--address .ps-social {
  padding-top: 30px !important;
  padding-bottom: 0px;

  @media screen and (max-width: 768px) {
    padding-bottom: 25px;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 25px;
  }
}

.storeimage i {
  font-size: 35px;
  color: white;
  position: absolute;
  top: 45%;
  left: 45%;
  border: 3px solid white;
  padding: 10px 15px;
  border-radius: 50%;
  width: 60px;

  @media screen and (max-width: 1024px) {
    top: 25%;
    left: 45%;
    border: 3px solid white;
    padding: 10px 20px 10px 20px;
    width: 65px;
  }

  @media screen and (max-width: 768px) {
    top: 13%;
    left: 45%;
    border: 3px solid white;
    padding: 10px 20px 10px 20px;
    width: 65px;
  }

  @media screen and (max-width: 480px) {
    top: 40%;
    left: 45%;
    border: 3px solid white;
    padding: 10px 20px 10px 20px;
    width: 65px;
  }

  @media screen and (max-width: 320px) {
    top: 35%;
    left: 40%;
    border: 3px solid white;
    padding: 10px 20px 10px 20px;
    width: 65px;
  }
}

.grid figure {
  margin: 10px 1%;

  @media screen and (max-width: 768px) {
    margin: 10px 0%;
  }

  @media screen and (max-width: 480px) {
    margin: 10px 0%;
  }
}

.ant-back-top .icon-arrow-up {
  align-self: center;
}

.login-card-content {
  display: grid;
  grid-template-columns: 1fr 356px;
  grid-gap: 30px;

  @media screen and (max-width: 1024px) {
    .login-mobile-hide {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }
}

.forgotpass-card-content {
  display: grid;
  grid-template-columns: 1fr 376px;
  grid-gap: 10px;

  @media screen and (max-width: 1024px) {
    .login-mobile-hide {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }
}

.signup-card-content {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 30px;

  @media screen and (max-width: 1024px) {
    .login-mobile-hide {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    display: block;

    .login-mobile-hide {
      display: none;
    }
  }
}

.btn-Left-Middle {
  @media screen and (max-width: 768px) {
    margin: 0px 50px;
  }

  @media screen and (max-width: 480px) {
    margin: 0px 50px;
  }
}

.btn-Right-Middle {
  @media screen and (max-width: 768px) {
    margin: 0px 0px;
  }

  @media screen and (max-width: 480px) {
    margin: 0px 0px;
  }
}

.mobile-slider {
  display: none;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
}

.desk-slider {
  @media screen and (max-width: 1199px) {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.review-user-img {
  @media screen and (max-width: 1199px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
}

.order-price-td {
  @media screen and (max-width: 1199px) {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 480px) {
    padding-left: 90px !important;
  }
}

.order-product-title {
  width: 200px;
  align-self: center;

  @media screen and (max-width: 1199px) {
    width: 245px;
    word-break: break-all;
    padding-right: 15px;
    margin-right: 0px;
  }

  @media screen and (max-width: 1024px) {
    width: 245px;
    word-break: break-all;
    padding-right: 15px;
    margin-right: 0px;
  }

  @media screen and (max-width: 768px) {
    width: 245px;
    word-break: break-all;
    padding-right: 15px;
    margin-right: 0px;
  }

  @media screen and (max-width: 480px) {
    width: 245px;
    word-break: break-all;
    padding-right: 15px;
    margin-right: 0px;
  }
}

.product-review {
  @media screen and (max-width: 1199px) {
    grid-template-columns: 200px 1fr;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 200px 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 155px 1fr;
  }

  @media screen and (max-width: 480px) {
    grid-column: 1;
    grid-column-gap: unset;
    grid-template-columns: unset;
  }
}

.copyright {
  @media screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 480px) {
    text-align: center;
    margin-bottom: 50px;
  }
}

.footer-bottom {
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 0;
  }
}

.ordertab {
  @media screen and (max-width: 768px) {
    padding-left: 15px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .ps-layout--with-sidebar.track-order {
    display: flex;
    padding-left: 300px;
    padding-right: 300px;
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 768px) {
}
