#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    .loader-section {
        position: fixed;
        top: 0;
        width: 51%;
        height: 100%;
        background: #ffffff;
        z-index: 999;
        &.section-left {
            left: 0;
        }
        &.section-right {
            right: 0;
        }
    }
}
