.breadcrumb {
    margin-bottom: 0;
    border: none;
    background-color: transparent;
    padding: 10px 0;

    li {
        color: $color-1st;
        font-size: 14px;

        a {
            color: $color-gray-new;
            font-size: 14px;

            &:after {
                content: "\f105";
                font-family: FontAwesome;
                padding: 0 15px;

            }
        }
    }
}
