.ps-section--standard {
  .ps-section__header {
    padding-bottom: 30px;
    text-align: center;

    h3 {
      margin-bottom: 0;
      line-height: 1em;
      font-size: 24px;
      font-weight: 600;
      color: $color-1st;
    }
  }

  @media screen and (min-width: 768px) {
    .ps-section__header {
      h3 {
        font-size: 30px;
      }
    }
  }

  @media screen and (min-width: 1040px) {
    .ps-section__header {
      h3 {
        font-size: 40px;
      }
    }
  }
}

.ps-best-deal-of-week {
  .ps-countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .ps-carousel--swiper {
    border: 3px solid $color-orange-new;
  }

  @media screen and (min-width: 768px) {
    .ps-product {
      height: 100%;
      padding: 30px 0;
      border-right: 1px solid $color-gray-light;
    }

    .ps-carousel--swiper {
      padding: 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .ps-section__header {
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        margin-right: 40px;
      }
    }

    .ps-carousel--swiper {
      padding: 0;
      border: 3px solid $color-orange-new;
    }
  }

  @media screen and (max-width: 480px) {
    .ps-product {
      height: 100%;
      border-right: 1px solid $color-gray-light;
    }

    .ps-countdown {
      display: flex;
      justify-content: center;
    }

    .ps-section__header {
      text-align: center;

      h3 {
        margin-bottom: 10px;
      }
    }

    .ps-carousel--swiper {
      padding: 30px 0 10px;
      border: 3px solid $color-orange-new;

      .ps-carousel--swiper {
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .ps-section__header {
      h3 {
        margin-bottom: 10px;
      }
    }
  }
}

.ps-best-sellers {
  .ps-list--categories {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 20px;

      a {
        font-size: 16px;
        color: $color-1st;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        a {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  .ps-section__header {
    @extend %list-reset;

    h3 {
      margin-bottom: 12px;
    }
  }

  .ps-carousel--swiper {
    border: 1px solid $color-gray-light;
    border-right: none;
  }

  .ps-product {
    border-right: 1px solid $color-gray-light;
  }

  @media screen and (min-width: 768px) {
    .ps-list--categories {
      li {
        margin-right: 30px;

        a {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .ps-section__header {
      h3 {
        margin-bottom: 20px;
      }
    }

    .ps-list--categories {
      li {
        margin-right: 40px;

        a {
          font-size: 25px;
        }
      }
    }
  }
}

.ps-sidebar--shop {
  .widget_shop {
    margin-bottom: 40px;
    padding-bottom: 36px;
    border-bottom: 1px solid #f0f2f5;

    &:last-child {
      border-bottom: none;
    }
  }
}

.ps-testimonials {
  padding: 40px 0;

  .ps-carousel {
    .ps-carousel__item {
      padding: 0 10px;
    }
  }

  @media screen and (min-width: 768px) {
    .ps-carousel {
      .slick-dots {
        margin-top: 40px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .slick-dots {
      margin-top: 30px;
      @include show;
      height: auto;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 80px 0;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 10px;
    .ps-carousel {
      .slick-dots {
        margin-top: 20px;
      }
    }
  }
}

.ps-home-product-with-banner {
  @media screen and (min-width: 1200px) {
    .container {
      display: grid;
      grid-template-columns: 393px 1fr;
      grid-gap: 30px;
    }
    &.ps-reversed {
      .container {
        grid-template-columns: 1fr 393px;
      }

      .ps-section__left {
        grid-column-start: 2;
      }

      .ps-section__right {
        grid-column-start: 1;
        grid-row-start: 1;
      }
    }
  }
}

.ps-section--from-blog {
  .ps-section__header {
    text-align: center;
    padding-bottom: 30px;

    h3 {
      margin-bottom: 0;
      font-weight: 600;
      color: $color-heading;
      font-size: 24px;
    }
  }
}

.ps-blog-items {
  .ps-post--grid {
    margin-bottom: 30px;
  }
}

.ps-customer-bought {
  padding: 40px 0;

  .ps-section__header {
    text-align: center;
    padding-bottom: 30px;

    h3 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .ps-section__content {
    background-color: #fff;
  }

  @media screen and (min-width: 1200px) {
    padding: 80px 0;
  }
}

.ps-latest-reviews {
  .ps-section__header {
    padding-bottom: 30px;

    a {
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
      color: $color-heading;

      &:hover {
        color: $color-orange-new;
      }
    }

    h3 {
      font-size: 25px;
    }
  }

  .ps-section__footer {
    padding-top: 30px;

    a {
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
      color: $color-heading;

      &:hover {
        color: $color-orange-new;
      }
    }
  }
}

.ps-section--category-horizontal {
  &:after {
    display: none;
  }
}

.ps-home-week-deal {
  .container {
    display: grid;
  }

  .ps-section__heading {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1em;
  }

  .ps-section__left {
    position: relative;
    margin-bottom: 30px;

    .ps-section__content {
      padding: 20px 20px;
      border: 3px solid $color-2nd;
      border-radius: 4px;
    }

    .ps-product {
      padding: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .ps-section__heading {
      font-size: 30px;
    }

    .container {
      grid-template-columns: 290px 1fr;
      grid-gap: 30px;
    }

    .ps-section__left {
      margin-bottom: 0;
    }

    .ps-section__right {
      .ps-section__heading {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .ps-shop-items {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 768px) {
    .ps-shop-items {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      display: block;
    }
  }
}

.ps-latest-products {
  .ps-carousel--swiper {
    border: 1px solid $color-gray-light;
    border-right: none;
  }

  .ps-product {
    border-right: 1px solid $color-gray-light;
  }
}

.ps-home-testimonials {
  .ps-section__title {
    margin-bottom: 30px;
    font-size: 30px;

    img {
      margin-right: 15px;
    }
  }

  .ps-review {
    background-color: $color-gray-light;
  }

  .ps-section__left {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .ps-section__title {
      font-size: 40px;
    }

    .ps-carousel {
      .slick-slide {
        > div {
          padding: 0 10px;
        }
      }

      .slick-dots {
        @include show;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
      flex-basis: 100%;
    }

    .ps-section__left {
      max-width: 500px;
      display: block;

      img {
        width: 100%;
      }
    }

    .ps-section__right {
      padding: 0 50px;
      max-width: calc(100% - 500px);
    }
  }

  @media screen and (max-width: 1199px) {
    .ps-section__content {
      padding: 0 30px;
    }
    .slick-dots {
      margin-top: 30px;
    }
  }
}

.ps-section--newsletter {
  @media screen and (max-width: 480px) {
    padding: 0 16px;
    .ps-section__title {
      font-size: 24px;
      line-height: 1.2em;
    }

    .ps-btn {
      width: 100%;
      text-align: center;
      border-color: $color-1st;
    }
  }
}

.ps-promotion--home-seven {
  position: relative;

  span {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 20px;
    padding: 0 16px;
    background-color: #752a2b;
    color: #ffffff;
    border-radius: 50px;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
    color: #752a2b;
    line-height: 1.1em;
  }

  p {
    margin-bottom: 20px;
    color: #752a2b;
    font-size: 16px;
  }

  .ps-btn {
    background-color: #752a2b;

    &:hover {
      background-color: $color-2nd;
    }
  }

  @media screen and (min-width: 1200px) {
    background: none !important;
    h3 {
      font-size: 70px;
    }
    p {
      font-size: 24px;
    }

    .ps-section__content {
      @include vertical-align();
      max-width: 1200px;
      left: calc((100% - 1200px) / 2);
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 160px 0;

    > img {
      display: none;
    }
    .ps-section__content {
      position: relative;
      left: 0;
      padding: 0 30px;
    }
  }
}

.ps-featured-products {
  .ps-section__bottom {
    padding-top: 30px;
    text-align: center;

    .ps-btn {
      height: 26px;
      padding: 0 16px;
      border-color: $color-gray-light;
      font-size: 12px;
      color: $color-heading;

      &:hover {
        background-color: $color-2nd;
        border-color: $color-2nd;
        color: $color-2nd;
      }
    }
  }
}

.ps-blog {
  .ps-pagination {
    padding-bottom: 0;
  }
}

.home-video-section {
  .home-video-section-container {
    .video-container {
      padding: 30px 0px;
      position: relative;
      flex-wrap: wrap;
      overflow-x: clip;
      .slick-list {
        overflow: visible;
      }
      .slick-arrow {
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: 50%;
        position: absolute;
        z-index: 111;
        height: 35px;
        width: 35px;
        border-radius: 10%;
        border: 1px solid transparent;
        font-size: 20px;
        color: $color-dark-gray;
        background-color: rgba(231, 230, 230, 0.568);
        font-size: 0;
      }

      .slick-prev {
        // left: -25px;
        &::before {
          content: "<" !important;
          font-size: 30px;
        }
      }

      .slick-next {
        right: 0px;

        &::before {
          content: ">" !important;
          font-size: 30px;
        }
      }
      .video-content {
        gap: 0px;
        position: relative;
        padding: 0px;

        .video--card {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 15px;
          padding: 16px;
          .video-image {
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            overflow: hidden;
            .video-element {
              object-fit: contain;
              border-radius: 12px;
              height: auto;
              max-height: 100%;
              width: 100%;
              display: block;

              ::-webkit-media-controls {
                display: none !important;
              }

              ::-webkit-media-controls-enclosure {
                display: none !important;
              }

              ::-webkit-media-controls-panel {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

/*----------------------- Blog scss start ------------------------*/
/* BlogOne */
.blog--one {
  .container {
    .blog--one-container {
      .blog--one-content-container {
        padding: 30px 0px;
        .blog-page-title {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: azure;
          padding: 20px 0px;
          .line {
            height: 2px;
            width: 100%;
            background-color: $color-black;
          }
          h1 {
            font-family: "Times New Roman", Times, serif !important;
            font-weight: 400;
            text-transform: uppercase !important;
            word-spacing: 0px;
            color: $color-black;
            margin-bottom: 0;
          }
        }

        .blog--top-section {
          margin-top: 30px;
          padding: 20px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .blog-filter-container {
            display: flex;
            justify-content: end;
            align-items: center;
            .filter-title {
              margin-right: 10px;
              p {
                color: $color-dark-gray;
                width: max-content;
              }
            }
            .selected-values {
              display: flex;
              column-gap: 5px;
              cursor: pointer;
              flex-wrap: wrap;
              margin-right: 20px;
              span {
                padding: 5px 10px;
                margin: 5px;
                border: 1px solid $color-1st;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: max-content;
                .fi {
                  line-height: 0;
                  margin-left: 5px;
                  opacity: 0.8;
                }
              }
            }
            .values {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .filter-values {
                display: flex;
                column-gap: 5px;
                cursor: pointer;
                flex-wrap: wrap;
                width: max-content;

                span {
                  padding: 5px 10px;
                  margin: 5px;
                  border: 1px solid $color-1st;
                  border-radius: 5px;
                  width: max-content;
                  &.selected-tag {
                    background-color: $color-1st;
                    color: $color-white;
                  }
                }
              }
            }
          }
        }

        .blog-section--one {
          margin: 30px 0;
          .blog-section--one-container {
            border-radius: 5px;
            display: flex;
            align-items: stretch;
            .left-content {
              padding: 0;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              .img-container {
                position: relative;
                height: 100%;
                .bg-img {
                  object-fit: cover;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  width: 100%;
                }
              }
            }
            .right-content {
              box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
              border: 1px solid #c1e4e8cb;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              position: relative;
              flex: 1;
              display: flex;
              flex-direction: column;
              .blog-content-container {
                padding: 25px;
                position: relative;
                height: 100%;

                .blog-title {
                  p {
                    color: $color-black;
                    margin-bottom: 5px;
                    opacity: 0.9;
                    font-size: 18px;
                    font-weight: 300;
                    text-transform: capitalize;
                  }
                  h2 {
                    color: $color-1st;
                    text-transform: capitalize;
                    cursor: pointer;

                    &:hover {
                      color: $color-3rd;
                    }
                  }
                }

                .blog-text {
                  p {
                    color: $color-black;
                    opacity: 0.8;
                    font-weight: 300;
                    display: -webkit-box;
                    -webkit-line-clamp: 4; /* Number of visible lines */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                  }
                }

                .read-btn {
                  margin-top: 20px;
                  display: flex;
                  align-items: start;

                  button {
                    outline: none;
                    border: 1px solid $color-1st;
                    font-size: 14px;
                    background-color: transparent;
                    color: $color-1st;
                    border-radius: 5px;
                    padding: 8px 20px;
                    cursor: pointer;

                    &:hover {
                      background-color: $color-1st;
                      color: $color-white;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }

        .blog-bottom-section {
          .blog-bottom-section-container {
            .blog-bottom-title {
              margin-bottom: 30px;
              h2 {
                color: $color-1st;
                text-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
              }
            }
            .blog-category-container {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .blog-category-content {
                padding: 15px;
                border-radius: 5px;
                cursor: pointer;
                .img-container {
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  img {
                    object-fit: cover;
                    border-radius: 10%;
                    padding: 20px;
                  }
                }
                .cat-name {
                  color: $color-white;
                  padding: 10px 5px;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  span {
                    color: $color-black;
                    font-size: 18px;
                    font-weight: 500;
                    text-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                  }
                }
              }
              .slick-arrow {
                display: flex;
                justify-content: space-between;
                align-items: center;
                top: 40%;
                position: absolute;
                z-index: 11;
                height: 35px;
                width: 35px;
                border-radius: 10%;
                border: 1px solid transparent;
                font-size: 20px;
                color: $color-dark-gray;
                background-color: transparent;
                font-size: 0;
              }

              .slick-prev {
                left: -11px;
                &::before {
                  content: "<" !important;
                  font-size: 30px;
                }
              }

              .slick-next {
                right: -11px;

                &::before {
                  content: ">" !important;
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blog--one {
    .container {
      .blog--one-container {
        .blog--one-content-container {
          .blog--top-section {
            margin-top: 30px;
            padding: 0px;
          }
          .blog-section--one {
            .blog-section--one-container {
              .left-content {
                .img-container {
                  .main-img {
                    height: 151px;
                    width: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
              .right-content .blog-content-container .blog-text p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .blog--one .container {
    .blog--one-container {
      .blog--one-content-container {
        .blog-section--one {
          .blog-section--one-container {
            .left-content {
              .img-container {
                .main-img {
                  height: auto;
                  width: auto;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  padding: 20px;
                }
              }
            }
            .right-content {
              .blog-content-container {
                padding: 20px;
                position: relative;
                height: 100%;
                .blog-title {
                  p {
                    font-size: 16px;
                  }
                  h2 {
                    font-size: 22px;
                  }
                }
                .blog-text p {
                  font-size: 12px;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                }

                .read-btn {
                  button {
                    font-size: 12px;
                    padding: 4px 10px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .blog--one {
    .container {
      .blog--one-container {
        .blog--one-content-container {
          .blog-page-title h1 {
            font-size: 38px;
          }
          .blog-section--one {
            .blog-section--one-container {
              border-radius: 5px;
              display: flex;
              // flex-direction: column;
              .col-6,
              .left-content {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
                .img-container {
                  .bg-img {
                    object-fit: cover;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 5px;
                  }
                }
              }
              .col-6,
              .right-content {
                flex: 0 0 100%;
                max-width: 100%;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 0px;
                height: 100%;
                .blog-content-container {
                  .blog-text {
                    p {
                      font-size: 12px;
                      display: -webkit-box;
                      -webkit-line-clamp: 7;
                    }
                  }
                  // .read-btn {
                  //   margin-top: 30px;
                  //   bottom: 30px;
                  //   position: static;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (width: 320px) {
  .blog--one {
    .container {
      .blog--one-container {
        .blog--one-content-container {
          .blog-page-title {
            h1 {
              font-size: 30px;
            }
          }
          .blog-section--one {
            .blog-section--one-container {
              .left-content {
                .img-container {
                  .main-img {
                    height: 150px;
                    width: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/*----------------------- Blog scss end ------------------------*/

/*----------------------- Blog Detail scss Start ------------------------*/
.blog-detail--one {
  .container {
    .blog-detail-content-container {
      .breadcrums-container {
      }
      .blog-detail-container {
        padding: 50px 0px;
        .container {
          .blog-section-container {
            .left-section {
              padding: 0px;
              .left-section-content-container {
                border: 1px solid $color-border;
                padding: 20px;
                border-radius: 5px;
                .blog-title-container {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  text-align: center;
                  padding: 10px 0;
                  border-bottom: 2px solid $color-border1;
                  .date {
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 3px;
                    color: $color-black;
                  }
                  h1 {
                    letter-spacing: 3px;
                    color: $color-black;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    word-wrap: wrap;
                    box-sizing: border-box;
                  }
                  .views {
                    opacity: 0.9;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    width: 100%;
                    text-transform: uppercase;
                    margin-top: 5px;
                    i,
                    .fi {
                      font-size: 18px;
                      line-height: 0;
                      margin-right: 5px;
                      color: $color-1st;
                    }
                  }
                }
                .blog-data-container {
                  padding: 30px 0;
                  .blog-data--section--one {
                    .data-section-one-container {
                      .left-section-data {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .blog-img-container {
                          img {
                            object-fit: contain;
                          }
                        }
                      }
                    }
                    .data-section-one-description {
                      .blog-desciption {
                        .drop-cap {
                          float: left;
                          font-size: 70px;
                          line-height: 1;
                          margin-right: 10px;
                          font-weight: 600;
                        }
                        .subtitle {
                          color: $color-1st;
                        }
                        p,
                        span {
                          color: $color-black;
                          font-weight: 100;
                        }
                        .title-text {
                          font-size: 18px;
                          color: $color-1st;
                          font-weight: 500;
                        }
                        .blog-img-container {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-wrap: wrap;
                          row-gap: 20px;
                          img {
                            width: auto;
                            height: auto;
                            object-fit: contain;
                          }
                        }
                        .blog-imgs-container {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-wrap: wrap;
                          row-gap: 20px;
                          column-gap: 10px;
                          img {
                            width: 392px;
                            height: auto;
                            object-fit: contain;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blog-detail--one {
    .container {
      .blog-detail-content-container {
        .blog-detail-container {
          .container {
            .blog-section-container {
              .left-section {
                .left-section-content-container {
                  .blog-data-container {
                    .blog-data--section--one {
                      .data-section-one-description {
                        .blog-desciption {
                          .blog-imgs-container {
                            img {
                              width: 310px;
                              height: auto;
                              object-fit: contain;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .blog-detail--one {
    .blog-detail-content-container {
      .container {
        .blog-detail-container {
          .container {
            .blog-section-container {
              flex-direction: column;
              row-gap: 15px;
              .col-8,
              .col-4 {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
              }

              .left-section {
                .left-section-content-container {
                  .blog-data-container {
                    .blog-data--section--one {
                      .data-section-one-description {
                        .blog-desciption {
                          .blog-imgs-container {
                            img {
                              width: 100%;
                              height: auto;
                              object-fit: contain;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .blog-detail--one {
    .container {
      .blog-detail-content-container {
        .blog-detail-container {
          .container {
            .blog-section-container {
              .left-section {
                .left-section-content-container {
                  .blog-title-container {
                    h1 {
                      font-size: 30px;
                    }
                  }
                }
                .related-blog-section-container {
                  .related-blog-container {
                    padding: 0 !important;
                    &:nth-child(odd) {
                      padding: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/*----------------------- Blog Detail scss End ------------------------*/
