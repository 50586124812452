/*
    Project: My Medi HTML Template
    Version: 1.0
    Date Created: 20/02/2021
    Developed by: nouthemes
*/
/*
    TABLE OF CONTENT
        1. RESET AND TYPHOGRAPHY
            1.1 typhography
        2. ELEMENTS
            2.1 grid
            2.2 list
            2.3 button
            2.4 form
            2.5 post
            2.6 pagination
            2.7 breadcrumb
            2.8 block
            2.9 widget
            2.10 slider
            2.11 product
            2.12 banner
            2.13 filter
            2.14 table
            2.15 loader
            2.16 modal
            2.17 tabs
            2.18 promo
            2.19 blog
        3. COMPONENTS
             3.1 Cart
             3.2 forms
             3.3 search
             3.4 section
             3.5 pages
             3.6 Notify
             3.7 Countdown
             3.8 Social
             3.9 menu
             3.10 landing
             3.11 portfolio
             3.12 coming
        4. MODULES
            4.1 header
            4.2 footer
        5. HELPERS
        6. CUSTOM
*/
:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.09 * var(--space-unit));
  --space-xxxs: calc(0.146 * var(--space-unit));
  --space-xxs: calc(0.236 * var(--space-unit));
  --space-xs: calc(0.382 * var(--space-unit));
  --space-sm: calc(0.618 * var(--space-unit));
  --space-md: calc(1 * var(--space-unit));
  --space-lg: calc(1.618 * var(--space-unit));
  --space-xl: calc(2.618 * var(--space-unit));
  --space-xxl: calc(4.236 * var(--space-unit));
  --space-xxxl: calc(6.854 * var(--space-unit));
  --space-xxxxl: calc(11.08 * var(--space-unit));
  --component-padding: var(--space-xxxl);
}

/*1. RESET AND TYPHOGRAPHY */
* {
  font-family: "Jost", sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  color: #222;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  font-size: 16px;
  line-height: 1.6em;
  color: #9babcd;
}

p span {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
  transition: all 0.4s ease;
}

a:hover {
  color: #fd8d27;
}

a,
input,
textarea,
button,
select {
  outline: none;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
  text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
  max-width: 100%;
  width: 100%;
}

.ps-document ul,
.ps-document ol {
  margin-bottom: 10px;
}

.ps-document ul ul,
.ps-document ul ol,
.ps-document ol ul,
.ps-document ol ol {
  margin-bottom: 0;
}

.ps-document ul li,
.ps-document ol li {
  color: #9babcd;
  font-size: 16px;
  line-height: 1.6em;
}

.ps-document p {
  margin-bottom: 15px;
}

.ps-document p strong {
  color: #222;
}

figure {
  margin: 0;
}

.tooltip-inner {
  background-color: #f0f2f5 !important;
  color: #103178;
  font-size: 12px;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #f0f2f5 !important;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #f0f2f5 !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #f0f2f5 !important;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #f0f2f5 !important;
}

.desktop-only {
  display: none !important;
}

@media (min-width: 768px) {
  .desktop-only {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

.ps-layout .ps-menu--slidebar {
  padding-top: 80px;
}

/*2. ELEMENTS */
html,
body {
  min-height: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1279px) {
  .container {
    max-width: 1270px;
  }
}

body {
  overflow-x: hidden;
}

.ps-list--social {
  margin: 0;
  padding: 0;
}

.ps-list--social li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  vertical-align: top;
}

.ps-list--social li a {
  display: inline-block;
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #636363;
  border: 1px solid #404040;
}

.ps-list--social li a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.ps-list--social li a:hover {
  color: #103178;
  border-color: #103178;
  background-color: #103178;
}

.ps-list--social li a:hover i {
  color: #ffffff;
}

.ps-list--social li a.facebook {
  background-color: #3b5998;
}

.ps-list--social li a.facebook i {
  color: #ffffff;
}

.ps-list--social li a.twitter {
  background-color: #0084b4;
}

.ps-list--social li a.twitter i {
  color: #ffffff;
}

.ps-list--social li a.google-plus {
  background-color: #ff0000;
}

.ps-list--social li a.google-plus i {
  color: #ffffff;
}

.ps-list--social li a.instagram i {
  color: #c32aa3;
}

.ps-list--social li:last-child {
  margin-right: 0;
}

.ps-list--social.simple li {
  margin-right: 0;
}

.ps-list--social.simple li a {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.ps-list--social.square li {
  margin-right: 10px;
}

.ps-list--social.square li a {
  width: 85px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: none;
}

.ps-list--social-color {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ps-list--social-color li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.ps-list--social-color li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 38px;
  height: 38px;
  color: #ffffff;
}

.ps-list--social-color li a.facebook {
  background-color: #1b4e9b;
}

.ps-list--social-color li a.facebook:hover {
  background-color: #296fda;
}

.ps-list--social-color li a.twitter {
  background-color: #00aeef;
}

.ps-list--social-color li a.twitter:hover {
  background-color: #3dcaff;
}

.ps-list--social-color li a.google-plus {
  background-color: #cc0000;
}

.ps-list--social-color li a.google-plus:hover {
  background-color: #ff1a1a;
}

.ps-list--social-color li a.linkedin {
  background-color: #2d567f;
}

.ps-list--social-color li a.linkedin:hover {
  background-color: #417cb7;
}

.ps-list--social-color li a.feed {
  background-color: #f86a02;
}

.ps-list--social-color li a.feed:hover {
  background-color: #fe9549;
}

.ps-list--checked li {
  margin-bottom: 20px;
}

.ps-list--checked li a {
  position: relative;
  display: block;
  padding-left: 30px;
  font-size: 14px;
  color: #313131;
}

.ps-list--checked li a:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
  border: solid 1px #8d8d8d;
  transition: all 0.4s ease;
}

.ps-list--checked li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 8px;
  color: #fff;
  width: 6px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  visibility: hidden;
  opacity: 0;
}

.ps-list--checked li a:hover:before {
  background-color: #103178;
  border-color: #103178;
}

.ps-list--checked li a:hover:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked li.current a:before {
  background-color: #103178;
  border-color: #103178;
}

.ps-list--checked li.current a:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked.ps-list--checked-circle li a:before {
  border-radius: 50%;
}

.ps-list--checked.ps-list--checked-circle li a:hover:before {
  background-color: #a7a7a7;
  border-color: #a7a7a7;
}

.ps-list--arrow a {
  position: relative;
  display: block;
  padding-left: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #555555;
}

.ps-list--arrow a:before {
  content: "\f105";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-family: FontAwesome;
}

.ps-list--arrow a:hover {
  color: #103178;
}

.ps-list--arrow a:hover .circle {
  background-color: #ffc800;
  border-color: #ffc800;
}

.ps-list--arrow a:hover .circle:before {
  color: #fff;
  visibility: visible;
  opacity: 1;
}

.ps-list--arrow li {
  margin-bottom: 20px;
}

.ps-list--arrow li.current a {
  color: #ffc800;
}

.ps-list--arrow li.current .circle {
  background-color: #ffc800;
}

.ps-list--arrow li.current .circle:before {
  visibility: visible;
  opacity: 1;
  color: #fff;
}

.ps-list--dot {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  list-style-type: none;
}

.ps-list--dot li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #000000;
  line-height: 20px;
}

.ps-list--dot li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #103178;
  border-radius: 50px;
}

.ps-list--plus li {
  margin: 10px 0;
}

.ps-list--plus li a {
  display: block;
  position: relative;
  padding-left: 15px;
  line-height: 20px;
}

.ps-list--plus li a:before {
  content: "+";
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}

.ps-list--line li a {
  position: relative;
  display: inline-block;
  padding: 5px 0;
  line-height: 20px;
  color: #9babcd;
}

.ps-list--line li a:before {
  content: "";
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 1px;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
}

.ps-list--line li a:hover {
  color: #222;
}

.ps-list--line li a:hover:before {
  transform-origin: 100% 50%;
  transform: scale3d(1, 1, 1);
}

.form-control {
  outline: none;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  border: none;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ddd;
  transition: all 0.4s ease;
  box-shadow: 0 0 0 transparent;
  background-color: transparent;
}

.form-control::-webkit-input-placeholder {
  opacity: 1;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  opacity: 1;
  font-size: 14px;
}

.form-control:-moz-placeholder {
  opacity: 1;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  font-size: 14px;
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 #000;
  border-color: #103178;
}

textarea.form-control {
  height: auto;
  padding: 2rem;
  resize: none;
}

.ps-checkbox {
  position: relative;
  display: block;
}

.ps-checkbox > input {
  position: absolute;
  visibility: hidden;
}

.ps-checkbox label {
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  font-family: "Jost", sans-serif;
  color: rgba(34, 34, 34, 0.5);
  font-weight: 400;
  cursor: pointer;
}

.ps-checkbox label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border: 1px solid #222;
  transition: all 0.4s ease;
}

.ps-checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  z-index: 10;
  opacity: 0;
  transform: rotate(0deg);
  transition: all 0.4s ease;
}

.ps-checkbox input[type="checkbox"]:checked ~ label:before {
  background-color: #ffc800;
  border-color: #ffc800;
}

.ps-checkbox input[type="checkbox"]:checked ~ label:after {
  transform: rotate(45deg);
  opacity: 1;
}

.ps-checkbox--circle label {
  font-size: 20px;
  font-weight: 600;
  color: #555;
}

.ps-checkbox--circle label:before {
  border-radius: 50%;
  border-color: #595959;
}

.ps-checkbox--circle input[type="checkbox"]:checked ~ label:before {
  background-color: transparent;
}

.ps-checkbox--circle input[type="checkbox"]:checked ~ label:after {
  background-color: #103178;
  border-radius: 50%;
  border: none;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
}

.ps-checkbox--inline {
  display: inline-block;
  margin-bottom: 1rem;
}

.ps-checkbox--color {
  margin-right: 1rem;
}

.ps-checkbox--color > label {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  padding-left: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.ps-checkbox--color > label:before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
}

.ps-checkbox--color > label:after {
  display: none !important;
}

.ps-checkbox--color input[type="radio"]:checked ~ label {
  background-color: transparent;
}

.ps-checkbox--color input[type="radio"]:checked ~ label:before {
  background-color: transparent;
  border: 1px solid #ccc;
}

.ps-checkbox--size {
  margin-right: 1rem;
}

.ps-checkbox--size > label {
  margin-bottom: 0;
  padding: 8px 20px;
  border: none;
  color: #222222;
  background-color: #f6f6f6;
  border-radius: 0;
  transition: all 0.4s ease;
}

.ps-checkbox--size > label:before,
.ps-checkbox--size > label:after {
  display: none;
}

.ps-checkbox--size input[type="checkbox"]:checked ~ label {
  color: #ffffff;
  background-color: #a7a7a7;
}

.ps-radio {
  position: relative;
}

.ps-radio > input {
  position: absolute;
  visibility: hidden;
}

.ps-radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;
  font-family: "Jost", sans-serif;
  color: #737373;
  font-weight: 400;
  cursor: pointer;
}

.ps-radio label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border: 1px solid rgba(19, 8, 1, 0.2);
  background-color: #fff;
  transition: all 0.4s ease;
  border-radius: 50%;
}

.ps-radio label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 15;
  opacity: 0;
  transform: scale(1.5, 1.5);
  transition: all 0.6s ease;
}

.ps-radio input[type="radio"]:checked ~ label:before {
  background-color: transparent;
}

.ps-radio input[type="radio"]:checked ~ label:after {
  background-color: #103178;
  border-radius: 50%;
  border: none;
  width: 8px;
  height: 8px;
  top: 6px;
  left: 6px;
  opacity: 1;
}

.ps-radio--inline {
  display: inline-block;
  margin-right: 20px;
}

.ps-radio--color {
  margin-right: 1rem;
}

.ps-radio--color > label {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  padding-left: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.ps-radio--color > label:before {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
}

.ps-radio--color > label:after {
  display: none !important;
}

.ps-radio--color input[type="radio"]:checked ~ label {
  background-color: rgba(61, 196, 126, 0.5);
}

.form-group {
  margin-bottom: 2.5rem;
}

.form-group > label {
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #000000;
  line-height: 1em;
  font-size: 14px;
}

.form-group > label sup {
  color: #f00000;
}

.form-group--inline {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-group--inline > label {
  margin-bottom: 0;
  font-weight: 600;
  max-width: 160px;
}

.form-group--inline .form-group__content {
  width: 100%;
}

@media (max-width: 767px) {
  .form-group--inline {
    display: block;
  }
}

.form-group--number {
  display: inline-block;
  position: relative;
}

.form-group--number button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  max-width: 20px;
  color: #222;
  font-size: 30px;
  border: none;
  background: none;
}

.form-group--number button.up {
  right: 12px;
}

.form-group--number button.up:before,
.form-group--number button.up:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  width: 14px;
  height: 1px;
  background-color: #9babcd;
}

.form-group--number button.up:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.form-group--number button.up:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number button.down {
  left: 12px;
}

.form-group--number button.down:before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  width: 14px;
  height: 1px;
  background-color: #9babcd;
  transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number .form-control {
  border: 2px solid #eaeaea;
  height: 45px;
  padding: 0 25px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #222;
  background-color: transparent;
}

.form-group--number .form-control::-webkit-input-placeholder {
  color: #222;
}

.form-group--number .form-control::-moz-placeholder {
  color: #222;
}

.form-group--number .form-control:-moz-placeholder {
  color: #222;
}

.form-group--number .form-control:-ms-input-placeholder {
  color: #222;
}

.form-group--number-2 {
  position: relative;
}

.form-group--number-2 button {
  position: absolute;
  right: 5px;
  width: 12px;
  height: 12px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
}

.form-group--number-2 button.up {
  top: 3px;
  align-items: flex-end;
}

.form-group--number-2 button.down {
  bottom: 6px;
  align-items: flex-start;
}

.form-group--select {
  position: relative;
}

.form-group--select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.form-group--select select {
  height: 40px;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.form-group--nest {
  display: flex;
  flex-flow: row nowrap;
}

.form-group--nest .ps-btn {
  border-radius: 0 4px 4px 0;
  padding-left: 25px;
  padding-right: 25px;
}

.form-group--nest input {
  border-radius: 4px 0 0 4px;
  border-color: #ccc;
  border-right: none;
}

.form-group--icon {
  position: relative;
}

.form-group--icon button {
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-group--icon button i:before {
  font-size: 20px;
}

.form-group--icon .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.ps-dropdown {
  position: relative;
  display: inline-block;
}

.ps-dropdown > a {
  position: relative;
  display: block;
  padding-right: 20px;
}

.ps-dropdown > a:after {
  content: "\e93a";
  font: normal normal normal 12px/1 linearicons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.ps-dropdown ul {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  min-width: 120px;
  max-width: 200px;
  width: 100%;
  padding: 4px 0;
  background-color: #fff;
  box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
  visibility: hidden;
  opacity: 0;
  text-align: left;
}

.ps-dropdown ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ps-dropdown ul li a {
  display: block;
  font-size: 1.3rem;
  color: #222;
  padding: 6px 10px;
}

.ps-dropdown:hover ul {
  visibility: visible;
  opacity: 1;
}

.ps-dropdown.open ul {
  visibility: visible;
  opacity: 1;
}

.select2 {
  display: block;
  min-width: 100%;
}

.select2 .select2-selection--single {
  height: auto;
  outline: none;
  border-radius: 0;
}

.select2 .select2-selection--single .select2-selection__rendered {
  line-height: 20px;
  padding: 10px 15px;
}

.select2 .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 20px;
  height: 20px;
}

.select2 .select2-selection--single .select2-selection__arrow:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select2 .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2 .select2-selection--multiple {
  min-height: 0;
  border: none !important;
}

.select2 .select2-selection--multiple .select2-selection__rendered {
  margin-bottom: 0;
  padding: 0;
  padding-left: 10px;
  vertical-align: top;
}

.select2 .select2-selection--multiple .select2-selection__rendered input {
  margin-top: 0;
  height: 40px;
  padding: 0 10px;
}

.select2 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  line-height: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

.select2-container .select2-dropdown {
  border-color: #ccc;
}

.select2-container .select2-results li {
  color: #222;
  font-size: 16px;
  padding: 6px 16px;
  background-color: transparent;
}

.select2-container .select2-results li.select2-results__option--highlighted {
  background-color: #103178;
}

.form-group--inside {
  position: relative;
}

.form-group--inside button {
  right: 10px;
  border: none;
  color: #ce873a;
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-group--inside button:hover {
  color: #ab6d2a;
}

.ps-color {
  display: flex;
  justify-content: space-between;
  color: #bbb;
  width: 100%;
}

.ps-color .ps-color__swatch {
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  vertical-align: middle;
}

.ps-color .ps-color__left i {
  font-size: 14px;
  color: #999;
  font-style: normal;
}

.ps-color .ps-color__right i {
  color: #eaeaea;
}

.ps-color.black .ps-color__swatch {
  background-color: #000;
}

.ps-color.blue .ps-color__swatch {
  background-color: #70849d;
}

.ps-color.gold .ps-color__swatch {
  background-color: #cc9900;
}

.ps-color.nude .ps-color__swatch {
  background-color: #cfa58f;
}

.ps-color.orange .ps-color__swatch {
  background-color: #cc6600;
}

.ps-color.red .ps-color__swatch {
  background-color: #990000;
}

.ps-color.active .ps-color__right i {
  color: #222;
}

.ps-color.active .ps-color__right i:before {
  content: "\e999";
}

.ps-variant {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: 10;
  border: 1px solid #999;
  cursor: pointer;
}

.ps-variant__tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 5px 10px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-variant__tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
}

.ps-variant:last-child {
  margin-right: 0;
}

.ps-variant.ps-variant--size {
  position: relative;
  vertical-align: top;
  border: none;
  background-color: #f1f1f1;
  transition: all 0.4s ease;
}

.ps-variant.ps-variant--size .ps-variant__size {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #9babcd;
  font-size: 12px;
}

.ps-variant.ps-variant--size.active .ps-variant__size {
  color: #222;
}

.ps-variant.ps-variant--size:hover {
  background-color: #103178;
}

.ps-variant.ps-variant--size:hover .ps-variant__size {
  color: #ffffff;
}

.ps-variant.ps-variant--color {
  border: 1px solid #ccc;
}

.ps-variant.ps-variant--color:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ps-variant.ps-variant--color.color--1:before {
  background-color: #70849d;
}

.ps-variant.ps-variant--color.color--2:before {
  background-color: #cfa58f;
}

.ps-variant.ps-variant--color.color--3:before {
  background-color: #f06292;
}

.ps-variant.ps-variant--image img {
  border-radius: 50%;
  max-width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ps-variant:hover .ps-variant__tooltip {
  visibility: visible;
  opacity: 1;
}

.ps-variant.active:before {
  border-color: #000;
}

.ps-variant.active .ps-variant__tooltip {
  visibility: visible;
  opacity: 1;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 3px;
  width: 13px;
  height: 13px;
}

.custom-control-label::before {
  background-color: white;
  border: 1px solid #103178;
}

.br-widget a::after {
  color: #fd8d27 !important;
}

.form-check {
  padding-left: 0;
}

.form-check .form-check-input {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: #f0f2f5;
  display: none;
}

.form-check .form-check-input:focus {
  box-shadow: none;
}

.form-check .form-check-input:checked + label:before {
  background-color: #103178;
}

.form-check .form-check-input:checked + label:after {
  opacity: 1;
}

.form-check .form-check-label {
  margin: 0;
  position: relative;
  display: block;
  line-height: 22px;
  -webkit-padding-start: 27px;
  padding-inline-start: 27px;
  transition: all 0.35s ease-in-out 0s;
  cursor: pointer;
}

.form-check .form-check-label::before {
  content: "";
  width: 16px;
  height: 16px;
  background: #f0f2f5;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 4px;
  transition: all 0.35s ease-in-out 0s;
}

.form-check .form-check-label::after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8'%3E%3Cpath fill='%23fff' d='M9.942 1.35L3.535 7.758 0 4.209l1.353-1.348 2.185 2.193L8.59 0l1.35 1.35z'/%3E%3C/svg%3E");
  position: absolute;
  left: 3px;
  top: 0;
  opacity: 0;
}

.ps-post .ps-post__title {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 10px;
  color: #103178;
}

.ps-post .ps-post__content {
  padding-top: 20px;
}

.ps-post .ps-blog__badge {
  margin-bottom: 10px;
}

.ps-post .ps-badge__item {
  margin: 0 5px 5px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 40px;
  display: inline-block;
  text-transform: uppercase;
  color: white;
  background-color: #103178;
  font-weight: 600;
}

.ps-post .ps-blog__meta {
  margin-bottom: 45px;
  color: #5b6c8f;
  font-size: 14px;
  line-height: 20px;
}

.ps-post .ps-blog__date {
  font-weight: 600;
  margin-right: 15px;
}

.ps-post .ps-blog__banner {
  margin-bottom: 50px;
}

.ps-post .ps-blog__banner img {
  width: 100%;
  border-radius: 4px;
}

.ps-post .ps-blog__text-large {
  margin-bottom: 40px;
  font-size: 22px;
  color: #103178;
}

.ps-post .ps-blog__text {
  margin-bottom: 40px;
  color: #5b6c8f;
  font-size: 16px;
}

.ps-post .ps-blog__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-post .ps-review {
  background-color: #f0f2f5;
  height: 100%;
}

.ps-post .ps-blog__row {
  margin-bottom: 40px;
}

.ps-post .ps-blog__row .ps-blog__text,
.ps-post .ps-blog__row .ps-blog__text-large {
  margin-bottom: 20px;
}

.ps-post .ps-section--blog {
  margin-bottom: 30px;
  padding: 70px 0 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ps-post .ps-form--review {
  margin: 80px 0;
}

.ps-post .ps-comment--post {
  border-top: 1px solid #f0f2f5;
  padding-top: 70px;
  margin-top: 50px;
}

@media (min-width: 1280px) {
  .ps-post .ps-post__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-post .ps-blog__text-large {
    font-size: 24px;
  }
}

.ps-comment--post .ps-comment__title {
  color: #103178;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 36px;
}

.ps-comment--post .ps-comment__children {
  border-left: 30px solid #f0f2f5;
}

.ps-comment--post .ps-review--product {
  border-color: #f0f2f5;
  border-bottom: none;
}

.ps-comment--post .ps-comment__list {
  border-bottom: 1px solid #f0f2f5;
}

@media (min-width: 1280px) {
  .ps-comment--post .ps-comment__title {
    font-size: 40px;
    line-height: 50px;
  }
}

.ps-post--full .ps-comment--post {
  margin-top: 0;
  border-top: none;
  padding-top: 30px;
}

.ps-post--full .ps-post__line {
  border-top: 1px solid #f0f2f5;
  padding-bottom: 80px;
  margin-top: 50px;
}

@media (min-width: 1280px) {
  .ps-post--full .ps-post__wrapper {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
}

.ps-pagination {
  padding: 30px;
}

.ps-pagination .pagination {
  margin-bottom: 0;
  justify-content: center;
}

.ps-pagination .pagination a {
  width: 22px;
  height: 22px;
  margin: 0 4px;
  font-size: 14px;
  color: #103178;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ps-pagination .pagination a i {
  font-weight: bold;
}

.ps-pagination .pagination .active a {
  background-color: #103178;
  color: white;
  border-radius: 50%;
}

.ps-breadcrumb {
  list-style: none;
  padding: 20px 0 !important;
  margin: 0;
}

.ps-breadcrumb__item {
  position: relative;
  display: inline-block;
  color: #103178;
  font-size: 14px;
}

.ps-breadcrumb__item:before {
  content: "\203A";
  display: inline-block;
  border-radius: 50%;
  margin: 0 10px;
  color: #103178;
}

.ps-breadcrumb__item a {
  color: #103178;
  font-size: 14px;
}

.ps-breadcrumb__item a:hover {
  color: #fd8d27;
}

.ps-breadcrumb__item:first-child:before {
  display: none;
}

.ps-section--block-grid {
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.ps-section--block-grid .ps-section__thumbnail {
  margin-bottom: 30px;
  padding: 0 15px;
}

.ps-section--block-grid .ps-section__thumbnail img {
  width: 100%;
  transition: 600ms ease 0s;
}

.ps-section--block-grid .ps-section__thumbnail img:hover {
  transform: scale(1.1, 1.1);
}

.ps-section--block-grid .ps-section__thumbnail .ps-section__image {
  overflow: hidden;
  display: block;
}

.ps-section--block-grid .ps-section__content {
  margin-bottom: 30px;
  padding: 0 15px;
}

.ps-section--block-grid .ps-section__title {
  font-size: 26px;
  line-height: 30px;
  color: #103178;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-section--block-grid .ps-section__subtitle {
  font-size: 22px;
  line-height: 26px;
  color: #103178;
  margin-bottom: 20px;
}

.ps-section--block-grid .ps-section__desc {
  color: #5b6c8f;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 26px;
}

.ps-section--block-grid .ps-section__list {
  margin-bottom: 20px;
}

.ps-section--block-grid .ps-section__list li {
  font-size: 14px;
  color: #103178;
  padding: 6px 0;
  line-height: 20px;
}

.ps-section--block-grid .ps-section__list li::before {
  content: "\f00c";
  color: #12a05c;
  font-size: 75%;
  display: inline-block;
  font-weight: 900;
  font: normal normal normal 14px/1 FontAwesome;
  margin: -3px 10px 0 0;
}

@media (min-width: 768px) {
  .ps-section--block-grid {
    display: flex;
  }
  .ps-section--block-grid.row-reverse {
    flex-direction: row-reverse;
  }
  .ps-section--block-grid > * {
    flex: 0 0 50%;
  }
  .ps-section--block-grid .ps-section__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1280px) {
  .ps-section--block-grid .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-section--block-grid .ps-section__subtitle {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-bought {
  padding-bottom: 80px;
}

.ps-bought .ps-bought__title {
  font-size: 22px;
  line-height: 26px;
  color: #103178;
  font-weight: 600;
  margin-bottom: 20px;
}

.ps-bought .ps-bought__wapper {
  border: 3px solid #fd8d27;
  border-radius: 4px;
}

.ps-bought .ps-bought__thumbnail img {
  max-width: 120px;
  width: 100%;
  height: auto;
}

.ps-bought .ps-bought__thumbnail li {
  border-bottom: 1px solid #f0f2f5;
  padding: 15px 0;
}

.ps-bought .ps-bought__thumbnail li:last-child a:after {
  display: none;
}

.ps-bought .ps-bought__thumbnail a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.ps-bought .ps-bought__thumbnail a::after {
  content: "+";
  background: #103178;
  color: white;
  width: 24px;
  height: 24px;
  line-height: 22px;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -27px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  text-align: center;
}

.ps-bought .ps-bought__list {
  padding: 25px 30px 0;
  list-style: none;
  margin: 0;
}

.ps-bought .ps-bought__list li {
  font-size: 14px;
  line-height: 22px;
  padding: 3px 0 5px;
}

.ps-bought .ps-bought__name {
  color: #103178;
  font-size: 14px;
}

.ps-bought .ps-bought__price {
  color: #103178;
  font-size: 14px;
  font-weight: 600;
}

.ps-bought .ps-bought__price.sale {
  color: #fd8d27;
}

.ps-bought .ps-bought__del {
  font-size: 14px;
  color: #9babcd;
  margin-right: 10px;
  text-decoration: line-through;
}

.ps-bought .ps-bought__submit {
  padding: 20px 30px 30px;
}

.ps-bought .ps-bought__submit label {
  color: #103178;
  font-weight: 500;
  font-size: 14px;
}

.ps-bought .ps-bought__submit .ps-btn {
  font-size: 16px;
  padding: 8px 15px;
  display: inline-block;
  width: auto;
}

.ps-bought .ps-bought__total {
  color: #fd8d27;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .ps-bought .ps-bought__thumbnail ul {
    display: flex;
    border-collapse: collapse;
  }
  .ps-bought .ps-bought__thumbnail li {
    flex-grow: 1;
    border: 1px solid #f0f2f5;
    padding: 15px;
  }
  .ps-bought .ps-bought__thumbnail img {
    max-width: 100%;
  }
  .ps-bought .ps-bought__thumbnail a::after {
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 50%;
    margin-top: -30px;
    right: -30px;
    left: auto;
    bottom: auto;
  }
  .ps-bought .ps-bought__content {
    display: flex;
  }
  .ps-bought .ps-bought__list {
    width: 70%;
    flex-basis: 70%;
    padding: 17px 20px;
  }
  .ps-bought .ps-bought__submit {
    width: 30%;
    flex-basis: 30%;
    padding: 17px 20px 20px 0;
  }
}

@media (min-width: 992px) {
  .ps-bought .ps-bought__submit {
    width: 25%;
    flex-basis: 25%;
    padding: 25px 30px 30px 0;
  }
  .ps-bought .ps-bought__list {
    padding: 17px 30px;
    width: 75%;
    flex-basis: 75%;
  }
}

@media (min-width: 1280px) {
  .ps-bought .ps-bought__title {
    font-size: 24px;
    line-height: 30px;
  }
  .ps-bought .ps-bought__submit .ps-btn {
    font-size: 18px;
    padding: 10px 25px;
  }
  .ps-bought .ps-bought__total {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 992px) {
  .ps-bought--vertical .ps-bought__wapper {
    display: flex;
  }
  .ps-bought--vertical .ps-bought__thumbnail {
    width: 60%;
    flex-basis: 60%;
  }
  .ps-bought--vertical .ps-bought__thumbnail ul {
    height: 100%;
  }
  .ps-bought--vertical .ps-bought__content {
    width: 40%;
    flex-basis: 40%;
    display: block;
  }
  .ps-bought--vertical .ps-bought__list {
    width: 100%;
  }
  .ps-bought--vertical .ps-bought__submit {
    width: 100%;
    padding: 0 30px 20px;
  }
}

@media (min-width: 1280px) {
  .ps-bought--vertical .ps-bought__list,
  .ps-bought--vertical .ps-bought__submit {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.ps-bought--dark .ps-bought__title,
.ps-bought--dark .ps-bought__name,
.ps-bought--dark .ps-bought__submit label {
  color: #333;
}

.ps-bought--dark .form-check .form-check-input:checked + label:before {
  background-color: #333;
}

.ps-bought--dark .ps-bought__price {
  color: #333;
}

.ps-bought--dark .ps-bought__thumbnail a::after {
  background-color: #333;
}

.ps-section--mask {
  background-color: #103178;
  padding: 10px 0 80px;
}

.ps-section--mask .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
}

.ps-section--mask .ps-section__item {
  padding: 0 15px;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 20px;
}

.ps-section--mask .ps-section__item:last-child {
  margin-bottom: 0;
}

.ps-section--mask .ps-section__thumbnail {
  margin-bottom: 20px;
  text-align: center;
}

.ps-section--mask .ps-section__thumbnail img {
  max-width: 260px;
}

.ps-section--mask .ps-section__text {
  text-align: center;
  margin-bottom: 0;
}

.ps-section--mask .ps-section__text a {
  color: white;
  font-size: 18px;
}

@media (min-width: 768px) {
  .ps-section--mask {
    padding: 80px 0;
  }
  .ps-section--mask .ps-section__item {
    width: 33.3333333333%;
    flex-basis: 33.3333333333%;
    margin-bottom: 0;
  }
  .ps-section--mask .ps-section__thumbnail img {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .ps-section--mask .ps-section__content {
    max-width: 990px;
    margin: auto;
  }
}

.ps-section--mask-info {
  background-color: #e5f5f4;
}

.ps-section--mask-info .ps-section__title {
  color: #333;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.ps-section--mask-info .ps-section__subtitle {
  color: #00a198;
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

.ps-section--mask-info .ps-btn {
  width: auto;
  margin: auto;
  font-size: 14px;
  padding: 5px 25px;
}

.ps-section--mask-info .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
}

.ps-section--mask-info .ps-section__item {
  padding: 0 15px;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.ps-section--mask-info .ps-section__item:last-child {
  margin-bottom: 0;
}

.ps-section--mask-info .ps-section__thumbnail {
  margin-bottom: 20px;
  text-align: center;
}

.ps-section--mask-info .ps-section__thumbnail img {
  max-width: 300px;
}

.ps-section--mask-info .ps-section__text {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.ps-section--mask-info .ps-section__text a {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .ps-section--mask-info .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-section--mask-info .ps-section__subtitle {
    font-size: 18px;
  }
  .ps-section--mask-info .ps-section__item {
    width: 33.3333333333%;
    flex-basis: 33.3333333333%;
    margin-bottom: 0;
    border-right: 1px solid #d9dee8;
  }
  .ps-section--mask-info .ps-section__item:last-child {
    border-right: none;
  }
  .ps-section--mask-info .ps-section__thumbnail img {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .ps-section--mask-info .ps-section__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-section--mask-info .ps-section__subtitle {
    font-size: 20px;
  }
  .ps-section--mask-info .ps-section__content {
    max-width: 990px;
    margin: auto;
  }
  .ps-section--mask-info .ps-btn {
    font-size: 16px;
  }
}

.ps-mask--service {
  background-color: #00a198;
  padding: 80px 0;
  margin-bottom: 80px;
}

.ps-mask--service .ps-mask__item {
  margin-bottom: 40px;
}

.ps-mask--service .ps-mask__item:last-child {
  margin-bottom: 0;
}

.ps-mask--service .ps-mask__item .ps-mask__icon {
  margin-bottom: 15px;
}

.ps-mask--service .ps-mask__item .ps-mask__icon img {
  width: 36px;
  height: 36px;
}

.ps-mask--service .ps-mask__item .ps-mask__title {
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.ps-mask--service .ps-mask__item .ps-mask__desc {
  color: white;
  font-size: 14px;
  margin-bottom: 0;
}

.ps-mask--service .ps-mask__content {
  position: relative;
}

.ps-mask--service .ps-mask__right {
  margin-top: -130px;
}

.ps-mask--service .ps-mask__thumbnail {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .ps-mask--service {
    padding: 0;
  }
  .ps-mask--service .ps-mask__right {
    text-align: right;
    margin-top: 0;
  }
  .ps-mask--service .ps-mask__thumbnail {
    position: relative;
  }
  .ps-mask--service .ps-mask__thumbnail img {
    position: absolute;
    top: 0;
    left: -55px;
    width: calc(100% + 180px);
    max-width: none;
  }
  .ps-mask--service .ps-mask__left,
  .ps-mask--service .ps-mask__right {
    padding: 80px 0;
  }
}

@media (min-width: 992px) {
  .ps-mask--service .ps-mask__thumbnail img {
    left: 0;
    width: calc(100% + 120px);
  }
}

@media (min-width: 1280px) {
  .ps-mask--service .ps-mask__thumbnail img {
    left: 30px;
    width: calc(100% + 30px);
  }
  .ps-mask--service .ps-mask__item .ps-mask__title {
    font-size: 22px;
  }
  .ps-mask--service .ps-mask__item .ps-mask__desc {
    font-size: 16px;
  }
}

.ps-project--info .ps-project__title {
  font-weight: 600;
  color: #333;
  text-align: center;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 50px;
}

.ps-project--info .ps-project__item {
  text-align: center;
  margin-bottom: 50px;
}

.ps-project--info .col-12:last-child .ps-project__item {
  margin-bottom: 0;
}

.ps-project--info .ps-project__name {
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  font-size: 20px;
}

.ps-project--info .ps-project__thumbnail {
  margin-bottom: 50px;
}

.ps-project--info .ps-project__thumbnail img {
  width: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-project--info .ps-project__desc {
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
}

@media (min-width: 768px) {
  .ps-project--info .ps-project__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-project--info .ps-project__item {
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .ps-project--info .ps-project__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-project--info .ps-project__name {
    font-size: 30px;
  }
  .ps-project--info .ps-project__desc {
    font-size: 16px;
  }
}

.ps-product--type .ps-section__title {
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
  color: #333;
  font-size: 20px;
}

.ps-product--type .ps-category__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.ps-product--type .ps-category__item {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.ps-product--type .ps-category__icon {
  margin-right: 20px;
  width: 24px;
  height: 24px;
}

.ps-product--type .ps-category__name {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 17px;
}

@media (min-width: 768px) {
  .ps-product--type .ps-category__content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .ps-product--type .ps-category__content {
    grid-template-columns: repeat(6, 1fr);
  }
}

.ps-widget--related-product {
  margin-bottom: 60px;
}

.ps-widget--related-product .ps-widget__title {
  margin-bottom: 20px;
  color: #103178;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}

.ps-widget--related-product .ps-product--standard {
  border: 1px solid #f0f2f5;
}

.ps-widget--related-product .ps-product--standard:not(:last-child) {
  border-bottom: 0;
}

.ps-widget--related-product .ps-product--standard .ps-product__actions {
  display: none;
}

.ps-widget--related-product .ps-product--standard .ps-product__thumbnail:hover {
  opacity: 0.5;
}

@media (min-width: 1280px) {
  .ps-widget--related-product .ps-widget__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-widget--promo {
  margin-bottom: 60px;
  text-align: center;
}

.ps-widget--promo img {
  width: 100%;
}

@media (min-width: 768px) {
  .ps-widget--promo {
    display: none;
  }
}

@media (min-width: 992px) {
  .ps-widget--promo {
    display: block;
  }
}

.ps-widget .ps-widget__block {
  padding: 10px 0;
  border-bottom: 1px solid #f0f2f5;
  position: relative;
}

.ps-widget .ps-widget__block .ps-block-control {
  position: absolute;
  width: 30px;
  height: 30px;
  display: inline-block;
  right: 0;
  top: 15px;
  text-align: center;
  text-indent: 0;
  z-index: 8;
  line-height: 30px;
}

.ps-widget .ps-widget__block .ps-block-control i {
  color: #103178;
  font-weight: bold;
}

.ps-widget .ps-widget__block .ps-block-control.active {
  transform: rotate(180deg);
}

.ps-widget .ps-widget__block .ps-widget__content {
  padding: 20px 0;
  display: none;
  overflow: auto;
}

.ps-widget .ps-widget__title {
  color: #103178;
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .ps-widget .ps-widget__title {
    font-size: 18px;
    line-height: 26px;
  }
  .ps-widget .ps-widget__block {
    padding: 40px 0;
    border-top: 1px solid #f0f2f5;
    border-bottom: none;
  }
  .ps-widget .ps-widget__block .ps-block-control {
    display: none;
  }
  .ps-widget .ps-widget__block .ps-widget__content {
    display: block !important;
    padding-bottom: 0;
  }
  .ps-widget .ps-widget__block:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.ps-widget--product {
  padding: 0 15px;
}

.ps-widget--product .ps-widget__block .ps-widget__content {
  max-height: 240px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.ps-widget--product .ps-widget__block .ps-widget__content::-webkit-scrollbar {
  width: 5px;
}

.ps-widget--product .ps-widget__block .ps-widget__content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0f2f5;
  border-radius: 4px;
}

.ps-widget--product .ps-widget__block .ps-widget__content::-webkit-scrollbar-thumb {
  background: #103178;
  border-radius: 4px;
}

.ps-widget--product .ps-widget__block .ps-widget__content::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.ps-widget--product .ps-widget__block .ps-widget__category {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
}

.ps-widget--product .ps-widget__promo {
  margin-top: 30px;
}

.ps-widget--product .ps-widget__promo img {
  width: 100%;
}

.ps-widget--product .ps-widget__item {
  padding: 8px 0;
}

.ps-widget--product .ps-widget__item .custom-control-label {
  display: flex;
  font-size: 14px;
  padding-left: 10px;
  color: #103178;
  font-size: 16px;
}

.ps-widget--product .ps-widget__item .custom-control-label::before,
.ps-widget--product .ps-widget__item .custom-control-label::after {
  top: 3px;
  width: 15px;
  height: 15px;
}

.ps-widget--product .ps-widget__item .custom-control-label::before {
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5;
}

.ps-widget--product .ps-widget__item .custom-control-label span {
  color: #103178;
  margin-left: 10px;
}

.ps-widget--product .ps-widget__item .custom-label {
  display: flex;
  font-size: 14px;
  padding-left: 10px;
  color: #103178;
  font-size: 16px;
}

.ps-widget--product .ps-widget__item .custom-label span {
  color: #103178;
  margin-left: 10px;
}

.ps-widget--product .br-widget a {
  font-size: 16px;
}

.ps-widget--product .ps-widget__color {
  padding-left: 3px;
}

.ps-widget--product .ps-widget__color .custom-checkbox {
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ps-widget--product .ps-widget__color .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--bg-color);
}

.ps-widget--product .ps-widget__color .custom-checkbox:hover::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  border: 1px solid #fd8d27;
  border-radius: 50%;
  z-index: -1;
}

.ps-widget--product .ps-widget__color .custom-control-label::before,
.ps-widget--product .ps-widget__color .custom-control-label::after {
  top: -17px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 350ms ease 0s;
}

.ps-widget--product .ps-widget__color .custom-control-label::before {
  background-color: var(--bg-color);
  border-color: transparent;
}

.ps-widget--product .ps-widget__price {
  padding: 20px 25px 20px 5px;
  position: relative;
}

.ps-widget--product .ps-widget__price .noUi-target.noUi-horizontal .noUi-tooltip {
  background-color: #103178;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: initial;
  bottom: auto;
}

.ps-widget--product .ps-widget__price .noUi-target.noUi-horizontal .noUi-tooltip span {
  display: none;
}

.ps-widget--product .ps-widget__price .noUi-target .noUi-active.noUi-handle {
  top: -6px;
}

.ps-widget--product .ps-widget__price .noUi-connect {
  background-color: #103178;
}

.ps-widget--product .ps-widget__input {
  display: flex;
  align-items: center;
}

.ps-widget--product .ps-widget__input .bridge {
  color: #103178;
  font-size: 25px;
  font-weight: bold;
  padding: 0 10px;
}

.ps-widget--product .ps-widget__input .ps-price {
  width: calc(50% - 15px);
  height: 34px;
  line-height: 24px;
  padding: 6px 15px;
  font-size: 16px;
  color: #5b6c8f;
  background-color: #f0f2f5;
  border-radius: 40px;
}

.ps-widget--product .ps-widget__filter {
  background-color: transparent;
  border: none;
  color: #103178;
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
  padding-top: 10px;
}

.ps-widget--product .menu--mobile > li {
  overflow: hidden;
}

.ps-widget--product .menu--mobile > li > a {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.ps-widget--product .menu--mobile .active .sub-toggle::before {
  content: "-";
}

.ps-widget--product .menu--mobile .active > a {
  color: #fd8d27;
}

.ps-widget--product .menu--mobile .sub-toggle {
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
  background-color: #f0f2f5;
}

.ps-widget--product .menu--mobile .sub-toggle::before {
  content: "+";
  color: #103178;
  font-weight: bold;
}

.ps-widget--product .menu--mobile .sub-toggle i {
  display: none;
}

.ps-widget--product .menu--mobile .sub-menu {
  margin: 10px 0 8px 20px !important;
}

.ps-widget--product .menu--mobile .sub-menu > li {
  padding: 6px 0;
}

.ps-widget--product .menu--mobile .sub-menu > li > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 1280px) {
  .ps-widget--product .ps-widget__filter {
    font-size: 18px;
  }
}

.ps-widget--blog .ps-product--standard {
  border: 1px solid #f0f2f5;
  border-bottom: none;
}

.ps-widget--blog .ps-product--standard:last-child {
  border-bottom: 1px solid #f0f2f5;
}

.ps-widget--blog .ps-product--standard .ps-product__actions {
  display: none;
}

.ps-widget--blog .ps-product--standard .ps-product__thumbnail:hover {
  opacity: 0.5;
}

.ps-widget--blog .ps-widget__product {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .ps-widget--blog .ps-widget__block {
    border-top: 0;
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .ps-widget--blog .ps-widget__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-widget--category li {
  margin-bottom: 8px;
}

.ps-widget--category a {
  font-size: 100%;
  color: #103178;
  line-height: 26px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .ps-widget--category li {
    margin-bottom: 20px;
  }
  .ps-widget--category a {
    font-size: 18px;
    line-height: 26px;
  }
}

.slick-slider .slick-slide {
  outline: none;
  box-shadow: 0 0 rgba(0, 0, 0, 0);
}

.slick-slider .slick-slide:focus,
.slick-slider .slick-slide:active,
.slick-slider .slick-slide:hover {
  outline: none;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-item img {
  width: auto;
}

.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  width: 100%;
  height: 0;
  left: 0;
}

.owl-carousel .owl-nav > * {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 56px;
  height: 56px;
  background-color: rgba(16, 49, 120, 0.1) !important;
  transition: all 0.4s ease;
  border-radius: 50%;
  transform: translateY(-50%);
}

.owl-carousel .owl-nav > * i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 23px;
  color: white;
}

.owl-carousel .owl-nav > *:hover {
  background-color: #103178 !important;
}

.owl-carousel .owl-nav .owl-prev {
  margin-left: 40px;
}

.owl-carousel .owl-nav .owl-next {
  float: right;
  margin-right: 40px;
}

.owl-carousel .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border: 1px solid #bebebe;
  background-color: transparent;
  border-radius: 50%;
}

.owl-carousel .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.owl-carousel .owl-dots .owl-dot.active {
  background-color: #103178;
  border-color: #103178;
}

.ps-banner {
  min-height: 536px;
  position: relative;
}

.ps-banner .ps-banner__content {
  padding: 40px 15px;
  text-align: center;
  height: 336px;
}

.ps-banner .ps-banner__title {
  font-weight: 600;
  font-size: 35px;
  color: #103178;
  line-height: 42px;
  margin-bottom: 10px;
}

.ps-banner .ps-banner__text {
  font-weight: 600;
  font-size: 41px;
  color: #fd8d27;
  margin-bottom: 10px;
  line-height: 39px;
}

.ps-banner .ps-banner__desc {
  font-size: 20px;
  color: #103178;
  margin-bottom: 15px;
  line-height: 29px;
}

.ps-banner .ps-banner__price {
  position: relative;
}

.ps-banner .ps-banner__price span {
  font-size: 40px;
  color: #fd8d27;
  font-weight: 600;
}

.ps-banner .ps-banner__price del {
  font-size: 20px;
  color: #5b6c8f;
  display: inline-block;
  position: relative;
  top: -12px;
  margin-left: 10px;
}

.ps-banner .ps-banner__shop {
  font-size: 19px;
  font-weight: 500;
  padding: 5px 25px;
  border-radius: 40px;
  display: inline-block;
  margin-top: 15px;
  border: 1px solid #103178;
}

.ps-banner .ps-banner__shop.bg-warning:hover {
  color: #fd8d27 !important;
  background-color: white !important;
}

.ps-banner .ps-banner__shop.bg-yellow:hover {
  color: #ffc800 !important;
  background-color: white !important;
}

.ps-banner .ps-banner__shop.bg-white:hover {
  color: white;
  border-color: #103178;
  background-color: #103178 !important;
}

.ps-banner .ps-banner__shop.bg-primary:hover {
  background-color: white !important;
  color: #103178;
}

.ps-banner .ps-banner__shop.bg-success:hover {
  background-color: white !important;
  color: #12a05c;
}

.ps-banner .ps-banner__shop.bg-pink:hover {
  background-color: white !important;
  color: #752a2b;
}

.ps-banner .ps-banner__btn-group {
  margin: auto;
}

.ps-banner .ps-banner__btn {
  font-size: 12px;
  padding-right: 30px;
  color: #103178;
}

.ps-banner .ps-banner__btn:last-child {
  padding-right: 0;
}

.ps-banner .ps-banner__btn img {
  width: 21px;
  display: inline-block;
  margin-right: 10px;
}

.ps-banner .ps-banner__btn.font-bold {
  font-weight: 500;
}

.ps-banner .ps-banner__persen {
  position: absolute;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  z-index: 10;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 25%;
  right: 15%;
  flex-direction: column;
}

.ps-banner .ps-banner__persen small {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
}

.ps-banner .ps-banner__thumnail {
  position: relative;
  height: 100%;
  width: 100%;
}

.ps-banner .ps-banner__thumnail .ps-banner__round {
  position: absolute;
  right: 5%;
  top: -90px;
  width: 90%;
}

.ps-banner .ps-banner__thumnail .ps-banner__image {
  width: 100%;
  height: 200px;
}

.ps-banner .bg-warning {
  color: white;
  background-color: #fd8d27 !important;
  border-color: #fd8d27;
}

.ps-banner .bg-yellow {
  color: #103178;
  background-color: #ffc800 !important;
  border-color: #ffc800;
}

.ps-banner .bg-white {
  color: #103178;
  background-color: white;
  border-color: white;
}

.ps-banner .bg-primary {
  background-color: #103178 !important;
  color: white;
  border-color: #103178;
}

.ps-banner .bg-success {
  background-color: #12a05c !important;
  color: white;
  border-color: #12a05c;
}

.ps-banner .bg-pink {
  background-color: #752a2b !important;
  color: white;
  border-color: #752a2b;
}

.ps-banner .text-warning {
  color: #fd8d27 !important;
}

.ps-banner .text-yellow {
  color: #ffc800 !important;
}

.ps-banner .ps-logo {
  margin-bottom: 15px;
}

.ps-banner .ps-logo img {
  height: 42px;
}

@media (min-width: 768px) {
  .ps-banner {
    height: 320px;
    min-height: 320px !important;
  }
  .ps-banner .container,
  .ps-banner .container-no-round {
    height: 100%;
  }
  .ps-banner .ps-banner__block {
    display: flex;
    height: 100%;
    position: relative;
  }
  .ps-banner .ps-banner__content {
    padding: 8% 0 0;
    width: 48%;
    height: 100%;
    flex: 0 0 48%;
    text-align: left;
  }
  .ps-banner .ps-banner__thumnail {
    flex: 0 0 62%;
  }
  .ps-banner .ps-banner__thumnail .ps-banner__image {
    position: absolute;
    bottom: 0;
    right: -5%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .ps-banner .ps-banner__thumnail .ps-banner__round {
    z-index: 10;
    -o-object-fit: cover;
    object-fit: cover;
    height: 190%;
    right: auto;
    left: -20%;
    width: auto;
    top: -200px;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .ps-banner .ps-banner__btn-group {
    margin: 0;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .ps-banner {
    height: 400px;
  }
}

@media (min-width: 1280px) {
  .ps-banner {
    height: 600px;
  }
  .ps-banner .ps-banner__title {
    font-size: 70px;
    line-height: 84px;
  }
  .ps-banner .ps-banner__desc {
    font-size: 24px;
  }
  .ps-banner .ps-banner__price span {
    font-size: 70px;
  }
  .ps-banner .ps-banner__price del {
    font-size: 30px;
    top: -25px;
  }
  .ps-banner .ps-banner__shop {
    font-size: 21px;
    padding: 8px 50px;
  }
  .ps-banner .ps-banner__btn {
    font-size: 16px;
  }
  .ps-banner .ps-banner__persen {
    width: 133px;
    height: 133px;
    font-size: 45px;
    line-height: 45px;
    right: 3%;
  }
  .ps-banner .ps-banner__persen small {
    font-size: 27px;
    line-height: 27px;
  }
  .ps-banner .ps-banner__persen.ps-center {
    right: 34%;
    top: 17%;
  }
  .ps-banner .ps-banner__persen.ps-top {
    top: 28%;
  }
  .ps-banner .ps-banner__text {
    line-height: 80px;
    font-size: 70px;
  }
  .ps-banner .ps-logo img {
    height: 54px;
  }
}

.ps-section--banner {
  min-height: 500px;
  position: relative;
}

.ps-section--banner .ps-section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  -webkit-animation: cssAnimation 0s ease-in 2s forwards;
  animation: cssAnimation 0s ease-in 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ps-section--banner .ps-section__loading {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #103178;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ps-section--banner .owl-nav {
  display: none;
}

@media (min-width: 768px) {
  .ps-section--banner {
    min-height: 320px;
  }
}

@media (min-width: 1280px) {
  .ps-section--banner {
    min-height: 500px;
  }
  .ps-section--banner .owl-nav {
    display: block;
  }
}

.ps-banner--round {
  overflow: hidden;
  background-color: #f0f2f5;
  border-radius: 4px;
}

.ps-banner--round .ps-banner__content {
  text-align: left;
}

.ps-banner--round .ps-banner__title {
  font-size: 34px;
  margin-bottom: 35px;
}

.ps-banner--round .ps-banner__btn-group {
  display: block;
}

.ps-banner--round .ps-banner__btn {
  display: block;
  margin-bottom: 15px;
  color: #5b6c8f;
  font-weight: 600;
  font-size: 16px;
}

.ps-banner--round .ps-banner__thumnail {
  margin-top: 70px;
}

.ps-banner--round .ps-banner__thumnail .ps-banner__image {
  height: 345px;
}

.ps-banner--round .ps-banner__thumnail .ps-banner__round {
  width: auto;
  height: 400%;
  -o-object-fit: cover;
  object-fit: cover;
  top: auto;
  bottom: -500px;
}

@media (min-width: 768px) {
  .ps-banner--round .ps-banner {
    height: 455px;
  }
  .ps-banner--round .ps-banner__content {
    padding: 50px 0 30px 5%;
  }
  .ps-banner--round .ps-banner__btn-group {
    display: table;
    margin-bottom: 20px;
  }
  .ps-banner--round .ps-banner__btn {
    display: table-cell;
  }
  .ps-banner--round .ps-banner__thumnail {
    margin-top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .ps-banner--round .ps-banner__thumnail .ps-banner__image {
    height: 90%;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
    position: initial;
  }
  .ps-banner--round .ps-banner__thumnail .ps-banner__round {
    height: 300%;
    left: -38%;
  }
}

@media (min-width: 1280px) {
  .ps-banner--round .ps-banner__title {
    font-size: 50px;
    line-height: 60px;
  }
}

.ps-banner--container {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  border-radius: 4px;
}

.ps-banner--container .ps-banner__fluid {
  position: initial;
}

.ps-banner--container .ps-banner__fluid .ps-banner__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-banner--container .ps-banner__content {
  z-index: 20;
  position: relative;
  height: 280px;
  padding: 30px 15px 0;
}

.ps-banner--container .ps-banner {
  min-height: 480px;
}

.ps-banner--container .ps-banner__persen {
  right: 25%;
  bottom: -35%;
}

.ps-banner--container .ps-banner__persen.ps-left {
  left: 10%;
  right: auto;
}

.ps-banner--container .owl-nav .owl-prev {
  margin-left: 0;
}

.ps-banner--container .owl-nav .owl-next {
  margin-right: 0;
}

@media (min-width: 768px) {
  .ps-banner--container {
    margin: 0;
  }
  .ps-banner--container .ps-banner__content {
    padding: 5% 0 0 4%;
    position: initial;
  }
  .ps-banner--container .ps-banner__persen {
    bottom: auto;
    top: 25%;
    right: 15%;
  }
  .ps-banner--container .ps-banner__persen.ps-left {
    left: 40%;
    top: 40%;
  }
}

@media (min-width: 1280px) {
  .ps-banner--container .ps-banner__content {
    padding: 5% 0 0 6%;
  }
  .ps-banner--container .ps-banner {
    min-height: 450px;
    height: 450px;
  }
  .ps-banner--container .ps-banner .ps-banner__title {
    font-size: 50px;
    line-height: 1.2;
  }
  .ps-banner--container .ps-banner .ps-banner__desc {
    font-size: 22px;
  }
  .ps-banner--container .ps-banner .ps-banner__btn {
    font-size: 18px;
  }
  .ps-banner--container .ps-banner .ps-banner__shop {
    font-size: 18px;
  }
  .ps-banner--container .ps-banner .ps-banner__price span {
    font-size: 50px;
    line-height: 1.2;
  }
  .ps-banner--container .ps-banner .ps-banner__price del {
    font-size: 25px;
  }
}

.ps-banner--home7 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 150px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  min-height: 620px;
}

.ps-banner--home7 .ps-banner__round {
  display: none;
  position: absolute;
  top: 0;
  left: 20%;
  width: 100%;
  height: 100%;
}

.ps-banner--home7 .ps-banner__round img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-banner--home7 .ps-banner {
  height: 100%;
}

.ps-banner--home7 .ps-banner .ps-banner__btn {
  color: #fd8d27 !important;
}

.ps-banner--home7 .ps-banner .ps-banner__btn img {
  filter: invert(60%) sepia(75%) saturate(1000%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

@media (min-width: 768px) {
  .ps-banner--home7 .ps-banner__round {
    display: block;
  }
  .ps-banner--home7 .ps-banner {
    padding-top: 120px;
  }
  .ps-banner--home7 .ps-banner .ps-banner__block {
    display: block;
  }
  .ps-banner--home7 .ps-banner .ps-banner__content {
    width: 100%;
    text-align: center;
    padding-top: 50px !important;
  }
  .ps-banner--home7 .ps-banner .ps-banner__persen {
    width: 134px;
    height: 134px;
    line-height: 134px;
    font-size: 45px;
  }
  .ps-banner--home7 .ps-banner .ps-banner__thumnail {
    height: auto;
  }
}

@media (min-width: 992px) {
  .ps-banner--home7 .ps-banner {
    padding-top: 0;
  }
  .ps-banner--home7 .ps-banner .ps-banner__block {
    display: flex;
    flex-direction: row-reverse;
  }
  .ps-banner--home7 .ps-banner .ps-banner__thumnail {
    flex: 0 0 50%;
    position: relative;
  }
  .ps-banner--home7 .ps-banner .ps-banner__content {
    text-align: left;
    width: 50%;
    flex: 0 0 50%;
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ps-banner--home7 .ps-banner .ps-banner__shop {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .ps-banner--home7 .ps-banner .ps-banner__baged {
    text-align: left;
  }
  .ps-banner--home7 .ps-banner .ps-banner__persen {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-150%, -160%);
  }
  .ps-banner--home7 .ps-banner .ps-banner__btn-group {
    margin-bottom: 20px;
  }
  .ps-banner--home7 .ps-banner .ps-banner__btn {
    display: table-cell !important;
  }
  .ps-banner--home7 .ps-banner .ps-banner__title {
    font-size: 45px;
    line-height: 50px;
  }
  .ps-banner--home7 .ps-banner .ps-banner__desc {
    font-size: 22px;
    line-height: 26px;
  }
  .ps-banner--home7 .ps-banner__round {
    top: 10%;
    left: 18%;
  }
}

@media (min-width: 1400px) {
  .ps-banner--home7 .ps-banner .ps-banner__persen {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-100%, -160%);
  }
}

.ps-banner--full {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  padding-top: 130px;
  overflow: hidden;
  position: relative;
}

.ps-banner--full .ps-banner .ps-banner__content {
  padding-top: 0;
}

.ps-banner--full .ps-banner .ps-banner__title {
  font-size: 36px;
}

.ps-banner--full .ps-banner .ps-banner__title,
.ps-banner--full .ps-banner .ps-banner__desc,
.ps-banner--full .ps-banner .ps-banner__btn {
  margin-bottom: 15px;
}

.ps-banner--full .ps-banner .ps-banner {
  min-height: 456px;
}

.ps-banner--full .ps-banner .ps-banner__desc {
  font-size: 19px;
}

.ps-banner--full .ps-banner .ps-banner__btn {
  font-size: 16px;
  font-weight: 500;
}

.ps-banner--full .ps-banner .ps-banner__shop {
  padding: 9px 23px;
  font-size: 16px;
  margin-top: 20px;
}

.ps-banner--full .ps-banner__group {
  display: flex;
}

.ps-banner--full .ps-banner__item {
  text-align: center;
  flex-grow: 1;
  padding: 0 5px;
  position: relative;
}

.ps-banner--full .ps-banner__item p {
  color: white;
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 10px;
}

.ps-banner--full .ps-banner__item img {
  width: 31px;
}

.ps-banner--full .ps-banner__item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 50%;
  width: 1px;
  background-color: #f0f2f5;
  opacity: 0.5;
}

.ps-banner--full .ps-banner__item:last-child::after {
  display: none;
}

@media (min-width: 768px) {
  .ps-banner--full {
    height: 90vh;
    min-height: 620px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ps-banner--full .ps-banner__item p {
    font-size: 15px;
  }
  .ps-banner--full .ps-banner__item img {
    width: 38px;
  }
  .ps-banner--full .ps-banner {
    height: auto;
    position: initial;
    margin-top: -100px;
  }
  .ps-banner--full .ps-banner .ps-banner__title {
    font-size: 39px;
  }
  .ps-banner--full .ps-banner .ps-banner__content {
    flex: 0 0 50%;
    width: 50%;
  }
  .ps-banner--full .ps-banner .ps-banner__block {
    height: auto;
  }
  .ps-banner--full .ps-banner__group {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .ps-banner--full {
    height: calc(100vh - 65px);
  }
  .ps-banner--full .ps-banner__item p {
    font-size: 20px;
  }
  .ps-banner--full .ps-banner__item img {
    width: 48px;
  }
  .ps-banner--full .ps-banner .ps-banner__title {
    font-size: 70px;
  }
  .ps-banner--full .ps-banner .ps-banner__desc {
    font-size: 24px;
    margin-bottom: 25px;
  }
  .ps-banner--full .ps-banner .ps-banner__btn {
    font-size: 18px;
  }
  .ps-banner--full .ps-banner .ps-banner__shop {
    padding: 10px 50px;
    font-size: 20px;
    margin-top: 30px;
  }
  .ps-banner--full .ps-banner .ps-banner {
    height: 53vh;
  }
}

.ps-banner--landing {
  overflow: hidden;
}

.ps-banner--landing .ps-banner__round {
  display: none;
  bottom: 0;
  position: absolute;
  left: 35%;
}

.ps-banner--landing .ps-banner__thumnail,
.ps-banner--landing .ps-banner__block {
  position: initial;
}

.ps-banner--landing .ps-logo {
  margin-bottom: 40px !important;
}

.ps-banner--landing .ps-banner .ps-banner__title {
  margin-bottom: 40px;
}

.ps-banner--landing .ps-banner .ps-banner__btn-group {
  margin-bottom: 30px;
}

.ps-banner--landing .ps-banner .ps-banner__btn {
  display: block;
  padding-right: 0;
  margin-bottom: 10px;
}

.ps-banner--landing .ps-banner .ps-banner__thumnail {
  overflow: hidden;
}

.ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
}

.ps-banner--landing .ps-banner .ps-banner__shop {
  margin-right: 10px;
  font-size: 14px;
}

.ps-banner--landing .ps-banner .ps-banner__shop:last-child {
  margin-right: 0;
}

.ps-banner--landing .ps-banner .ps-banner__content {
  height: auto;
  padding-bottom: 0;
}

.ps-banner--landing .ps-banner__arrow {
  padding-top: 50px;
}

.ps-banner--landing .ps-banner__arrow img {
  height: 55px;
}

@media (min-width: 768px) {
  .ps-banner--landing .ps-banner__round {
    display: block;
  }
  .ps-banner--landing .ps-banner {
    height: auto;
  }
  .ps-banner--landing .ps-banner .ps-banner__btn {
    display: table-cell;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .ps-banner--landing .ps-banner .ps-banner__thumnail {
    flex: 0 0 40%;
    width: 40%;
    overflow: initial;
  }
  .ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: auto;
    top: 0;
    right: -50%;
  }
  .ps-banner--landing .ps-banner .ps-banner__shop {
    font-size: 16px;
  }
  .ps-banner--landing .ps-banner .ps-banner__content {
    padding: 50px 0 100px;
    flex: 0 0 60%;
    width: 60%;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .ps-banner--landing .ps-banner .ps-banner__content,
  .ps-banner--landing .ps-banner .ps-banner__thumnail {
    flex: 0 0 50%;
    width: 50%;
  }
  .ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
    right: -15%;
  }
}

@media (min-width: 1280px) {
  .ps-banner--landing .ps-banner .ps-banner__btn {
    padding-right: 30px;
  }
  .ps-banner--landing .ps-banner .ps-banner__content,
  .ps-banner--landing .ps-banner .ps-banner__thumnail {
    flex: 0 0 50%;
    width: 50%;
  }
  .ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
    right: -15%;
    top: -3%;
  }
  .ps-banner--landing .ps-banner .ps-banner__title {
    font-size: 60px;
    line-height: 74px;
  }
  .ps-banner--landing .ps-banner .ps-banner__shop {
    font-size: 21px;
  }
}

@media (min-width: 1336px) {
  .ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
    right: -7%;
    top: -3%;
  }
}

@media (min-width: 1680px) {
  .ps-banner--landing .ps-banner .ps-banner__thumnail .ps-banner__image {
    height: 120%;
    top: -10%;
    right: -6%;
  }
}

@media (min-width: 1200px) {
  .table-responsive {
    overflow-x: initial;
  }
}

.ps-table {
  border-radius: 4px;
  border-collapse: collapse;
  border-spacing: 0;
}

.ps-table thead tr th {
  padding: var(--space-md);
  border: none;
  background-color: #51607f;
}

.ps-table tbody tr td {
  border: none;
  border-right: 1px solid rgba(16, 49, 120, 0.1);
  border-bottom: 1px solid rgba(16, 49, 120, 0.1);
  margin: 0 auto;
}

.ps-table--oriented {
  margin-bottom: 50px;
  border-radius: 4px;
  overflow: hidden;
}

.ps-table--oriented tr:first-child th:first-child {
  border-top-left-radius: 4px;
  border-top-color: transparent;
}

.ps-table--oriented tr:first-child td:last-child {
  border-top-right-radius: 4px;
  border-top: 1px solid rgba(16, 49, 120, 0.1);
}

.ps-table--oriented tr:last-child th:first-child {
  border-bottom-left-radius: 4px;
}

.ps-table--oriented tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.ps-table--oriented tr th {
  background-color: #f0f2f5;
  color: #103178;
  padding: 11px 20px;
  width: 30%;
  font-size: 14px;
}

.ps-table--oriented tr td {
  color: #103178;
  font-weight: bold;
  padding: 11px 20px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .ps-table--responsive {
    border: none;
  }
  .ps-table--responsive thead {
    display: none;
  }
  .ps-table--responsive tbody {
    display: block;
  }
  .ps-table--responsive tbody tr {
    display: block;
    margin-bottom: var(--space-md);
    border: 1px solid #f0f2f5;
  }
  .ps-table--responsive tbody tr td[data-label] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-top: 1px solid #f0f2f5;
  }
  .ps-table--responsive tbody tr td[data-label]:before {
    content: attr(data-label) ":";
    font-weight: 600;
  }
  .ps-table--responsive tbody tr td[data-label]:first-child {
    border-top: none;
  }
}

.ps-table--product {
  border: none;
}

.ps-table--product thead tr th {
  background-color: white;
  font-weight: bold;
  font-size: 16px;
  color: #103178;
}

.ps-table--product tbody td {
  padding: 25px 20px;
  vertical-align: middle;
  border: none !important;
  border-top: 1px solid #f0f2f5 !important;
}

.ps-table--product tbody td:first-child {
  border-left: 1px solid #f0f2f5 !important;
}

.ps-table--product tbody td:last-child {
  border-right: 1px solid #f0f2f5 !important;
}

.ps-table--product tbody tr:last-child {
  border-bottom: 1px solid #f0f2f5;
}

.ps-table--product .ps-product__thumbnail {
  max-width: 90px;
}

.ps-table--product .ps-product__image {
  width: 90px;
  display: block;
  margin: auto;
}

.ps-table--product .ps-product__image img {
  width: 100%;
}

.ps-table--product .ps-btn {
  text-transform: initial;
  background-color: white;
  color: #103178;
  border: 1px solid #f0f2f5;
  width: auto;
  font-size: 14px;
  padding: 3px 10px;
  min-width: 80px;
}

.ps-table--product .ps-btn:hover {
  background-color: #103178;
  border-color: #103178;
  color: white;
}

.ps-table--product .ps-product__status span {
  color: #12a05c;
  font-size: 14px;
}

.ps-table--product .ps-product__status span::before {
  content: "\f00c";
  color: #12a05c;
  font-size: 75%;
  display: inline-block;
  font-weight: 900;
  font: normal normal normal 14px/1 FontAwesome;
  margin: -3px 10px 0 0;
}

.ps-table--product .ps-product__price {
  font-weight: 600;
}

.ps-table--product .ps-product__name {
  font-size: 16px;
  color: #103178;
}

.ps-table--product .ps-product__remove {
  padding-left: 10px;
  padding-right: 10px;
}

.ps-table--product .ps-product__remove i {
  font-weight: 600;
  color: #103178;
}

.ps-table--product .ps-product__remove a {
  width: 25px;
  font-size: 14px;
}

.ps-table--product tbody .ps-product__subtotal {
  padding: 25px;
}

.ps-table--product .ps-product__subtotal {
  color: #103178;
  font-weight: 600;
  font-size: 18px;
}

.ps-table--product .ps-product__quantity {
  text-align: center;
  width: 160px;
}

.ps-table--product .ps-product__quantity span {
  color: #103178;
  font-size: 18px;
}

.ps-table--product .number-input {
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  border: 1px solid #f0f2f5;
  border-radius: 2px;
  border-radius: 40px;
}

.ps-table--product .number-input input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.ps-table--product .number-input input[type="number"] {
  text-align: center;
  max-width: 30px;
  font-size: 14px;
  color: #103178;
  flex-grow: 1;
  background-color: transparent;
}

.ps-table--product .number-input input[type="number"]:focus {
  box-shadow: none;
}

.ps-table--product .number-input input[type="number"]::-webkit-inner-spin-button,
.ps-table--product .number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ps-table--product .number-input button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 16px;
  cursor: pointer;
}

.ps-table--product .number-input button i {
  color: #5b6c8f;
  font-weight: 600;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ps-table--product .number-input.def-number-input.safari_only button:before,
    .ps-table--product .number-input.def-number-input.safari_only button:after {
      margin-top: -0.3rem;
    }
  }
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #ffffff;
  z-index: 999;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

.loaded #loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  /* transition: all 0.3s 1s ease-out; */
}

.loaded #loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  /* transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
}

.loaded #loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  /* transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
}

.ps-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(34, 34, 34, 0.7);
  transition: all 0.4s ease;
}

.ps-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
  z-index: 10;
  border-radius: 3px;
  max-width: 945px;
  width: 100%;
  background-color: #fff;
}

.ps-modal__close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5e6b9d;
  transform: translate(50%, -50%);
}

.ps-modal__close i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffffff;
}

.ps-modal.active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .ps-modal__container {
    max-width: 90%;
  }
  .ps-modal__close {
    transform: translate(0, 0);
    width: 30px;
    height: 30px;
    background-color: transparent;
  }
  .ps-modal__close i {
    color: #5e6b9d;
  }
}

@media (max-width: 767px) {
  .ps-modal {
    overflow: auto;
  }
  .ps-modal__container {
    position: relative;
    top: 0;
    left: 0;
    margin: 3rem auto;
    overflow: auto;
    transform: translate(0, 0);
  }
}

@media (max-width: 479px) {
  .ps-modal__container {
    max-width: 300px;
  }
}

.ps-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  transition: all 0.4s ease;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
}

.ps-popup__content {
  position: relative;
  max-width: 830px;
  width: 100%;
  padding: 67px 40px;
  transition: transform 0.4s ease;
}

.ps-popup__close {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 0;
}

.ps-popup__close:before,
.ps-popup__close:after {
  background-color: #000;
}

.ps-popup__close:hover:before,
.ps-popup__close:hover:after {
  background-color: #f00000;
}

.ps-popup.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
}

@media (max-width: 991px) {
  .ps-popup {
    padding: 100px 30px 20px;
  }
  .ps-popup .ps-popup__content {
    position: relative;
    top: 0;
    left: 0;
    max-width: 800px;
    margin: 0 auto;
  }
  .ps-popup.active .ps-popup__content {
    transform: translate(0, 0);
  }
}

@media (max-width: 479px) {
  .ps-popup {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .ps-popup .ps-popup__content {
    padding: 50px 20px;
  }
}

.ps-dropdown-single {
  width: 80px !important;
  padding: 10px 0;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  background-color: white;
}

.ps-dropdown-single .select2-results__option {
  font-size: 14px !important;
  color: #5b6c8f !important;
}

.ps-dropdown-single .select2-results__option[aria-selected="true"] {
  background-color: transparent;
  color: #fd8d27;
}

.ps-dropdown-single .select2-results__option--highlighted {
  background-color: transparent !important;
  color: #fd8d27 !important;
}

@media (min-width: 1024px) {
  .ps-dropdown-single {
    width: 180px !important;
    padding: 30px 30px;
  }
}

.ps-dropdown-input {
  background-color: #f0f2f5;
  margin-top: 10px;
  border: none;
  padding-top: 15px;
  border-radius: 4px;
}

.ps-dropdown-input input {
  height: 34px;
  border-radius: 40px;
  background-color: #f0f2f5;
  color: #5b6c8f;
  margin-bottom: 10px;
  padding: 4px 10px !important;
  font-size: 14px;
}

.ps-dropdown-input input:focus {
  box-shadow: none;
}

.ps-dropdown-input .select2-results__option[aria-selected="true"] {
  background-color: #103178;
  color: white;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.ps-quickview .modal-body {
  padding: 30px 40px 30px 30px;
  position: relative;
  border-radius: 4px;
}

.ps-quickview__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  font-size: 30px;
  line-height: 36px;
}

.ps-quickview .ps-product__quantity .number-input input[type="number"] {
  max-width: 50px;
}

.ps-quickview .ps-product__quantity .ps-btn {
  margin-left: 10px;
}

@media (min-width: 1200px) {
  .ps-quickview .ps-product--gallery {
    margin-bottom: 0;
  }
}

.ps-compare--popup .modal-body {
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

.ps-compare--popup .ps-compare__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  font-size: 30px;
  line-height: 36px;
  color: white;
  z-index: 10;
  opacity: 1;
}

.ps-compare--popup .ps-compare__close:hover {
  color: white !important;
}

.ps-popup--select .modal-body {
  padding: 30px 40px 30px 30px;
  position: relative;
}

.ps-popup--select .ps-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  font-size: 30px;
  line-height: 36px;
}

.ps-popup--select .ps-popup__title {
  color: #103178;
  text-align: center;
  font-size: 40px;
  margin-bottom: 25px;
}

.ps-popup--select .ps-popup__list {
  text-align: center;
}

.ps-popup--select .ps-popup__list li {
  display: inline-block;
  padding: 5px;
}

.ps-popup--select .ps-popup__list a {
  padding: 5px 18px;
  font-weight: 600;
  line-height: 19px;
  border: 1px solid #f0f2f5;
  border-radius: 40px;
  font-size: 13px;
  color: #103178;
}

.ps-popup--select .ps-popup__list a.active {
  border-color: #103178;
}

.ps-addcart .modal-body {
  padding: 30px 40px 30px 30px;
  position: relative;
  border-radius: 4px;
}

.ps-addcart__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  font-size: 30px;
  line-height: 36px;
}

.ps-addcart .ps-product--standard {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
}

.ps-addcart .ps-addcart__noti {
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto 30px;
  border-radius: 40px;
  color: white;
  background-color: #12a05c;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 25px;
  line-height: 16px;
}

.ps-addcart .ps-addcart__noti i {
  margin-right: 10px;
}

.ps-addcart .ps-addcart__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.ps-addcart .ps-addcart__content p {
  color: #103178;
}

.ps-addcart .ps-addcart__content .ps-addcart__total {
  font-weight: 500;
  margin-bottom: 30px;
}

.ps-addcart .ps-addcart__content .ps-btn {
  margin-bottom: 10px;
}

.ps-addcart .ps-addcart__header {
  border-top: 1px solid #f0f2f5;
  padding: 30px 0;
}

.ps-addcart .ps-addcart__header p,
.ps-addcart .ps-addcart__header h3 {
  color: #103178;
  text-align: center;
}

.ps-addcart .ps-addcart__header p {
  font-size: 16px;
}

.ps-addcart .ps-addcart__header h3 {
  font-size: 20px;
}

.ps-addcart .ps-addcart__footer {
  text-align: right;
  padding-top: 30px;
}

.ps-addcart .ps-addcart__footer .ps-btn {
  margin-bottom: 15px;
}

.ps-addcart .owl-nav {
  display: none;
}

.ps-addcart .owl-dots {
  position: initial;
  padding-top: 15px;
}

.ps-addcart .ps-addcart__product .ps-product--standard {
  border: none;
}

.ps-addcart .ps-addcart__product .ps-product__quantity {
  display: inline-block;
  margin-bottom: 10px;
  color: #103178;
  min-width: auto;
  margin-right: 10px;
}

.ps-addcart .ps-addcart__product .ps-product__meta {
  display: inline-block;
}

.ps-addcart .ps-addcart__product .ps-product__image img {
  max-width: 60px;
}

.ps-addcart .ps-addcart__body {
  position: relative;
  min-height: 350px;
}

.ps-addcart .ps-addcart__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  -webkit-animation: cssAnimation 0s ease-in 2s forwards;
  animation: cssAnimation 0s ease-in 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ps-addcart .ps-addcart__loading {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #103178;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .ps-addcart .ps-addcart__product .ps-product--standard {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .ps-addcart .ps-addcart__product .ps-product__thumbnail {
    padding: 0;
    margin: 0;
  }
  .ps-addcart .ps-addcart__product .ps-product__content {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .ps-addcart .ps-addcart__product .ps-product__title {
    margin-bottom: 0;
    flex-grow: 1;
  }
  .ps-addcart .ps-addcart__product .ps-product__content {
    flex-grow: 1;
    display: flex;
  }
  .ps-addcart .ps-addcart__product .ps-product__quantity {
    margin: 0;
    flex: 0 0 50px;
    text-align: right;
  }
  .ps-addcart .ps-addcart__product .ps-product__meta {
    margin: 0;
    text-align: right;
    flex: 0 0 120px;
  }
  .ps-addcart .ps-addcart__header h3 {
    font-size: 25px;
  }
  .ps-addcart .ps-addcart__footer .ps-btn {
    width: auto;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .ps-addcart .ps-addcart__footer .ps-btn:last-child {
    margin-right: 0;
  }
}

.ps-tab {
  display: none;
}

.ps-tab.active {
  display: block;
}

.ps-promo__item {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.ps-promo__item:hover .ps-promo__banner {
  transform: scale(1.1, 1.1);
}

.ps-promo__title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-promo__text {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translate(0, -50%);
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  z-index: 10;
}

.ps-promo__banner {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 800ms ease 0s;
  border-radius: 4px;
}

.ps-promo__content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  padding: 25px 30px;
}

.ps-promo .ps-promo__badge {
  padding: 1px 10px;
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  color: white;
  background-color: #12a05c;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 40px;
  margin-bottom: 5px;
}

.ps-promo .ps-promo__name {
  color: #103178;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}

.ps-promo .ps-promo__name.mb-20 {
  margin-bottom: 20px;
}

.ps-promo .ps-promo__btn {
  display: inline-block;
  background-color: #103178;
  color: white;
  padding: 0px 25px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 40px;
  border: 1px solid #103178;
  line-height: 26px;
}

.ps-promo .ps-promo__btn:hover {
  background-color: #fd8d27;
  border-color: #fd8d27;
}

.ps-promo .ps-promo__sale {
  color: #fd8d27;
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.2;
}

.ps-promo .ps-promo__price {
  color: #ffc800;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

.ps-promo .ps-promo__del {
  font-size: 18px;
  color: #9babcd;
  line-height: 30px;
  font-weight: 500;
  text-decoration: line-through;
}

.ps-promo .ps-promo__meta {
  margin-bottom: 15px;
}

.ps-promo .ps-promo__meta.d-horizontal {
  display: flex;
}

.ps-promo .ps-promo__icon {
  margin-bottom: 25px;
}

.ps-promo .ps-promo__icon img {
  width: 24px;
  margin-right: 10px;
}

.ps-promo .ps-promo__icon span {
  color: #fd8d27;
  font-size: 14px;
  font-weight: 600;
}

.ps-promo .ps-promo__image {
  margin-bottom: 25px;
}

.ps-promo .ps-promo__image img {
  max-height: 40px;
}

.ps-promo .col-12 {
  padding-bottom: 10px !important;
}

.ps-promo.ps-not-padding .col-12 {
  padding-bottom: 0 !important;
}

.ps-promo .text-primary {
  color: #103178 !important;
}

.ps-promo .text-yellow {
  color: #ffc800;
}

.ps-promo .text-warning {
  color: #fd8d27 !important;
}

.ps-promo .btn-warning {
  background-color: #fd8d27 !important;
  border-color: #fd8d27 !important;
}

.ps-promo .btn-warning:hover {
  background-color: #103178 !important;
  border-color: #103178 !important;
}

.ps-promo .btn-yellow {
  background-color: #ffc800 !important;
  border-color: #ffc800 !important;
}

.ps-promo .btn-yellow:hover {
  background-color: #103178 !important;
  border-color: #103178 !important;
  color: #ffc800 !important;
}

.ps-promo .btn-green {
  background-color: #00605a !important;
  border-color: #00605a !important;
}

.ps-promo .btn-green:hover {
  background-color: #103178 !important;
  border-color: #103178 !important;
}

.ps-promo .btn-white {
  background-color: white !important;
  border-color: white !important;
  color: #333;
}

.ps-promo .btn-white:hover {
  background-color: #103178 !important;
  border-color: #103178 !important;
  color: white;
}

@media (min-width: 768px) {
  .ps-promo .col-12 {
    padding-bottom: 0 !important;
  }
  .ps-promo__content {
    padding: 40px;
  }
  .ps-promo .ps-promo__name {
    font-size: 15px;
  }
  .ps-promo .ps-promo__badge {
    font-size: 12px;
  }
  .ps-promo .ps-promo__price,
  .ps-promo .ps-promo__sale {
    font-size: 25px;
  }
  .ps-promo .ps-promo__del {
    font-size: 21px;
  }
  .ps-promo .ps-promo__icon span {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ps-promo .ps-promo__icon span {
    font-size: 18px;
  }
  .ps-promo .ps-promo__name {
    font-size: 25px;
  }
  .ps-promo .ps-promo__price,
  .ps-promo .ps-promo__sale {
    font-size: 45px;
  }
  .ps-promo .ps-promo__btn {
    font-size: 16px;
    padding: 3px 30px;
  }
}

@media (min-width: 1024px) {
  .ps-promo__title {
    font-size: 40px;
    line-height: 50px;
  }
}

.ps-promo--mask {
  overflow: hidden;
}

.ps-promo--mask .ps-banner {
  background-color: #cccbc9;
  height: auto;
  min-height: 280px !important;
}

.ps-promo--mask .ps-banner .ps-banner__title {
  color: #222;
}

.ps-promo--mask .ps-banner .ps-banner__btn {
  display: block;
  padding: 0 0 10px 0;
  font-size: 16px;
  color: #222;
}

.ps-promo--mask .ps-banner .ps-banner__thumnail .ps-banner__image {
  height: auto;
  max-height: 300px;
}

.ps-promo--mask .ps-banner .ps-banner__content {
  height: auto;
}

.ps-promo--mask .ps-banner .ps-banner__desc {
  color: #222;
  font-size: 19px;
}

.ps-promo--mask .ps-banner.bg-white .ps-banner__content {
  padding-top: 0;
}

@media (min-width: 768px) {
  .ps-promo--mask .ps-banner .ps-banner__btn {
    display: table-cell;
    padding-right: 30px;
  }
  .ps-promo--mask .ps-banner .ps-banner__content {
    padding: 40px 0 40px 40px;
    width: 50%;
    flex: 0 0 50%;
  }
  .ps-promo--mask .ps-banner .ps-banner__thumnail {
    height: auto;
    width: 50%;
    flex: 0 0 50%;
  }
  .ps-promo--mask .ps-banner .ps-banner__thumnail .ps-banner__image {
    height: 80%;
    max-height: 100%;
    width: auto;
  }
  .ps-promo--mask .ps-banner .row-reverse {
    flex-direction: row-reverse;
  }
  .ps-promo--mask .ps-banner.bg-white .ps-banner__content {
    padding: 40px 40px 40px 0;
  }
  .ps-promo--mask .ps-banner.bg-white .ps-banner__thumnail .ps-banner__image {
    height: 100%;
    max-width: 110%;
  }
}

@media (min-width: 1280px) {
  .ps-promo--mask .ps-banner .ps-banner__content {
    padding: 100px 0 100px 70px;
  }
  .ps-promo--mask .ps-banner .ps-banner__title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
  .ps-promo--mask .ps-banner .ps-banner__shop {
    font-size: 18px;
    margin-top: 15px;
  }
  .ps-promo--mask .ps-banner .ps-banner__thumnail .ps-banner__image {
    height: 95%;
  }
  .ps-promo--mask .ps-banner .ps-banner__desc {
    font-size: 21px;
  }
  .ps-promo--mask .ps-banner.bg-white .ps-banner__content {
    padding: 60px 70px 60px 0;
  }
  .ps-promo--mask .ps-banner.bg-white .ps-banner__thumnail .ps-banner__image {
    max-width: initial;
  }
}

.ps-promo--desc {
  background-color: #f4f5f7;
  background-image: linear-gradient(to left, #f4f5f7, #f3f7f8 80%);
}

.ps-promo--desc .ps-banner {
  height: auto;
}

.ps-promo--desc .ps-banner .ps-banner__content {
  height: auto;
}

.ps-promo--desc .ps-banner .ps-banner__title {
  margin-bottom: 25px;
}

.ps-promo--desc .ps-banner .ps-banner__desc {
  font-size: 16px;
  margin-bottom: 25px;
}

.ps-promo--desc .ps-banner .ps-banner__btn {
  font-size: 16px;
  margin-bottom: 5px;
  padding-right: 0;
}

.ps-promo--desc .ps-banner .ps-banner__thumnail .ps-banner__image {
  width: calc(100% + 30px);
  margin-left: -15px;
  max-width: none;
}

@media (min-width: 768px) {
  .ps-promo--desc .ps-banner .ps-banner__block {
    position: initial;
  }
  .ps-promo--desc .ps-banner .ps-banner__block.row-reverse {
    flex-direction: row-reverse;
  }
  .ps-promo--desc .ps-banner .ps-banner__thumnail {
    position: initial;
  }
  .ps-promo--desc .ps-banner .ps-banner__thumnail .ps-banner__image {
    width: auto;
    margin-left: 0;
    height: 100%;
    top: 0;
    bottom: auto;
    left: 0;
    z-index: 0;
  }
  .ps-promo--desc .ps-banner .ps-banner__content {
    position: relative;
    z-index: 10;
    flex: 0 0 60%;
    width: 60%;
    padding: 80px 0;
  }
  .ps-promo--desc .ps-banner .ps-banner__btn-group {
    display: block;
  }
  .ps-promo--desc .ps-banner .ps-banner__btn {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .ps-promo--desc .ps-banner .ps-banner__content {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .ps-promo--desc .ps-banner .ps-banner__desc,
  .ps-promo--desc .ps-banner .ps-banner__btn {
    font-size: 18px;
  }
  .ps-promo--desc .ps-banner .ps-banner__title {
    font-size: 50px;
    line-height: 64px;
  }
}

.ps-promo--section .ps-promo__title {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: #103178;
  font-weight: 600;
}

.ps-promo--section .ps-promo__subtitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 60px;
  color: #5b6c8f;
  text-align: center;
  font-weight: 500;
}

.ps-promo--section .ps-promo__footer {
  text-align: center;
}

.ps-promo--section .ps-promo__footer .ps-btn {
  width: auto;
}

@media (min-width: 768px) {
  .ps-promo--section .ps-promo__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-promo--section .ps-promo__subtitle {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .ps-promo--section .ps-promo__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-promo--section .ps-promo__subtitle {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-blog--latset {
  position: relative;
}

.ps-blog--latset .ps-blog__badge {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 100%;
  z-index: 10;
}

.ps-blog--latset .ps-blog__badge .ps-badge__item {
  background-color: #103178;
  color: white;
  font-weight: 500;
  border-radius: 40px;
  margin: 0 5px 5px;
  font-size: 12px !important;
  line-height: 20px !important;
  padding: 0 10px;
  display: inline-block;
  text-transform: uppercase;
}

.ps-blog--latset .ps-blog__thumbnail {
  overflow: hidden;
  position: relative;
}

.ps-blog--latset .ps-blog__thumbnail a {
  display: block;
}

.ps-blog--latset .ps-blog__thumbnail img {
  border-radius: 4px;
  width: 100%;
  transition: 500ms ease;
}

.ps-blog--latset .ps-blog__thumbnail:hover img {
  transform: scale(1.1, 1.1);
}

.ps-blog--latset .ps-blog__content {
  padding: 20px 0;
  color: #5b6c8f;
}

.ps-blog--latset .ps-blog__meta {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.ps-blog--latset .ps-blog__date {
  font-weight: 600;
  margin-right: 10px;
}

.ps-blog--latset .ps-blog__author {
  color: #5b6c8f;
}

.ps-blog--latset .ps-blog__author:hover {
  color: #fd8d27;
}

.ps-blog--latset .ps-blog__title {
  display: block;
  font-size: 21px;
  line-height: 24px;
  color: #103178;
  font-weight: 500;
}

.ps-blog--latset .ps-blog__title:hover {
  color: #fd8d27;
}

.ps-blog--latset .ps-blog__desc {
  display: none;
  margin-bottom: 0;
  font-size: 16px;
  color: #5b6c8f;
}

/*3. COMPONENTS */
.ps-cart--mini {
  background-color: white;
  width: 420px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  border-radius: 3px;
  padding: 35px 30px 30px 30px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  transition: all 0.2s ease;
  opacity: 0;
  transform-origin: right top 0;
  transform: scale(0);
}

.ps-cart--mini.active {
  opacity: 1;
  transform-origin: right top 0;
  transform: scale(1);
}

.ps-cart--mini .ps-cart__items {
  overflow-y: auto;
  max-height: 326px;
  padding: 0;
  margin-bottom: 20px;
}

.ps-cart--mini .ps-cart__items .ps-cart__item {
  display: block;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f2f5;
  padding-bottom: 15px;
}

.ps-cart--mini .ps-cart__items .ps-cart__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.ps-cart--mini .ps-cart__total {
  color: #103178;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 20px;
}

.ps-cart--mini .ps-cart__total span {
  font-weight: bold;
}

.ps-cart--mini .ps-cart__footer .ps-btn {
  display: block;
  width: 100%;
  height: auto;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 10px;
}

.ps-shopping .ps-shopping__title {
  color: #103178;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 40px;
}

.ps-shopping .ps-shopping__title sup {
  font-size: 42%;
  line-height: 20px;
  margin-left: 10px;
  color: #5b6c8f;
}

.ps-shopping .ps-shopping__list li {
  margin-bottom: 10px;
}

.ps-shopping .ps-shopping__table {
  display: none;
}

.ps-shopping .ps-product--wishlist .ps-product__quantity,
.ps-shopping .ps-product--wishlist .ps-product__subtotal {
  display: flex;
}

.ps-shopping .ps-product--wishlist .ps-product__cart,
.ps-shopping .ps-product--wishlist .ps-product__stock {
  display: none;
}

.ps-shopping .ps-shopping__footer {
  padding-top: 50px;
  margin-bottom: 50px;
}

.ps-shopping .ps-shopping__footer .ps-btn {
  display: inline-block;
  width: auto;
  text-transform: initial;
  height: 44px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
}

.ps-shopping .ps-input {
  display: inline-block;
  padding: 5px 15px;
  max-width: 200px;
  border-radius: 40px;
  font-size: 16px;
  height: 44px;
  background-color: #f0f2f5;
  color: #5b6c8f;
  border-color: #f0f2f5;
}

.ps-shopping .ps-input::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-shopping .ps-input:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-shopping .ps-input::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-shopping .ps-shopping__coupon {
  display: flex;
  margin-bottom: 15px;
}

.ps-shopping .ps-shopping__coupon .ps-btn {
  margin-left: 10px;
}

.ps-shopping .ps-shopping__button .ps-btn:first-child {
  margin-right: 10px;
}

.ps-shopping .ps-shopping__label {
  line-height: 20px;
  font-size: 14px;
  color: #103178;
  font-weight: 600;
  padding: 10px 0;
}

.ps-shopping .ps-shopping__box {
  color: #103178;
  border: 1px solid #f0f2f5;
  border-radius: 4px;
  padding: 5px 20px 30px;
  margin-bottom: 50px;
}

.ps-shopping .ps-shopping__row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f0f2f5;
}

.ps-shopping .ps-shopping__row .ps-shopping__label {
  padding: 0;
}

.ps-shopping .ps-shopping__price {
  color: #103178;
  font-weight: 500;
  font-size: 16px;
}

.ps-shopping .ps-shopping__text {
  font-size: 14px;
  padding-left: 20px;
}

.ps-shopping .ps-shopping__checkout {
  padding-top: 20px;
  text-align: center;
}

.ps-shopping .ps-shopping__checkout .ps-btn {
  margin-bottom: 15px;
  text-transform: initial;
  height: 44px;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 500;
}

.ps-shopping .ps-shopping__toggle {
  text-decoration: underline;
  font-size: 14px;
  color: #103178;
  display: block;
  border-bottom: 1px solid #f0f2f5;
  padding-bottom: 10px;
  padding-left: 20px;
}

.ps-shopping .ps-shopping__link {
  text-decoration: underline;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}

.ps-shopping .ps-shopping__checkbox {
  margin-bottom: 10px;
}

.ps-shopping .ps-shopping__checkbox label {
  margin-top: 3px;
  font-size: 14px;
  color: #103178;
}

.ps-shopping .ps-shopping__checkbox label::before {
  margin-right: 5px;
  content: "";
}

.ps-shopping .ps-shopping__group {
  margin-bottom: 15px;
}

.ps-shopping .ps-shopping__form {
  padding-top: 10px;
  display: none;
}

.ps-shopping .ps-shopping__form .ps-input {
  font-size: 14px;
}

.ps-shopping .ps-shopping__form .ps-input::-moz-placeholder {
  font-size: 14px;
}

.ps-shopping .ps-shopping__form .ps-input:-ms-input-placeholder {
  font-size: 14px;
}

.ps-shopping .ps-shopping__form .ps-input::placeholder {
  font-size: 14px;
}

.ps-shopping .ps-shopping__form .select2-selection--single {
  border: none;
}

.ps-shopping .ps-shopping__form .select2-selection__rendered {
  border-radius: 40px;
  height: 44px;
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  color: #5b6c8f;
  font-size: 14px;
  padding: 10px 25px;
}

.ps-shopping .ps-section--latest {
  margin-bottom: 80px;
}

.ps-shopping .ps-section--latest .ps-section__title {
  font-size: 26px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .ps-shopping .ps-shopping__title {
    font-size: 45px;
    line-height: 50px;
  }
  .ps-shopping .ps-shopping__footer,
  .ps-shopping .ps-shopping__box {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .ps-shopping .ps-shopping__list {
    display: none;
  }
  .ps-shopping .ps-shopping__table {
    display: block;
  }
  .ps-shopping .ps-shopping__footer {
    display: flex;
  }
  .ps-shopping .ps-shopping__coupon {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .ps-shopping .ps-shopping__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-shopping .ps-section--latest .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-shopping .ps-shopping__footer .ps-btn {
    padding: 10px 25px;
    font-size: 18px;
  }
  .ps-shopping .ps-input {
    padding: 10px 25px;
    max-width: 270px;
    font-size: 18px;
  }
  .ps-shopping .ps-input::-moz-placeholder {
    font-size: 16px;
  }
  .ps-shopping .ps-input:-ms-input-placeholder {
    font-size: 16px;
  }
  .ps-shopping .ps-input::placeholder {
    font-size: 16px;
  }
}

.ps-search-table {
  background-color: #f0f2f5;
  border-radius: 30px;
  overflow: hidden;
}

.ps-search-table .form-control {
  border-color: #f0f2f5;
  color: #5b6c8f;
  height: 46px;
  font-size: 18px;
}

.ps-search-table .form-control:focus {
  box-shadow: none;
  background-color: #f0f2f5;
  color: #5b6c8f;
}

.ps-search-table .form-control::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-search-table .form-control:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-search-table .form-control::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-search-table .input-group-append {
  width: 60px;
  align-items: center;
  justify-content: center;
  color: #103178;
}

.ps-search-table .input-group-append a i {
  color: #103178;
  font-weight: 900;
  font-size: 18px;
}

.ps-login--modal {
  background-color: white;
  width: 300px;
  position: absolute;
  top: 100%;
  right: -92px;
  z-index: 10;
  border-radius: 3px;
  padding: 35px 30px 30px 30px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  transition: all 0.2s ease;
  opacity: 0;
  transform-origin: right top 0;
  transform: scale(0);
}

.ps-login--modal.active {
  opacity: 1;
  transform-origin: right top 0;
  transform: scale(1);
}

.ps-login--modal .form-control {
  background-color: #f0f2f5;
  border-radius: 50px;
  color: #5b6c8f;
  font-size: 18px;
  padding: 10px 25px;
  line-height: 26px;
  height: 46px;
}

.ps-login--modal .form-group {
  margin-bottom: 20px;
}

.ps-login--modal label {
  font-size: 16px;
  color: #5b6c8f;
  margin-bottom: 5px;
}

.ps-login--modal .form-check label {
  margin-top: 8px;
  margin-left: 10px;
}

.ps-form--subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps-form--subscribe .ps-form__control {
  width: 100%;
}

.ps-form--subscribe .ps-input {
  border-radius: 40px;
  width: 100%;
  height: 44px;
  background-color: #f0f2f5;
  color: #5b6c8f;
  font-size: 16px;
  margin-bottom: 10px;
}

.ps-form--subscribe .ps-input:focus {
  box-shadow: none;
  background-color: #f0f2f5;
  color: #5b6c8f;
}

.ps-form--subscribe .ps-input::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--subscribe .ps-input:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--subscribe .ps-input::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--subscribe .ps-btn {
  height: 44px;
  text-transform: capitalize;
  font-size: 16px;
  padding: 9px 15px;
}

@media (min-width: 768px) {
  .ps-form--subscribe .ps-form__control {
    display: inline-flex;
    align-items: center;
    max-width: 500px;
  }
  .ps-form--subscribe .ps-input {
    font-size: 18px;
    width: calc(100% - 160px);
    padding: 11px 25px 11px 25px;
    margin-bottom: 0;
    height: 48px;
  }
  .ps-form--subscribe .ps-btn {
    font-size: 18px;
    width: 150px;
    margin-left: 10px;
    height: 48px;
  }
}

.ps-form--newsletter .ps-input {
  padding: 9px 15px;
  height: 44px;
  color: #5b6c8f;
  background-color: white;
  border-radius: 40px !important;
  border-color: white;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}

.ps-form--newsletter .ps-input:focus {
  box-shadow: none;
}

.ps-form--newsletter .ps-input::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--newsletter .ps-input:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--newsletter .ps-input::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--newsletter .ps-btn {
  padding: 11px 35px;
  font-size: 16px;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .ps-form--newsletter .ps-btn {
    width: auto;
  }
}

@media (min-width: 1280px) {
  .ps-form--newsletter .ps-input {
    height: 46px;
    margin-bottom: 0;
    width: calc(100% - 140px);
    padding: 11px 15px;
    margin: 0;
    float: left;
  }
  .ps-form--newsletter .ps-btn {
    margin-left: 10px;
    width: 130px;
  }
}

.ps-form--review {
  color: #103178;
}

.ps-form--review input:focus {
  box-shadow: none;
}

.ps-form--review .ps-form__title {
  font-size: 22px;
  line-height: 26px;
  color: #103178;
  margin-bottom: 10px;
  font-weight: 600;
}

.ps-form--review .ps-form__desc {
  color: #103178;
  font-size: 16px;
  margin-bottom: 35px;
}

.ps-form--review .ps-form__label {
  color: #103178;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ps-form--review .ps-form__input {
  height: 46px;
}

.ps-form--review .ps-form__input,
.ps-form--review .ps-form__textarea {
  color: #5b6c8f;
  background-color: #f0f2f5;
  font-size: 16px;
  padding: 9px 15px;
  border-radius: 40px;
  border-color: #f0f2f5;
}

.ps-form--review .ps-form__textarea {
  min-height: 100px;
  border-radius: 30px;
}

.ps-form--review .br-widget a {
  font-size: 32px;
}

.ps-form--review .ps-form__block {
  margin: 40px 0 30px;
}

.ps-form--review .ps-btn {
  font-size: 18px;
  text-transform: capitalize;
  padding: 10px 25px;
  max-width: 210px;
}

@media (min-width: 1280px) {
  .ps-form--review .ps-form__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-form--contact {
  padding-bottom: 80px;
  border-bottom: 1px solid #f0f2f5;
}

.ps-form--contact .ps-form__title {
  color: #103178;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 30px;
}

.ps-form--contact .ps-form__group {
  margin-bottom: 20px;
}

.ps-form--contact .ps-form__input {
  border-radius: 40px;
  color: #5b6c8f;
  background-color: #f0f2f5;
  padding: 9px 15px;
  border: none;
  line-height: 26px;
  height: 44px;
  font-size: 16px;
}

.ps-form--contact .ps-form__input:focus {
  box-shadow: none;
}

.ps-form--contact .ps-form__input::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-form__input:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-form__input::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-form__textarea {
  border-radius: 40px;
  color: #5b6c8f;
  background-color: #f0f2f5;
  padding: 9px 15px;
  border: none;
  line-height: 26px;
  font-size: 16px;
}

.ps-form--contact .ps-form__textarea:focus {
  box-shadow: none;
}

.ps-form--contact .ps-form__textarea::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-form__textarea:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-form__textarea::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.ps-form--contact .ps-btn {
  min-width: 208px;
  font-size: 16px;
  color: white;
  background-color: #fd8d27;
  border: 1px solid #fd8d27;
  padding: 8px 15px;
  font-weight: 500;
  display: inline-block;
  text-transform: initial;
  width: auto;
  height: 44px;
}

@media (min-width: 1280px) {
  .ps-form--contact .ps-form__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-form--contact .ps-btn {
    font-size: 18px;
    height: 46px;
  }
  .ps-form--contact .ps-form__input {
    height: 46px;
    font-size: 18px;
    padding: 10px 25px;
  }
  .ps-form--contact .ps-form__input::-moz-placeholder {
    font-size: 18px;
  }
  .ps-form--contact .ps-form__input:-ms-input-placeholder {
    font-size: 18px;
  }
  .ps-form--contact .ps-form__input::placeholder {
    font-size: 18px;
  }
  .ps-form--contact .ps-form__textarea {
    font-size: 18px;
    padding: 10px 25px;
  }
  .ps-form--contact .ps-form__textarea::-moz-placeholder {
    font-size: 18px;
  }
  .ps-form--contact .ps-form__textarea:-ms-input-placeholder {
    font-size: 18px;
  }
  .ps-form--contact .ps-form__textarea::placeholder {
    font-size: 18px;
  }
}

.ps-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  transform-style: preserve-3d;
  visibility: hidden;
  opacity: 0;
  transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  transition-delay: 0;
  overflow-y: auto;
}

.ps-search:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  opacity: 0.3;
  transform: scale3d(1, 0, 1);
  transform-origin: 50% 100%;
}

.ps-search__content {
  background-color: white;
  width: 100%;
  padding: 50px 20px;
  position: relative;
}

.ps-search__content h3 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 26px;
  color: #103178;
  font-weight: 600;
}

.ps-search .ps-search__close {
  position: absolute;
  right: 20px;
  top: 50px;
  color: #103178;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 10;
}

.ps-search .ps-search__close i {
  font-weight: bold;
}

.ps-search.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.ps-search.active:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 50% 0%;
}

.ps-search .ps-search__result {
  padding-top: 50px;
  display: none;
}

.ps-search .ps-search__result.active {
  display: block;
}

.ps-search .ps-search__item {
  margin-bottom: 30px;
}

.ps-home__content {
  overflow: hidden;
}

.ps-home .ps-home__banner {
  border-radius: 4px;
  overflow: hidden;
}

.ps-home .ps-home__banner .ps-banner__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-home .ps-home__banner .ps-banner__block {
  z-index: 10;
  position: relative;
}

.ps-home .ps-home__banner .ps-banner__btn-group,
.ps-home .ps-home__banner .ps-banner__btn {
  display: block;
}

.ps-home .ps-home__banner .ps-banner__persen {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 36px;
  position: initial;
  margin: auto;
}

.ps-home .ps-home__banner .ps-banner {
  height: 460px;
  min-height: 460px !important;
}

.ps-home .ps-home__banner .ps-banner__content {
  height: auto;
}

.ps-home .ps-home__banner .ps-banner__title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}

.ps-home .ps-home__banner .ps-banner__price {
  margin-bottom: 10px;
}

.ps-home .ps-home__banner .ps-banner__price span {
  font-size: 34px;
  line-height: 40px;
}

.ps-home .ps-home__banner .ps-banner__price del {
  font-size: 22px;
  line-height: 26px;
  top: -7px;
}

.ps-home .ps-home__banner .ps-banner__shop {
  font-size: 16px;
  font-weight: 600;
}

.ps-home .ps-home__banner .ps-banner__btn {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #103178;
}

@media (min-width: 768px) {
  .ps-home .ps-home__banner .ps-banner {
    height: 300px;
    min-height: 300px !important;
  }
  .ps-home .ps-home__banner .ps-banner .ps-banner__content {
    padding: 4%;
    flex: 0 0 50%;
  }
  .ps-home .ps-home__banner .ps-banner .ps-banner__thumnail {
    flex: 0 0 50%;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .ps-home .ps-home__banner .ps-banner {
    height: 350px;
    min-height: 300px !important;
  }
  .ps-home .ps-home__banner .ps-banner__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-home .ps-home__banner .ps-banner__price span {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-home .ps-home__banner .ps-banner__price del {
    font-size: 24px;
    line-height: 30px;
    top: -14px;
  }
  .ps-home .ps-home__banner .ps-banner__shop {
    font-size: 18px;
    margin-top: 20px;
  }
  .ps-home .ps-home__banner .ps-banner__btn-group {
    display: table;
  }
  .ps-home .ps-home__banner .ps-banner__btn {
    display: table-cell;
  }
}

.ps-delivery {
  padding: 30px 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.ps-delivery .ps-delivery__text {
  color: #12a05c;
  font-size: 22px;
  line-height: 26px;
  display: inline-flex;
  align-items: center;
}

.ps-delivery .ps-delivery__text i {
  font-weight: bold;
  margin-right: 10px;
}

.ps-delivery .ps-delivery__more {
  background-color: #12a05c;
  border: 1px solid #12a05c;
  font-size: 18px;
  font-weight: 500;
  color: white;
  min-width: 98px;
  border-radius: 20px;
  text-align: center;
  padding: 4px;
  display: inline-block;
  margin-top: 15px;
}

.ps-delivery .ps-delivery__more:hover {
  background-color: white;
  color: #12a05c;
}

@media (min-width: 768px) {
  .ps-delivery .ps-delivery__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ps-delivery .ps-delivery__more {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .ps-delivery .ps-delivery__text {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-delivery--info .ps-delivery__more {
  border-color: #00605a;
  background-color: #00605a;
}

.ps-delivery--info .ps-delivery__text {
  color: #00605a;
}

.ps-home--block .ps-block__image {
  margin-bottom: 10px;
  height: 100%;
}

.ps-home--block .ps-block__image .ps-banner {
  height: 350px;
  min-height: 330px !important;
}

.ps-home--block .ps-block__image .ps-banner .ps-banner__content {
  flex: 0 0 100%;
  text-align: left;
}

.ps-home--block .ps-block__image .ps-home__banner {
  height: 100%;
}

.ps-home--block .ps-block__product {
  border-top: 1px solid #f0f2f5;
  border-left: 1px solid #f0f2f5;
}

.ps-home--block .ps-block__product .ps-product--standard {
  border-right: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  height: 100%;
}

@media (min-width: 768px) {
  .ps-home--block .ps-block__image .ps-banner {
    height: 100%;
  }
  .ps-home--block .ps-block__image .ps-banner .ps-banner__content {
    padding: 50px 20px 0;
  }
  .ps-home--block .ps-block__image .ps-banner .ps-banner__price {
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .ps-home--block .ps-block__image .ps-banner .ps-banner__content {
    padding: 50px 40px 0;
  }
}

.ps-section--category .ps-section__title {
  font-weight: bold;
  color: #103178;
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-section--category .ps-category--block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.ps-section--category .ps-category__thumbnail {
  padding: 4px;
  position: relative;
  min-height: 190px;
  max-width: 50%;
}

.ps-section--category .ps-category__image {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 4px;
}

.ps-section--category .ps-category__content {
  position: absolute;
  padding-bottom: 10px;
  top: 15px;
  left: 15px;
}

.ps-section--category .ps-category__name {
  margin-bottom: 14px;
  color: #103178;
  font-size: 18px;
  display: block;
  font-weight: bold;
  font-weight: 500;
}

.ps-section--category .ps-category__more {
  color: #103178;
  background-color: white;
  font-size: 14px;
  padding: 3px 10px;
  min-width: 80px;
  line-height: 24px;
  border-radius: 40px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
}

.ps-section--category .ps-category__more:hover {
  background-color: #103178;
  color: white;
}

.ps-section--category .ps-category__title {
  font-weight: bold;
  color: #103178;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}

.ps-section--category .ps-category__list {
  margin-bottom: 35px;
}

.ps-section--category .ps-category__list li {
  line-height: 20px;
  padding: 8px 0;
}

.ps-section--category .ps-category__list li a {
  color: #103178;
  font-size: 16px;
}

.ps-section--category .ps-category__list li a:hover {
  color: #fd8d27;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .ps-section--category .ps-section__title {
    font-size: 24px;
    line-height: 30px;
  }
  .ps-section--category .ps-category__name {
    font-size: 24px;
    line-height: 28px;
  }
  .ps-section--category .ps-category__content {
    top: 35px;
    left: 35px;
    padding: 0;
    max-width: 60%;
  }
  .ps-section--category .ps-category__thumbnail {
    min-height: 236px;
    max-width: 33.33333%;
  }
}

@media (min-width: 1279px) {
  .ps-section--category .ps-category__thumbnail {
    min-height: 300px;
    width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .ps-section--category .ps-category--block {
    flex-wrap: nowrap;
  }
}

.ps-section--category-horizontal {
  position: relative;
}

.ps-section--category-horizontal::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #f0f2f5;
  position: absolute;
  bottom: 50px;
  left: 0;
}

.ps-section--category-horizontal .ps-section__title {
  display: none;
  color: #103178;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}

.ps-section--category-horizontal .ps-category__all {
  display: none;
  text-align: center;
}

.ps-section--category-horizontal .ps-category__all a {
  display: inline-block;
  color: #103178;
  border: 1px solid #f0f2f5;
  border-radius: 40px;
  padding: 3px 10px;
  min-width: 80px;
  background-color: white;
  font-size: 14px;
}

.ps-section--category-horizontal .ps-category__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ps-section--category-horizontal .ps-category__item {
  text-align: center;
  height: 100%;
  flex-basis: 33.3333%;
  padding: 0 15px;
}

.ps-section--category-horizontal .ps-category__link {
  display: block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: auto;
}

.ps-section--category-horizontal .ps-category__link img {
  max-width: 36px;
  margin: auto;
}

.ps-section--category-horizontal .ps-category__name {
  margin-bottom: 0;
}

.ps-section--category-horizontal .ps-category__name a {
  display: block;
  color: #103178;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 30px;
}

.ps-section--category-horizontal .ps-category__carousel {
  padding: 15px 0;
}

.ps-section--category-horizontal .ps-category__carousel .ps-category__item {
  width: 100%;
  flex-basis: 100%;
}

.ps-section--category-horizontal .ps-category__carousel .ps-category__link {
  width: 46px;
  height: 46px;
}

.ps-section--category-horizontal .ps-category__carousel .ps-category__name a {
  margin-bottom: 0;
}

.ps-section--category-horizontal .owl-nav,
.ps-section--category-horizontal .owl-dots {
  display: none;
}

@media (min-width: 768px) {
  .ps-section--category-horizontal .ps-category__item {
    flex-basis: 20%;
  }
}

@media (min-width: 960px) {
  .ps-section--category-horizontal .ps-category__item {
    flex-basis: 14.285%;
  }
}

@media (min-width: 1280px) {
  .ps-section--category-horizontal .ps-section__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-category--image .ps-category__thumbnail {
  max-width: 100%;
  height: 100%;
  min-height: 130px;
  padding: 0;
}

.ps-category--image .ps-category__thumbnail img {
  max-width: 60%;
}

.ps-category--image .row {
  margin-left: -4px;
  margin-right: -4px;
}

.ps-category--image .col-6 {
  padding: 4px;
}

.ps-category--image .ps-category__content {
  max-width: 100%;
}

@media (min-width: 768px) {
  .ps-category--image .ps-category__content {
    top: 15px;
    left: 15px;
  }
}

@media (min-width: 1280px) {
  .ps-category--image .ps-category__content {
    top: 44px;
    left: 35px;
    max-width: 60%;
  }
  .ps-category--image .ps-category__thumbnail {
    max-height: 160px;
  }
}

.ps-branch .ps-branch__box {
  display: flex;
  flex-flow: row wrap;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #f0f2f5;
}

.ps-branch .ps-branch__item {
  padding: 15px;
  flex-basis: 50%;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #f0f2f5;
  text-align: center;
}

.ps-branch a {
  display: block;
}

.ps-branch img {
  width: 100%;
}

.ps-branch .ps-branch__title {
  display: none;
  color: #103178;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .ps-branch .ps-branch__item {
    flex-basis: 20%;
  }
}

@media (min-width: 1280px) {
  .ps-branch .ps-branch__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-categogy .ps-categogy__name {
  color: #103178;
  font-size: 34px;
  line-height: 40px;
  margin: 0;
}

.ps-categogy .ps-categogy__name sup {
  color: #5b6c8f;
  font-size: 42%;
  line-height: 20px;
  vertical-align: super;
  margin-left: 10px;
}

.ps-categogy .ps-categogy__content {
  padding-top: 40px;
}

.ps-categogy .ps-delivery {
  margin: 30px 0 80px;
}

.ps-categogy .ps-delivery .ps-delivery__text {
  font-size: 18px;
}

.ps-categogy .ps-delivery .ps-delivery__more {
  min-width: 80px;
  font-size: 16px;
  padding: 2px;
}

.ps-categogy .ps-categogy__wrapper {
  background-color: #f0f2f5;
  border-radius: 5px;
  padding: 0 20px;
  display: flex;
}

.ps-categogy .ps-categogy__wrapper span {
  margin-right: 5px;
}

.ps-categogy .ps-categogy__wrapper .form-select {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #103178;
}

.ps-categogy .ps-categogy__filter {
  padding: 14px 30px 14px 0;
  margin-right: 30px;
  border-right: 1px solid #d9dee8;
}

.ps-categogy .ps-categogy__filter a {
  display: inline-block;
  font-weight: 600;
  color: #103178;
}

.ps-categogy .ps-categogy__filter a .fa-times {
  display: none;
}

.ps-categogy .ps-categogy__filter a i {
  margin-right: 5px;
}

.ps-categogy .ps-categogy__filter .active .fa-filter {
  display: none;
}

.ps-categogy .ps-categogy__filter .active .fa-times {
  display: inline-block;
}

.ps-categogy .ps-categogy__type {
  padding: 14px 30px 14px 0;
  margin-right: 30px;
  border-right: 1px solid #d9dee8;
  display: none;
}

.ps-categogy .ps-categogy__type a {
  display: inline-block;
  margin-right: 20px;
}

.ps-categogy .ps-categogy__type a:last-child {
  margin-right: 0;
}

.ps-categogy .ps-categogy__type img {
  width: 15px;
  height: 15px;
}

.ps-categogy .ps-categogy__type .active img {
  filter: invert(100%) sepia(90%) saturate(500%) hue-rotate(200deg) brightness(70%) contrast(100%);
}

.ps-categogy .ps-categogy__sort,
.ps-categogy .ps-categogy__onsale {
  color: #103178;
  font-size: 14px;
  padding: 14px 0;
}

.ps-categogy .ps-categogy__sort select,
.ps-categogy .ps-categogy__onsale select {
  display: inline-block;
  max-width: 100px;
}

.ps-categogy .ps-categogy__show {
  padding: 14px 0 14px 30px;
  margin-left: 30px;
  border-left: 1px solid #d9dee8;
  color: #103178;
  font-size: 14px;
}

.ps-categogy .ps-categogy__onsale {
  flex-grow: 1;
  display: none;
}

.ps-categogy .ps-categogy__loading {
  text-align: center;
}

.ps-categogy .ps-categogy__loading p {
  color: #103178;
  text-align: center;
  font-size: 16px;
}

.ps-categogy .ps-categogy__loading .ps-btn {
  display: inline-block;
  max-width: 200px;
  margin: 10px auto;
  text-transform: initial;
  font-size: 18px;
  padding: 9px 25px;
}

.ps-categogy .ps-categogy__loading .load-wrapp {
  position: relative;
}

.ps-categogy .ps-categogy__loading .ring-2 {
  position: relative;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  border: 3px solid #103178;
  border-radius: 100%;
}

.ps-categogy .ps-categogy__loading .ball-holder {
  position: absolute;
  width: 12px;
  height: 33px;
  left: 10px;
  top: -3px;
  -webkit-animation: loadingE 1.3s linear infinite;
  animation: loadingE 1.3s linear infinite;
}

.ps-categogy .ps-categogy__loading .ball {
  position: absolute;
  top: -5px;
  left: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: white;
}

@-webkit-keyframes loadingE {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loadingE {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .ps-categogy .ps-categogy__name {
    font-size: 45px;
    line-height: 50px;
  }
  .ps-categogy .ps-categogy__onsale {
    display: block;
  }
  .ps-categogy .ps-categogy__sort select,
  .ps-categogy .ps-categogy__onsale select {
    max-width: 120px;
  }
  .ps-categogy .row-reverse {
    flex-direction: row-reverse;
  }
}

@media (min-width: 960px) {
  .ps-categogy .ps-categogy__sort select,
  .ps-categogy .ps-categogy__onsale select {
    max-width: 150px;
  }
}

@media (min-width: 1280px) {
  .ps-categogy .ps-categogy__type {
    display: block;
  }
  .ps-categogy .ps-categogy__name {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-categogy .ps-delivery .ps-delivery__text {
    font-size: 20px;
  }
  .ps-categogy .ps-delivery .ps-delivery__more {
    min-width: 90px;
    font-size: 18px;
  }
}

.ps-categogy--grid {
  margin-top: 30px;
  border-top: 1px solid #f0f2f5;
  border-left: 1px solid #f0f2f5;
}

.ps-categogy--grid .ps-product--standard {
  height: 100%;
  border-bottom: 1px solid #f0f2f5;
  border-right: 1px solid #f0f2f5;
}

.ps-categogy--grid .ps-product--standard .ps-product__title {
  min-height: 50px;
}

.ps-categogy--grid .ps-product--standard .ps-product__branch {
  display: none;
}

.ps-categogy--detail .ps-product--standard .ps-product__thumbnail .ps-product__actions .ps-product__item {
  display: none;
}

.ps-categogy--detail .ps-product--standard .ps-product__thumbnail .ps-product__actions .ps-product__item:nth-child(3) {
  display: flex;
}

.ps-categogy--detail .ps-product--standard .ps-product__content .ps-product__actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ps-categogy--detail .ps-product--standard .ps-product__content .ps-product__actions .ps-product__item.cart {
  display: none;
}

.ps-categogy--detail .ps-product--standard .ps-product__branch,
.ps-categogy--detail .ps-product--standard .ps-product__cart,
.ps-categogy--detail .ps-product--standard .ps-product__review {
  display: inline-block;
}

.ps-categogy--detail .ps-product--standard .ps-product__title a {
  font-weight: 600;
}

.ps-categogy--detail .ps-product--standard .ps-product__desc {
  display: block;
}

.ps-categogy--separate .ps-categogy__content {
  padding-top: 20px;
}

.ps-categogy--separate .ps-categogy__wrapper {
  background-color: white;
  padding: 14px 0;
}

.ps-categogy--separate .ps-categogy__filter,
.ps-categogy--separate .ps-categogy__type,
.ps-categogy--separate .ps-categogy__onsale,
.ps-categogy--separate .ps-categogy__sort,
.ps-categogy--separate .ps-categogy__show {
  padding-top: 0;
  padding-bottom: 0;
}

.ps-categogy--separate .ps-categogy__main {
  background-color: #f7f8fa;
  padding-top: 40px;
  margin-bottom: 30px;
}

.ps-categogy--separate .ps-categogy__main .container {
  position: relative;
  overflow: hidden;
}

.ps-categogy--separate .ps-categogy__main.active .ps-categogy__widget {
  transform: translate(0, 0);
}

.ps-categogy--separate .ps-categogy__widget {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  padding: 40px 30px;
  max-width: 100%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
  height: 100vh;
  background-color: white;
}

.ps-categogy--separate .ps-categogy__widget #close-widget-product {
  position: absolute;
  top: 10px;
  right: 18px;
  width: 36px;
  height: 36px;
  color: #103178;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.ps-categogy--separate .ps-widget__block {
  border-bottom: none;
}

.ps-categogy--separate .ps-widget__block .ps-widget__content {
  display: block;
}

.ps-categogy--separate .ps-widget__block .ps-block-control {
  display: none;
}

.ps-categogy--separate .ps-categogy__product {
  padding-left: 0;
  transition: padding 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), min-height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ps-categogy--separate .ps-product--standard {
  background-color: white;
  margin: 0 5px 10px;
}

.ps-categogy--separate .ps-product--standard .ps-product__title {
  min-height: 50px;
}

.ps-categogy--separate .ps-product--standard .ps-product__title a {
  font-weight: 600;
}

.ps-categogy--separate .ps-product--standard .ps-product__desc {
  display: block;
}

.ps-categogy--separate .menu--mobile .sub-menu {
  background-color: transparent;
}

.ps-categogy--separate .ps-categogy__show {
  display: none;
}

.ps-categogy--separate .ps-categogy__sort {
  flex-grow: 1;
  text-align: right;
}

@media (min-width: 768px) {
  .ps-categogy--separate .ps-categogy__main.active .ps-categogy__product {
    padding-left: calc(20% + 15px);
  }
  .ps-categogy--separate .ps-categogy__widget {
    position: absolute;
    background-color: transparent;
    height: auto;
    width: calc(20% + 30px);
    padding: 0;
  }
  .ps-categogy--separate .ps-categogy__widget #close-widget-product {
    display: none;
  }
  .ps-categogy--separate .ps-categogy__show {
    display: inline-block;
  }
  .ps-categogy--separate .ps-product--standard {
    margin: 0 10px 20px;
  }
}

.ps-categogy--list {
  padding-top: 30px;
}

.ps-categogy--list .ps-product--list {
  margin-bottom: 10px;
}

.ps-categogy--dark .ps-categogy__name,
.ps-categogy--dark .ps-breadcrumb__item {
  color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__title,
.ps-categogy--dark .ps-product--standard .ps-product__del {
  color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__footer {
  color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__footer span {
  color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__percent {
  background-color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__price {
  color: #333;
}

.ps-categogy--dark .ps-product--standard .ps-product__price.sale {
  color: #fd8d27;
}

.ps-categogy--dark .ps-widget--product .ps-widget__title {
  color: #333;
}

.ps-categogy--dark .ps-widget__promo {
  display: none;
}

.ps-categogy--dark .ps-footer--banner {
  display: none;
}

.ps-categogy--dark .ps-widget__item .custom-control-label {
  color: #333;
}

.ps-categogy--dark .ps-widget__item .custom-label span {
  color: #333;
}

.ps-categogy--dark .ps-widget__filter {
  color: #333;
}

.ps-categogy--dark .ps-widget__price .noUi-connect,
.ps-categogy--dark .ps-widget__price .noUi-target.noUi-horizontal .noUi-tooltip {
  background-color: #333;
}

.ps-categogy--dark .sub-menu > li > a,
.ps-categogy--dark .menu--mobile > li > a,
.ps-categogy--dark .menu--mobile .sub-toggle::before,
.ps-categogy--dark .ps-categogy__show,
.ps-categogy--dark .ps-categogy__sort,
.ps-categogy--dark .ps-categogy__onsale {
  color: #333;
}

.ps-categogy--dark .ps-categogy__wrapper {
  background-color: #ededed;
}

.ps-categogy--dark .ps-categogy__wrapper .form-select {
  color: #333;
}

.ps-categogy--dark .ps-pagination .pagination .active a {
  background-color: #333;
  color: white;
}

.ps-categogy--dark .ps-pagination .pagination a {
  color: #333;
}

.ps-categogy--dark .custom-control input:checked ~ .custom-control-label::before {
  background-color: #333;
}

.ps-categogy--dark .ps-categogy__type .active img {
  filter: invert(10%) sepia(100%) saturate(2800%) hue-rotate(150deg) brightness(94%) contrast(90%);
}

.ps-categogy--dark.ps-page--sticky .ps-pagination {
  margin-bottom: 80px;
}

.ps-categogy--dark .ps-nextpage {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  font-size: 25px;
  border-right: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  padding: 40px 20px;
  line-height: 30px;
}

.ps-categogy--dark .ps-nextpage .ps-nextpage__text {
  font-weight: 600;
  margin-bottom: 20px;
}

.ps-categogy--dark .ps-nextpage i {
  font-weight: 600;
}

@media (min-width: 1280px) {
  .ps-categogy--dark .ps-nextpage {
    font-size: 30px;
    line-height: 35px;
  }
}

.ps-section--latest .ps-section__title {
  color: #103178;
  font-weight: bold;
  line-height: 50px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-section--latest .ps-section__carousel {
  padding-bottom: 50px;
  border-radius: 4px;
}

.ps-section--latest .ps-section__product {
  border-left: 1px solid #f0f2f5;
}

.ps-section--latest .owl-carousel {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
}

.ps-section--latest .owl-carousel .owl-dots {
  bottom: -50px;
}

.ps-section--latest .owl-carousel .owl-nav {
  display: none;
}

.ps-section--latest .owl-carousel .owl-nav .owl-prev {
  margin-left: -30px;
}

.ps-section--latest .owl-carousel .owl-nav .owl-next {
  margin-right: -30px;
}

.ps-section--latest .owl-carousel .owl-item:not(.active) + .owl-item.active .ps-section__product {
  border-left: none;
}

@media (min-width: 1024px) {
  .ps-section--latest .ps-section__title {
    font-size: 40px;
  }
  .ps-section--latest .owl-carousel .owl-nav {
    display: block;
  }
}

.ps-section--latest-horizontal .ps-section__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-section--latest-horizontal .ps-section__content {
  border-top: 1px solid #f0f2f5;
  border-right: 1px solid #f0f2f5;
}

.ps-section--latest-horizontal .ps-section__product {
  border-left: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  height: 100%;
}

.ps-section--latest-horizontal .ps-section__product .ps-product__title {
  min-height: 50px;
}

.ps-section--latest-horizontal .ps-section__product .ps-product--standard {
  padding-top: 10px;
}

@media (min-width: 1280px) {
  .ps-section--latest-horizontal .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
}

.ps-section--also {
  background-position: center;
  background-size: cover;
  margin-bottom: 30px;
  padding: 70px 0 120px;
}

.ps-section--also .ps-section__title {
  color: #103178;
  text-align: center;
  margin-bottom: 30px;
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}

.ps-section--also .ps-section__carousel {
  background-color: white;
}

.ps-section--also .ps-product--standard {
  border-left: 1px solid #f0f2f5;
}

.ps-section--also .ps-product--standard .ps-product__title {
  min-height: 72px;
}

.ps-section--also .owl-dots {
  bottom: -40px;
}

.ps-section--also .owl-nav {
  display: none;
}

.ps-section--also .owl-nav .owl-prev {
  margin-left: -25px;
}

.ps-section--also .owl-nav .owl-next {
  margin-right: -25px;
}

@media (min-width: 1280px) {
  .ps-section--also .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-section--also .owl-carousel:hover .owl-nav {
    display: block;
  }
}

.ps-section--deals .ps-section__header {
  margin-bottom: 20px;
}

.ps-section--deals .ps-section__title {
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #103178;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.ps-section--deals .ps-countdown .ps-countdown__days {
  display: none;
}

.ps-section--deals .ps-section__carousel {
  border: 3px solid #fd8d27;
  border-radius: 5px;
}

.ps-section--deals .owl-carousel .owl-dots {
  bottom: -50px;
  display: none;
}

.ps-section--deals .owl-carousel .owl-nav {
  display: none;
}

.ps-section--deals .owl-carousel .owl-nav .owl-prev {
  margin-left: -30px;
}

.ps-section--deals .owl-carousel .owl-nav .owl-next {
  margin-right: -30px;
}

.ps-section--deals .owl-carousel .owl-item:not(.active) + .owl-item.active .ps-section__product {
  border-left: none;
}

.ps-section--deals .ps-section__product {
  border-left: 1px solid #f0f2f5;
}

@media (min-width: 768px) {
  .ps-section--deals .ps-section__header {
    display: flex;
    justify-content: center;
  }
  .ps-section--deals .ps-countdown {
    width: auto;
    margin-left: 30px;
  }
  .ps-section--deals .ps-section__title {
    margin-bottom: 0;
  }
  .ps-section--deals .owl-carousel:hover .owl-nav {
    display: block;
  }
}

@media (min-width: 1024px) {
  .ps-section--deals .ps-section__title {
    font-size: 40px;
  }
}

.ps-section--weekdeal .ps-section__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-section--weekdeal .ps-weekdeal__product {
  border-radius: 4px;
  border: 3px solid #fd8d27;
}

.ps-section--weekdeal .ps-countdown {
  height: 80px;
  padding-top: 20px;
}

.ps-section--weekdeal .ps-countdown .ps-countdown__days {
  display: none;
}

@media (min-width: 768px) {
  .ps-section--weekdeal .ps-countdown .ps-countdown__block {
    margin: 5px;
  }
  .ps-section--weekdeal .ps-countdown .ps-countdown__block:not(:last-child)::after {
    right: -10px;
  }
  .ps-section--weekdeal .ps-countdown .ps-countdown__hours {
    margin-left: 0;
  }
  .ps-section--weekdeal .ps-countdown .ps-countdown__seconds {
    margin-right: 0;
  }
}

@media (min-width: 1280px) {
  .ps-section--weekdeal .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-section--weekdeal .ps-countdown .ps-countdown__block {
    margin: 8px;
  }
  .ps-section--weekdeal .ps-countdown .ps-countdown__block:not(:last-child)::after {
    right: -12px;
  }
}

.ps-section--sellers .ps-section__title {
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-section--sellers .ps-section__tab .nav-tabs {
  margin-bottom: 20px;
  justify-content: center;
  border: none;
}

.ps-section--sellers .ps-section__tab .nav-link {
  color: #103178 !important;
  padding: 0 20px 10px;
  font-size: 22px;
  line-height: 26px;
  border: none;
}

.ps-section--sellers .ps-section__tab .nav-link.active {
  font-weight: bold;
  text-decoration: underline;
}

.ps-section--sellers .ps-section__tab .tab-content {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
}

.ps-section--sellers .ps-section__tab .ps-section__product {
  border-left: 1px solid #f0f2f5;
}

.ps-section--sellers .ps-section__tab .owl-item:not(.active) + .owl-item.active .ps-section__product {
  border-left: none;
}

.ps-section--sellers .owl-nav {
  display: none;
}

.ps-section--sellers .owl-dots {
  bottom: -40px;
}

@media (min-width: 1024px) {
  .ps-section--sellers .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-section--sellers .ps-section__tab .nav-link {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-section--seller-diagnosis {
  padding-bottom: 50px;
}

.ps-section--seller-diagnosis .ps-section__title {
  color: #103178;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-section--seller-diagnosis .ps-section__carousel {
  border: 3px solid #fd8d27;
  border-radius: 4px;
}

.ps-section--seller-diagnosis .ps-product--standard {
  border-left: 1px solid #f0f2f5;
}

.ps-section--seller-diagnosis .owl-nav,
.ps-section--seller-diagnosis .owl-dots {
  display: none;
}

.ps-section--seller-diagnosis .owl-nav .owl-next {
  margin-right: -25px;
}

.ps-section--seller-diagnosis .owl-nav .owl-prev {
  margin-left: -25px;
}

@media (min-width: 1280px) {
  .ps-section--seller-diagnosis .ps-section__title {
    font-size: 24px;
  }
  .ps-section--seller-diagnosis .owl-nav {
    display: block;
  }
}

.ps-section--face-mask {
  margin-bottom: 40px;
}

.ps-section--face-mask .ps-section__title {
  margin-bottom: 25px;
  color: #103178;
  font-size: 22px;
  font-weight: 600;
}

.ps-section--face-mask .ps-section__carousel {
  border: 3px solid #fd8d27;
  border-radius: 4px;
}

.ps-section--face-mask .owl-nav,
.ps-section--face-mask .owl-dots {
  display: none;
}

.ps-section--face-mask .ps-product--standard {
  border-left: 1px solid #f0f2f5;
}

.ps-section--face-mask .ps-product--standard .ps-product__title {
  min-height: 50px;
}

.ps-section--face-mask .owl-item:not(.active) + .owl-item.active .ps-product--standard {
  border-left: none;
}

@media (min-width: 1280px) {
  .ps-section--face-mask .ps-section__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-section--featured .ps-section__title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-section--featured .ps-shop__more {
  display: none;
  margin-top: 30px;
  text-align: center;
}

.ps-section--featured .ps-shop__more a {
  border: 1px solid #f0f2f5;
  color: #103178;
  padding: 3px 10px;
  min-width: 80px;
  font-size: 14px;
  border-radius: 40px;
}

.ps-section--featured .ps-section__content .row {
  border-top: 1px solid #f0f2f5;
  border-right: 1px solid #f0f2f5;
  border-radius: 4px;
  overflow: hidden;
}

.ps-section--featured .ps-section__product {
  border-left: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  height: 100%;
}

.ps-section--featured .ps-section__product .ps-product__title {
  overflow: hidden;
}

@media (min-width: 992px) {
  .ps-section--featured .col-lg-2dot4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1024px) {
  .ps-section--featured .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
}

.ps-section--choose-style {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ps-section--choose-style .ps-section__title {
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.ps-section--choose-style .ps-section__product {
  padding: 0 5px 5px;
  flex-grow: 1;
  width: 50%;
  flex-basis: 50%;
}

.ps-section--choose-style .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
}

.ps-section--choose-style .ps-product--standard {
  border-radius: 4px;
}

.ps-section--choose-style .ps-product--standard .ps-product__title {
  margin-bottom: 15px;
}

.ps-section--choose-style .ps-product--standard .ps-product__desc {
  display: block;
  margin-bottom: 20px;
}

.ps-section--choose-style .ps-product--standard .ps-product__meta {
  margin-bottom: 10px;
}

.ps-section--choose-style .ps-product--standard .ps-product__content .ps-product__actions {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .ps-section--choose-style .ps-section__product {
    padding: 0 10px;
    width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
}

@media (min-width: 1024px) {
  .ps-section--choose-style .ps-section__title {
    font-size: 40px;
  }
  .ps-section--choose-style .ps-section__content {
    max-width: 990px;
    margin: auto;
  }
  .ps-section--choose-style .ps-section__product {
    padding: 0 15px;
  }
  .ps-section--choose-style .ps-product--standard .ps-product__content .ps-product__actions {
    display: block;
  }
  .ps-section--choose-style .ps-product--standard .ps-product__content .ps-product__item {
    display: none;
  }
  .ps-section--choose-style .ps-product--standard .ps-product__content .ps-product__cart {
    display: block;
  }
}

.ps-section--black-style {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ps-section--black-style .ps-section__title {
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.ps-section--black-style .ps-section__product {
  padding: 0 5px 5px;
  flex-grow: 1;
  width: 50%;
  flex-basis: 50%;
}

.ps-section--black-style .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
}

.ps-section--black-style .ps-product--standard {
  background-color: transparent;
  text-align: center;
}

.ps-section--black-style .ps-product--standard .ps-product__branch,
.ps-section--black-style .ps-product--standard .ps-product__rating {
  display: none;
}

.ps-section--black-style .ps-product--standard .ps-product__title {
  min-height: auto;
  color: #222;
  margin-bottom: 8px;
}

.ps-section--black-style .ps-product--standard .ps-product__meta {
  margin-bottom: 15px;
}

.ps-section--black-style .ps-product--standard .ps-product__price {
  color: #222;
  font-size: 20px;
}

.ps-section--black-style .ps-product--standard .ps-product__price.sale {
  color: #fd8d27;
}

.ps-section--black-style .ps-product--standard .ps-product__del {
  color: #222;
  font-size: 16px;
}

.ps-section--black-style .ps-product--standard .ps-product__content .ps-product__actions,
.ps-section--black-style .ps-product--standard .ps-product__content .ps-product__cart,
.ps-section--black-style .ps-product--standard .ps-product__content .ps-product__quantity {
  display: block;
}

.ps-section--black-style .ps-product--standard .ps-product__content .ps-product__item {
  display: none;
}

.ps-section--black-style .ps-product--standard .ps-product__quantity {
  margin-bottom: 10px;
}

.ps-section--black-style .ps-product--standard .ps-product__cart .ps-btn {
  min-width: 0;
  width: 100%;
  font-weight: 500;
  padding: 8px 10px;
  font-size: 100%;
  line-height: 24px;
}

@media (min-width: 768px) {
  .ps-section--black-style .ps-section__product {
    padding: 0 10px;
    width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
  .ps-section--black-style .ps-product--standard .ps-product__quantity {
    width: 172px;
    margin: 0 auto 10px;
  }
  .ps-section--black-style .ps-product--standard .ps-product__cart .ps-btn {
    min-width: 172px;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .ps-section--black-style .ps-section__title {
    font-size: 40px;
  }
  .ps-section--black-style .ps-section__content {
    max-width: 990px;
    margin: auto;
  }
  .ps-section--black-style .ps-section__product {
    padding: 0 15px;
  }
  .ps-section--black-style .ps-product--standard .ps-product__cart .ps-btn {
    padding: 10px;
    font-size: 115%;
    min-width: 172px;
  }
  .ps-section--black-style .ps-product--standard .ps-product__quantity .number-input button {
    padding: 16px;
  }
}

.ps-section--reviews {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fdf6ed;
}

.ps-section--reviews .ps-section__title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 34px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-section--reviews .ps-section__title img {
  height: 23px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.ps-section--reviews .ps-section__content .owl-item {
  padding: 0 15px;
}

.ps-section--reviews .ps-section__content .owl-carousel {
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.ps-section--reviews .ps-section__content .owl-nav {
  display: none;
}

.ps-section--reviews .ps-section__content .owl-dots {
  bottom: -40px;
}

@media (min-width: 992px) {
  .ps-section--reviews .ps-section__content .owl-carousel {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1024px) {
  .ps-section--reviews .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-section--reviews .ps-section__title img {
    height: 28px;
  }
}

@media (min-width: 1280px) {
  .ps-section--reviews .ps-section__content .owl-carousel {
    width: calc(100vw + 240px);
    margin-left: calc(-50vw + 50% - 120px);
    margin-right: calc(-50vw + 50% - 120px);
  }
}

.ps-review {
  background-color: white;
  font-size: 18px;
  line-height: 26px;
  border-radius: 4px;
  padding: 30px;
  color: #103178;
}

.ps-review__text {
  margin-bottom: 15px;
}

.ps-review__name {
  font-weight: 600;
}

.ps-review__review {
  font-size: 14px;
  margin-top: 15px;
}

.ps-review__review .br-widget {
  height: auto;
}

.ps-review__review .br-widget a {
  font-size: 14px;
}

.ps-reviews--2 {
  background-image: none;
  background-color: white;
  overflow: hidden;
}

.ps-reviews--2 .ps-section__carousel {
  padding-top: 110px;
  padding-bottom: 110px;
}

.ps-reviews--2 .ps-section__thumbnail {
  width: 100%;
}

.ps-reviews--2 .ps-review {
  background-color: #f0f2f5;
}

@media (min-width: 768px) {
  .ps-reviews--2 .ps-section__thumbnail {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1280px) {
  .ps-reviews--2 .ps-section__thumbnail {
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }
  .ps-reviews--2 .ps-section__title {
    text-align: left;
  }
  .ps-reviews--2 .ps-section__content .owl-carousel {
    width: 100%;
    margin: auto;
  }
}

.ps-review--product {
  padding: 20px;
  border: 1px solid #d9dee8;
  color: #103178;
}

.ps-review--product .ps-review__row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.ps-review--product .ps-review__avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.ps-review--product .ps-review__info {
  padding: 0 25px;
}

.ps-review--product .ps-review__name,
.ps-review--product .ps-review__date {
  font-weight: bold;
  font-size: 90%;
  line-height: 20px;
}

.ps-review--product .ps-review__date {
  color: #5b6c8f;
}

.ps-review--product .ps-review__rating .br-widget a {
  font-size: 14px;
}

.ps-review--product .ps-review__rating .br-widget .br-selected::after {
  color: #fd8d27;
}

.ps-review--product .ps-review__desc {
  flex: 0 0 100%;
  margin-top: 20px;
}

.ps-review--product .ps-review__desc p {
  font-size: 16px;
  margin-bottom: 0;
  color: #103178;
}

.ps-review--product .ps-review__reply {
  color: #103178;
  font-weight: 600;
  display: inline-block;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 8px;
}

@media (min-width: 768px) {
  .ps-review--product .ps-review__desc {
    margin-top: 0;
    flex: 0 0 50%;
    padding-left: 20px;
  }
  .ps-review--product .ps-review__info {
    padding: 0 15px;
  }
}

@media (min-width: 1280px) {
  .ps-review--product {
    padding: 30px;
  }
  .ps-review--product .ps-review__avatar img {
    width: 60px;
    height: 60px;
  }
  .ps-review--product .ps-review__info {
    padding: 0 25px;
  }
  .ps-review--product .ps-review__desc {
    flex: 0 0 65%;
    padding-left: 30px;
  }
}

.ps-section--review-product {
  padding: 80px 0 50px;
}

.ps-section--review-product .ps-section__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #103178;
  font-weight: 600;
}

.ps-section--review-product .ps-section__toggle {
  text-decoration: underline;
  color: #103178;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  display: inline-block;
}

.ps-section--review-product .ps-section__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ps-section--review-product .ps-section__list .ps-review {
  border: 1px solid #f0f2f5;
  border-radius: 4px;
}

.ps-section--review-product .ps-section__list li {
  margin-bottom: 10px;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.ps-section--review-product .ps-form--review {
  display: none;
  padding-bottom: 50px;
}

.ps-section--review-product .ps-form--review .ps-form__title {
  display: none;
}

@media (min-width: 768px) {
  .ps-section--review-product .ps-section__list {
    -moz-columns: 3;
    columns: 3;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}

@media (min-width: 1280px) {
  .ps-section--review-product .ps-section__title {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-section--review-product .ps-section__list {
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  .ps-section--review-product .ps-section__list li {
    margin-bottom: 30px;
  }
}

.ps-reviews--3 {
  border-top: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  background-image: none;
  background-color: white;
}

.ps-reviews--3 .ps-review .ps-review__review {
  margin-top: 0;
}

.ps-reviews--3 .ps-review .ps-review__thumbnail img {
  margin: 15px 8px 5px 0;
  max-height: 53px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
  display: inline-block;
}

.ps-reviews--3 .ps-review .ps-review__text {
  margin-bottom: 0;
}

.ps-reviews--3 .ps-review .ps-review__author {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.ps-reviews--3 .ps-review .ps-review__image {
  margin-right: 15px;
  display: inline-block;
}

.ps-reviews--3 .ps-review .ps-review__image img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
}

.ps-reviews--3 .ps-review .ps-review__name {
  font-size: 90%;
  color: #222;
  margin-right: 10px;
}

.ps-reviews--3 .ps-review .ps-review__date {
  color: #4d4d4d;
  font-size: 80%;
}

.ps-reviews--3 .ps-section__list {
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.ps-reviews--3 .ps-section__list li {
  border: 1px solid #f0f2f5;
  margin-bottom: 10px;
  border-radius: 4px;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.ps-reviews--3 .ps-section__link {
  color: #333;
  font-size: 18px;
  text-decoration: underline;
  font-weight: 500;
}

@media (min-width: 768px) {
  .ps-reviews--3 .ps-section__list {
    -moz-columns: 2;
    columns: 2;
  }
  .ps-reviews--3 .ps-section__list li {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .ps-reviews--3 .ps-section__list {
    -moz-columns: 3;
    columns: 3;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  .ps-reviews--3 .ps-section__list li {
    margin-bottom: 30px;
  }
}

.ps-section--instagram .ps-section__title {
  color: #103178;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}

.ps-section--instagram .ps-section__content .col-6 {
  padding: 4px;
}

.ps-image--transition {
  position: relative;
  height: 100%;
  display: block;
  overflow: hidden;
}

.ps-image--transition img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 800ms ease 0s;
}

.ps-image--transition .ps-image__overlay {
  font-size: 24px;
  line-height: 1;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ps-image--transition:hover::before {
  background-color: rgba(16, 49, 120, 0.6);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.ps-image--transition:hover .ps-image__overlay {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  z-index: 3;
}

.ps-image--transition:hover img {
  transform: scale(1.1, 1.1);
}

.ps-section--newsletter {
  background-position: center;
  background-repeat: no-repeat;
}

.ps-section--newsletter .ps-section__title {
  margin-bottom: 30px;
  color: #103178;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
}

.ps-section--newsletter .ps-section__text {
  color: #103178;
  margin-bottom: 25px;
  font-size: 22px;
  line-height: 30px;
}

@media (min-width: 1024px) {
  .ps-section--newsletter .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-section--newsletter .ps-section__text {
    font-size: 24px;
  }
}

.ps-section--newsletter-full .ps-section__title {
  color: white;
  text-align: left;
}

.ps-section--newsletter-full .ps-form--subscribe .ps-form__control {
  max-width: 100%;
}

.ps-section--newsletter-full .ps-form--subscribe .ps-input {
  color: #222;
}

.ps-section--newsletter-full .ps-form--subscribe .ps-input::-moz-placeholder {
  color: #222;
}

.ps-section--newsletter-full .ps-form--subscribe .ps-input:-ms-input-placeholder {
  color: #222;
}

.ps-section--newsletter-full .ps-form--subscribe .ps-input::placeholder {
  color: #222;
}

@media (min-width: 768px) {
  .ps-section--newsletter-full .ps-section__content {
    width: 50%;
  }
}

.ps-section--newsletter-info {
  background-size: cover;
}

.ps-section--newsletter-info .ps-section__title {
  text-align: left;
  color: white;
  font-size: 28px;
}

.ps-section--newsletter-info .ps-form--subscribe {
  justify-content: flex-start;
}

.ps-section--newsletter-info .ps-form--subscribe .ps-input {
  color: #333;
}

.ps-section--newsletter-info .ps-form--subscribe .ps-input::-moz-placeholder {
  color: #333;
}

.ps-section--newsletter-info .ps-form--subscribe .ps-input:-ms-input-placeholder {
  color: #333;
}

.ps-section--newsletter-info .ps-form--subscribe .ps-input::placeholder {
  color: #333;
}

@media (min-width: 768px) {
  .ps-section--newsletter-info .ps-section__title {
    font-size: 30px;
  }
}

@media (min-width: 1280px) {
  .ps-section--newsletter-info .ps-section__title {
    font-size: 40px;
  }
}

.ps-section--newsletter-inline {
  background-color: #f5f5f5;
  background-image: none;
  padding: 50px 0;
}

.ps-section--newsletter-inline .ps-section__title {
  color: #333;
  font-size: 20px;
  line-height: 30px;
}

@media (min-width: 992px) {
  .ps-section--newsletter-inline .ps-section__title {
    margin-bottom: 0;
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .ps-section--newsletter-inline .ps-section__title {
    font-size: 25px;
  }
}

.ps-section--categories .ps-section__title {
  color: #103178;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
}

.ps-section--categories .ps-categories__list {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: center;
}

.ps-section--categories .ps-categories__item {
  width: 33.3333%;
  flex-basis: 33.3333%;
  height: 100%;
  padding: 0 15px;
  text-align: center;
}

.ps-section--categories .ps-categories__link {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #f0f2f5;
  background-color: #f0f2f5;
  text-align: center;
}

.ps-section--categories .ps-categories__link img {
  max-width: 30px;
}

/* .ps-section--categories .ps-categories__link:hover {
  background-color: white;
  border-color: #103178;
} */

.ps-section--categories .ps-categories__name {
  line-height: 26px;
  font-size: 16px;
  color: #103178;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  display: block;
}

.ps-section--categories .ps-categories__name:hover {
  color: #fd8d27;
}

.ps-section--categories .ps-categories__show {
  padding: 3px 10px;
  min-width: 90px;
  font-size: 14px;
  background-color: white;
  color: #103178;
  border: 1px solid #f0f2f5;
  border-radius: 40px;
  font-weight: 600;
  display: inline-block;
}

.ps-section--categories .ps-categories__show:hover {
  background-color: #fd8d27;
  color: white;
  border-color: #fd8d27;
}

@media (min-width: 768px) {
  .ps-section--categories .ps-categories__item {
    width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 992px) {
  .ps-section--categories .ps-categories__item {
    width: 14.285%;
    flex-basis: 14.285%;
  }
}

@media (min-width: 1280px) {
  .ps-section--categories .ps-section__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-section--blog {
  overflow: hidden;
}

.ps-section--blog .ps-section__title {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 26px;
  color: #103178;
  text-align: center;
  font-weight: 600;
}

.ps-section--blog .ps-section__carousel {
  margin-left: -15px;
  margin-right: -15px;
}

.ps-section--blog .ps-section__carousel .owl-nav,
.ps-section--blog .ps-section__carousel .owl-dots {
  display: none;
}

.ps-section--blog .ps-section__item {
  padding: 0 15px;
}

.ps-blog .ps-blog__title {
  color: #103178;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 22px;
}

.ps-blog .ps-pagination {
  padding: 10px 0 80px;
}

.ps-blog .ps-blog__content {
  padding-top: 20px;
}

.ps-blog .ps-blog--latset .ps-blog__thumbnail:hover img {
  transform: none;
}

.ps-blog .ps-blog__button {
  text-align: center;
}

.ps-blog .ps-blog__button .ps-btn {
  width: auto;
  padding: 9px 25px;
}

@media (min-width: 768px) {
  .ps-blog .ps-blog__title {
    font-size: 45px;
    line-height: 50px;
  }
}

@media (min-width: 1280px) {
  .ps-blog .ps-blog__title {
    font-size: 50px;
    line-height: 60px;
  }
}

.ps-blog--sidebar .ps-blog--latset {
  margin-bottom: 60px;
}

.ps-blog--sidebar .ps-blog--latset .ps-blog__desc {
  display: block;
}

.ps-blog--sidebar .ps-blog--latset .ps-blog__content {
  padding-bottom: 0;
}

.ps-blog--sidebar .ps-blog--latset .ps-blog__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
}

.ps-blog--sidebar .ps-blog--latset:not(:first-child) {
  padding-top: 60px;
  border-top: 1px solid #f0f2f5;
}

@media (min-width: 1280px) {
  .ps-blog--sidebar .ps-blog--latset .ps-blog__title {
    font-size: 40px;
    line-height: 50px;
  }
}

.ps-blog--gird {
  overflow: hidden;
}

.ps-blog--gird .ps-blog--latset {
  padding: 60px 15px;
  border-top: 1px solid #f0f2f5;
}

.ps-blog--gird .ps-blog--latset .ps-blog__desc {
  display: block;
}

.ps-blog--gird .ps-blog--latset .ps-blog__content {
  padding-bottom: 0;
}

.ps-blog--gird .ps-blog--latset .ps-blog__title {
  font-size: 22px;
  line-height: 26px;
}

@media (min-width: 1280px) {
  .ps-blog--gird .ps-blog--latset .ps-blog__title {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-blog--masonry {
  padding-bottom: 80px;
}

.ps-blog--masonry .ps-blog--latset .ps-blog__title {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 60px;
}

.ps-page--notfound .ps-page__name {
  font-size: 100px;
  line-height: 100px;
  color: #103178;
  margin-bottom: 20px;
}

.ps-page--notfound .ps-page__content {
  text-align: center;
}

.ps-page--notfound .ps-page__content img {
  width: 60%;
  margin: auto;
  display: block;
}

.ps-page--notfound .ps-page__content h5 {
  font-size: 24px;
  color: #103178;
  margin-bottom: 20px;
}

.ps-page--notfound .ps-page__content p {
  color: #5b6c8f;
  margin-bottom: 20px;
  font-size: 14px;
}

.ps-page--notfound .ps-page__content .ps-btn {
  min-width: 220px;
  font-size: 16px;
  width: auto;
  text-transform: initial;
}

.ps-page--notfound .ps-section--latest {
  margin: 80px 0 50px;
}

@media (min-width: 768px) {
  .ps-page--notfound .ps-page__content {
    text-align: left;
  }
  .ps-page--notfound .ps-page__content .row {
    flex-direction: row-reverse;
  }
  .ps-page--notfound .ps-page__content img {
    width: 80%;
  }
  .ps-page--notfound .ps-page__content h5 {
    font-size: 26px;
    line-height: 30px;
  }
  .ps-page--notfound .ps-page__name {
    font-size: 120px;
    line-height: 120px;
  }
}

@media (min-width: 992px) {
  .ps-page--notfound .ps-page__content h5 {
    font-size: 34px;
    line-height: 40px;
  }
  .ps-page--notfound .ps-page__name {
    font-size: 150px;
    line-height: 150px;
  }
}

@media (min-width: 1280px) {
  .ps-page--notfound .ps-page__name {
    font-size: 200px;
    line-height: 200px;
  }
  .ps-page--notfound .ps-page__content h5 {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-page--notfound .ps-page__content p {
    font-size: 24px;
    line-height: 30px;
  }
  .ps-page--notfound .ps-page__content .ps-btn {
    min-width: 270px;
    font-size: 18px;
  }
}

.ps-preloader {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ps-preloader .ps-preloader-section {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 50%;
  background-color: white;
  z-index: 1000;
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ps-preloader .ps-preloader-left {
  left: 0;
  transform: translateX(-100%);
  -webkit-animation: moveleft 0.5s;
  animation: moveleft 0.5s;
}

.ps-preloader .ps-preloader-right {
  right: 0;
  transform: translateX(100%);
  -webkit-animation: moveright 0.5s;
  animation: moveright 0.5s;
}

.ps-preloader .ps-preloader__icon {
  position: relative;
  height: 90px;
  width: 150px;
}

@-webkit-keyframes moveleft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes moveleft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moveright {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes moveright {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
  }
}

.ps-page {
  position: relative;
}

.ps-page--sticky {
  padding-top: 66px;
}

.ps-page--sticky hr {
  margin: 0;
}

@media (min-width: 768px) {
  .ps-page--sticky {
    padding-top: 100px;
  }
}

.ps-page--product1 .ps-document .ps-list li {
  color: #fd8d27;
}

.ps-page--product1 .ps-document .ps-list img {
  filter: invert(60%) sepia(75%) saturate(1000%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

.ps-page--product3 .ps-delivery {
  margin: 30px 0 80px;
}

.ps-page--product4 .ps-section--latest {
  margin-bottom: 50px;
}

.ps-page--product4 .ps-section--latest .owl-nav {
  display: none;
}

@media (min-width: 1280px) {
  .ps-page--product4 .ps-section--latest .owl-nav {
    display: block;
  }
  .ps-page--product4 .ps-section--latest .owl-dots {
    display: none;
  }
}

.ps-page--product5 .ps-product--detail {
  background-color: #f0f2f5;
  padding-top: 40px;
}

.ps-page--product5 .ps-product--detail .ps-product__info {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 40px;
  padding: 30px 20px;
}

.ps-page--product5 .ps-product--detail .ps-product__quantity .number-input {
  margin-bottom: 15px;
}

.ps-page--product5 .ps-product--detail .ps-product__quantity .number-input button {
  padding: 14px;
}

.ps-page--product5 .ps-product--detail .ps-product__group {
  display: flex;
}

.ps-page--product5 .ps-product--detail .ps-product__group h6 {
  margin-right: 15px;
}

.ps-page--product5 .ps-product--detail .ps-product__group a {
  border: 1px solid #f0f2f5;
}

.ps-page--product5 .ps-page__content {
  background-color: white;
  padding-top: 80px;
}

.ps-page--product5 .ps-delivery {
  margin: 80px 0;
}

@media (min-width: 768px) {
  .ps-page--product5 .ps-product--detail .ps-product__info {
    padding: 30px;
  }
  .ps-page--product5 .ps-product--detail .ps-product__quantity .number-input {
    max-width: 40%;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .ps-page--product5 .ps-product--detail .ps-product__info {
    padding: 40px;
  }
}

.ps-page--product6 .ps-footer--banner {
  display: none;
}

.ps-page--product6 .ps-breadcrumb__item a {
  color: #333;
}

.ps-page--product6 .ps-section--latest {
  margin-bottom: 80px;
}

.ps-page--product6 .ps-section--latest .ps-section__title {
  color: #333;
}

.ps-page--product6 .owl-dots .owl-dot.active {
  background-color: #333;
}

.ps-page--product6 .owl-nav button:hover {
  background-color: #333 !important;
}

.ps-page--product6 .ps-product--standard .ps-product__title,
.ps-page--product6 .ps-product--standard .ps-product__del {
  color: #333;
}

.ps-page--product6 .ps-product--standard .ps-product__footer {
  color: #333;
}

.ps-page--product6 .ps-product--standard .ps-product__footer span {
  color: #333;
}

.ps-page--product6 .ps-product--standard .ps-product__percent {
  background-color: #333;
}

.ps-page--product6 .ps-product--standard .ps-product__price {
  color: #333;
}

.ps-page--product6 .ps-product--standard .ps-product__price.sale {
  color: #fd8d27;
}

.ps-page--product6 .ps-product--detail .ps-product__branch,
.ps-page--product6 .ps-product--detail .ps-product__title,
.ps-page--product6 .ps-product--detail .ps-product__rating .ps-product__review,
.ps-page--product6 .ps-product--detail .ps-product__list li,
.ps-page--product6 .ps-product--detail h6,
.ps-page--product6 .ps-product--detail .ps-product__link {
  color: #333;
}

.ps-page--product6 .ps-product--detail .ps-product__price {
  color: #333;
}

.ps-page--product6 .ps-product--detail .ps-product__price.sale {
  color: #fd8d27;
}

.ps-page--product6 .ps-product--detail .ps-product__quantity .number-input button i,
.ps-page--product6 .ps-product--detail .ps-product__quantity .number-input input[type="number"] {
  color: #333;
}

.ps-page--product6 .ps-product--detail .ps-product__size a {
  color: #333;
}

.ps-page--product6 .ps-product--detail .ps-product__size a.active {
  border-color: #333 !important;
}

.ps-page--product6 .ps-product--detail .ps-tab-list li a {
  color: #333;
}

.ps-page--product6 .ps-product--detail .ps-tab-list li a.active {
  border-color: #333;
}

.ps-page--product6 .ps-form--review .ps-form__title,
.ps-page--product6 .ps-form--review .ps-form__desc,
.ps-page--product6 .ps-form--review .ps-form__label {
  color: #333;
}

.ps-page--product6 .ps-review--product .ps-review__name,
.ps-page--product6 .ps-review--product .ps-review__date,
.ps-page--product6 .ps-review--product .ps-review__desc p {
  color: #333;
}

.ps-page--product6 .ps-table th,
.ps-page--product6 .ps-table td {
  color: #333;
}

.ps-page--product6 .ps-product__content .ps-desc {
  color: #333;
}

@media (min-width: 768px) {
  .ps-page--product6 .ps-product--detail .ps-product__feature-group {
    display: flex;
  }
  .ps-page--product6 .ps-product--detail .ps-product__feature-group .ps-product__group {
    margin-right: 30px;
  }
  .ps-page--product6 .ps-product--detail .ps-product__feature-group .ps-product__group:last-child {
    margin-right: 0;
  }
}

.ps-page--product-variable .ps-section--latest {
  background-position: center;
  padding: 70px 0;
  margin-bottom: 30px;
}

.ps-page--product-variable .ps-section--latest .owl-carousel {
  background-color: white;
}

.ps-categogy--promo .ps-section--category-horizontal {
  padding: 30px 0 50px;
}

.ps-categogy--promo .ps-section--category-horizontal .ps-section__title,
.ps-categogy--promo .ps-section--category-horizontal .ps-category__all {
  display: block;
}

.ps-categogy--promo .ps-section--category-horizontal::after {
  display: none;
}

.ps-categogy--promo .ps-section--blog {
  padding: 30px 0 50px;
}

.ps-categogy--promo .ps-branch {
  margin: 30px 0 50px;
}

.ps-categogy--promo .ps-branch .ps-branch__title {
  display: block;
}

.ps-categogy--promo .ps-promo {
  padding-bottom: 50px;
}

.ps-categogy--promo .ps-promo .ps-promo__sale {
  position: absolute;
  top: 50%;
  transform: translate(0, -80%);
  left: 100%;
  font-weight: 600;
  color: #103178;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffc800;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.ps-categogy--promo .ps-promo .ps-promo__price {
  font-weight: 700;
  color: #fd8d27;
}

.ps-categogy--promo .ps-promo .ps-promo__name {
  margin-bottom: 15px;
  font-size: 30px;
}

.ps-categogy--promo .ps-promo .ps-promo__meta {
  margin-bottom: 20px;
}

.ps-categogy--promo .ps-promo .ps-promo__price {
  font-size: 30px;
}

.ps-categogy--promo .ps-promo .ps-promo__btn {
  background-color: #fd8d27;
  border-color: #fd8d27;
  font-size: 16px;
  padding: 7px 40px;
}

.ps-categogy--promo .ps-promo .ps-promo__del {
  font-size: 18px;
}

.ps-categogy--promo .ps-promo .ps-promo__banner {
  width: auto;
}

.ps-categogy--promo .ps-section--deals {
  padding: 30px 0 50px;
}

.ps-categogy--promo .ps-section--featured {
  padding: 30px 0 50px;
}

.ps-categogy--promo .ps-section--newsletter {
  padding: 30px 0 80px;
}

@media (min-width: 768px) {
  .ps-categogy--promo .ps-promo .ps-promo__sale {
    width: 100px;
    height: 100px;
    font-size: 35px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__name {
    font-size: 40px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__del {
    font-size: 21px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__price {
    font-size: 40px;
  }
}

@media (min-width: 1280px) {
  .ps-categogy--promo .ps-promo .ps-promo__sale {
    font-size: 45px;
    width: 133px;
    height: 133px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__price,
  .ps-categogy--promo .ps-promo .ps-promo__name {
    font-size: 50px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__del {
    font-size: 25px;
  }
  .ps-categogy--promo .ps-promo .ps-promo__btn {
    font-size: 18px;
    padding: 9px 40px;
  }
}

.ps-about--info .ps-about__title {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: #103178;
  font-weight: 600;
}

.ps-about--info .ps-about__subtitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
  color: #5b6c8f;
  text-align: center;
  font-weight: 500;
}

.ps-about--info .ps-about__extent .col-12 {
  border-top: 1px solid #f0f2f5;
}

.ps-about--info .ps-about__extent .col-12:first-child {
  border-top: none;
}

.ps-about--info .ps-about__extent .col-12:first-child .ps-block--about {
  padding-top: 0;
}

@media (min-width: 768px) {
  .ps-about--info .ps-about__extent {
    padding-top: 30px;
  }
  .ps-about--info .ps-about__extent .col-12 {
    border-top: none;
    border-right: 1px solid #f0f2f5;
  }
  .ps-about--info .ps-about__extent .col-12 .ps-block--about {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ps-about--info .ps-about__extent .col-12:last-child {
    border-right: none;
  }
}

@media (min-width: 1280px) {
  .ps-about--info .ps-about__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-about--info .ps-about__subtitle {
    font-size: 24px;
    line-height: 30px;
  }
}

.ps-block--about {
  text-align: center;
  padding: 30px 10px;
}

.ps-block--about .ps-block__icon {
  margin-bottom: 20px;
}

.ps-block--about .ps-block__icon img {
  width: 48px;
  transition: 350ms ease;
}

.ps-block--about .ps-block__icon:hover img {
  filter: grayscale(1);
}

.ps-block--about .ps-block__title {
  font-size: 19px;
  line-height: 26px;
  color: #103178;
  font-weight: 600;
}

.ps-block--about .ps-block__subtitle {
  font-size: 16px;
  color: #5b6c8f;
}

@media (min-width: 1280px) {
  .ps-block--about .ps-block__title {
    font-size: 21px;
    line-height: 24px;
  }
}

.ps-about--video {
  border-top: 1px solid #f0f2f5;
}

.ps-about--video .ps-banner {
  height: auto;
}

.ps-about--video .ps-banner .ps-banner__title {
  margin-bottom: 20px;
}

.ps-about--video .ps-banner .ps-banner__desc {
  margin-bottom: 30px;
}

.ps-about--video .ps-banner .ps-banner__btn-group {
  margin-bottom: 30px;
  display: block;
}

.ps-about--video .ps-banner .ps-banner__content {
  padding-top: 0;
  padding-bottom: 0;
}

.ps-about--video .ps-banner .ps-banner__thumnail {
  padding-top: 30px;
  text-align: center;
  position: relative;
}

.ps-about--video .ps-banner .ps-banner__btn {
  display: block;
  color: #5b6c8f;
}

.ps-about--video .ps-banner .ps-banner__btn img {
  filter: invert(40%) sepia(50%) saturate(366%) hue-rotate(182deg) brightness(80%) contrast(89%);
}

.ps-about--video .ps-banner .ps-banner__image {
  height: auto;
  position: initial;
}

.ps-about--video #ps-video-gallery {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -10px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.ps-about--video .ps-banner__video {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.ps-about--video .ps-banner__video i {
  color: #103178;
  font-size: x-large;
  margin-left: 5px;
}

@media (min-width: 768px) {
  .ps-about--video .ps-banner .ps-banner__block {
    display: block;
  }
  .ps-about--video .ps-banner .ps-banner__content {
    width: 100%;
    text-align: center;
  }
  .ps-about--video .ps-banner .ps-banner__image {
    max-width: 380px;
  }
}

@media (min-width: 992px) {
  .ps-about--video .ps-banner .ps-banner__btn-group {
    display: table;
  }
  .ps-about--video .ps-banner .ps-banner__btn {
    display: table-cell;
  }
  .ps-about--video .ps-banner .ps-banner__block {
    display: flex;
    align-items: center;
  }
  .ps-about--video .ps-banner .ps-banner__content {
    text-align: left;
    flex: 0 0 50%;
  }
  .ps-about--video .ps-banner .ps-banner__thumnail {
    flex: 0 0 50%;
  }
  .ps-about--video .ps-banner .ps-banner__image {
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .ps-about--video .ps-banner .ps-banner__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-about--video .ps-banner .ps-banner__btn {
    font-size: 16px;
  }
  .ps-about--video .ps-banner .ps-banner__shop {
    font-size: 18px;
  }
  .ps-about--video .ps-banner__video {
    width: 100px;
    height: 100px;
  }
  .ps-about--video .ps-banner__video i {
    font-size: xx-large;
  }
  .ps-about--video #ps-video-gallery {
    margin-left: -50px;
    margin-top: -30px;
  }
}

.ps-about .ps-about__content {
  overflow: hidden;
}

.ps-about .ps-about__title {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: #103178;
}

.ps-about .ps-about__banner {
  background-size: cover;
  background-position: 60% center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
  padding: 100px 10px;
}

.ps-about .ps-about__banner .ps-banner {
  color: white;
  min-height: 300px;
}

.ps-about .ps-about__banner .ps-banner__title {
  font-size: 30px;
  line-height: 35px;
  color: white;
  font-weight: 600;
  margin-bottom: 25px;
}

.ps-about .ps-about__banner .ps-banner__desc {
  line-height: 32px;
  margin-bottom: 15px;
  font-size: 19px;
  color: white;
}

.ps-about .ps-about__banner .ps-banner__shop {
  font-size: 16px;
  border: 1px solid #103178;
  background-color: #103178;
  color: white;
  font-weight: 500;
  border-radius: 40px;
  padding: 8px 15px;
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.ps-about .ps-about__banner .ps-banner__shop:hover {
  background-color: white;
  color: #103178;
}

.ps-about .ps-banner--round .ps-banner__btn img {
  filter: invert(40%) sepia(50%) saturate(366%) hue-rotate(182deg) brightness(80%) contrast(89%);
}

.ps-about .ps-about__project {
  padding: 30px 0 50px;
}

.ps-about .ps-section--newsletter {
  padding: 30px 0 80px;
}

.ps-about .ps-section--blog {
  padding: 30px 0 50px;
}

.ps-about .ps-section--reviews {
  margin: 30px 0 50px;
  padding: 60px 10px 100px;
}

.ps-about .ps-about--info {
  padding: 30px 0 50px;
}

.ps-about .ps-about--video {
  padding: 50px 0;
}

@media (min-width: 768px) {
  .ps-about .ps-about__banner {
    background-position: right center;
  }
  .ps-about .ps-about__banner .ps-banner__desc {
    font-size: 22px;
  }
  .ps-about .ps-about__banner .ps-banner__title {
    font-size: 50px;
    line-height: 55px;
  }
  .ps-about .ps-about__banner .ps-banner {
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .ps-about .ps-about--info {
    padding: 30px 0 80px;
  }
}

@media (min-width: 992px) {
  .ps-about .ps-about__banner .ps-banner {
    width: 60%;
  }
  .ps-about .ps-section--reviews {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1280px) {
  .ps-about .ps-about__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-about .ps-about__banner .ps-banner {
    width: 45%;
  }
  .ps-about .ps-about__banner .ps-banner__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-about .ps-about__banner .ps-banner__desc {
    font-size: 21px;
  }
  .ps-about .ps-about__banner .ps-banner__shop {
    font-size: 18px;
  }
  .ps-about .ps-section--reviews {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1350px) {
  .ps-about .ps-section--reviews {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.ps-contact {
  padding-bottom: 30px;
}

.ps-contact .ps-contact__content {
  padding: 20px 0 50px;
}

.ps-contact .ps-contact__title {
  font-size: 34px;
  line-height: 40px;
  color: #103178;
  margin-bottom: 20px;
  font-weight: 600;
}

.ps-contact .ps-contact__text {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 25px;
  color: #5b6c8f;
  margin-bottom: 20px;
}

.ps-contact .ps-contact__fax {
  color: #103178;
  font-size: 22px;
  line-height: 26px;
  padding-top: 25px;
  border-top: 1px solid #f0f2f5;
  font-weight: 600;
}

.ps-contact .ps-contact__work {
  color: #5b6c8f;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 24px;
}

.ps-contact .ps-contact__email {
  padding-bottom: 30px;
  border-bottom: 1px solid #f0f2f5;
}

.ps-contact .ps-contact__email a {
  border-radius: 40px;
  color: #103178;
  padding: 8px 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  min-width: 150px;
  display: inline-block;
  border: 1px solid #f0f2f5;
}

.ps-contact .ps-contact__email a:hover {
  color: white;
  background-color: #fd8d27;
  border-color: #fd8d27;
}

.ps-contact .ps-social {
  margin-bottom: 40px;
}

.ps-contact .ps-section--instagram {
  padding: 80px 0 50px;
}

@media (min-width: 960px) {
  .ps-contact .ps-contact__title {
    font-size: 45px;
    line-height: 50px;
  }
  .ps-contact .ps-contact__info {
    padding-right: 10px;
  }
  .ps-contact .ps-contact__map {
    padding-left: 20px;
  }
}

@media (min-width: 1280px) {
  .ps-contact .ps-contact__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-contact .ps-contact__fax {
    font-size: 24px;
    line-height: 30px;
  }
  .ps-contact .ps-contact__email a {
    font-size: 18px;
  }
}

.ps-wishlist .ps-wishlist__title {
  margin-bottom: 30px;
  color: #103178;
  font-size: 26px;
  line-height: 30px;
}

.ps-wishlist .ps-wishlist__list li {
  margin-bottom: 10px;
}

.ps-wishlist .ps-wishlist__table {
  display: none;
}

.ps-wishlist .ps-wishlist__share {
  padding-top: 30px;
  margin-bottom: 100px;
}

.ps-wishlist .ps-wishlist__share label {
  color: #103178;
  font-weight: 600;
  margin-right: 10px;
  font-size: 16px;
}

.ps-wishlist .ps-social--color {
  display: inline-block;
}

.ps-wishlist .ps-social--color .ps-social__email,
.ps-wishlist .ps-social--color .ps-social__whatsapp {
  display: inline-block;
}

.ps-wishlist .ps-social--color .ps-social__linkedin,
.ps-wishlist .ps-social--color .ps-social__reddit {
  display: none;
}

.ps-wishlist .ps-social--color .ps-social__link {
  border-radius: 50%;
}

.ps-wishlist .ps-social--color li {
  margin: 0 2px 8px;
}

@media (min-width: 992px) {
  .ps-wishlist .ps-wishlist__list {
    display: none;
  }
  .ps-wishlist .ps-wishlist__table {
    display: block;
  }
}

@media (min-width: 1280px) {
  .ps-wishlist .ps-wishlist__title {
    font-size: 30px;
    line-height: 40px;
  }
}

.ps-account {
  min-height: 500px;
}

.ps-account .ps-form--review {
  margin: 20px 0 20px;
}

.ps-account .ps-form__group {
  margin-bottom: 25px;
}

.ps-account .ps-form__group .toogle-password {
  min-width: 30px;
  padding-right: 10px;
  background-color: #f0f2f5;
  display: inline-flex;
  align-items: center;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  font-size: 14px;
}

.ps-account .ps-form__title {
  margin-bottom: 30px;
}

.ps-account .ps-account__link {
  display: inline-block;
  margin-top: 25px;
  color: #103178;
  text-decoration: underline;
}

.ps-account .ps-form__label {
  font-weight: 400;
  font-size: 16px;
}

.ps-account .form-check {
  display: inline-block;
  margin-left: 30px;
}

.ps-account .form-check label {
  margin-top: 4px;
}

.ps-account .form-check label::before {
  margin-right: 10px;
  content: "";
}

.ps-account .ps-btn {
  font-weight: 500;
}

.ps-account .ps-form__text {
  color: #103178;
  line-height: 20px;
  font-size: 14px;
  margin-top: 15px;
}

.ps-lost-password .ps-lost-password__content {
  padding-top: 20px;
  min-height: 500px;
}

.ps-lost-password .ps-lost-password__text {
  color: #103178;
  font-size: 16px;
  margin-bottom: 25px;
}

.ps-lost-password .ps-form__group {
  margin-bottom: 25px;
}

.ps-lost-password .ps-form__label {
  font-weight: 400;
  font-size: 16px;
}

.ps-lost-password .ps-btn {
  font-weight: 500;
}

@media (min-width: 960px) {
  .ps-lost-password .ps-form__group {
    width: 50%;
  }
}

.ps-checkout .ps-checkout__title {
  color: #103178;
  font-size: 34px;
  line-height: 40px;
}

.ps-checkout .ps-checkout__text {
  color: #5b6c8f;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
}

.ps-checkout .ps-checkout__text a {
  color: #103178;
  font-weight: 600;
}

.ps-checkout .ps-checkout__wapper {
  margin-bottom: 30px;
}

.ps-checkout .ps-checkout__content {
  padding-top: 40px;
}

.ps-checkout .ps-checkout__group {
  margin-bottom: 20px;
}

.ps-checkout .ps-checkout__label {
  color: #103178;
  margin-bottom: 5px;
  font-size: 14px;
  display: block;
}

.ps-checkout .ps-checkout__heading {
  color: #103178;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
}

.ps-checkout .ps-input,
.ps-checkout .ps-textarea {
  border-radius: 40px;
  height: 44px;
  border: none;
  width: 100%;
  background-color: #f0f2f5;
  color: #5b6c8f;
  font-size: 14px;
  padding: 10px 20px;
}

.ps-checkout .ps-input::-moz-placeholder,
.ps-checkout .ps-textarea::-moz-placeholder {
  color: #5b6c8f;
  font-size: 14px;
}

.ps-checkout .ps-input:-ms-input-placeholder,
.ps-checkout .ps-textarea:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 14px;
}

.ps-checkout .ps-input::placeholder,
.ps-checkout .ps-textarea::placeholder {
  color: #5b6c8f;
  font-size: 14px;
}

.ps-checkout .ps-input:focus,
.ps-checkout .ps-textarea:focus {
  box-shadow: none;
}

.ps-checkout .ps-textarea {
  height: auto;
}

.ps-checkout .ps-checkout__form {
  border: 1px solid #f0f2f5;
  padding: 15px 20px 20px;
  border-radius: 4px;
  margin-bottom: 50px;
}

.ps-checkout .ps-checkout__order {
  border: 3px solid #fd8d27;
  border-radius: 4px;
  padding: 25px 20px 30px;
  margin-bottom: 50px;
}

.ps-checkout .ps-checkout__order .form-check label {
  color: #5b6c8f;
}

.ps-checkout .ps-checkout__row {
  display: flex;
  border-bottom: 1px solid #f0f2f5;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
}

.ps-checkout .toogle-password {
  min-width: 30px;
  padding-right: 10px;
  background-color: #f0f2f5;
  display: inline-flex;
  align-items: center;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  font-size: 14px;
}

.ps-checkout .input-group input {
  width: auto;
  flex-grow: 1;
}

.ps-checkout .ps-label--danger {
  color: #f00000;
}

.ps-checkout .ps-hidden {
  display: none;
}

.ps-checkout .form-check label {
  margin-top: 4px;
  color: #103178;
  font-size: 14px;
}

.ps-checkout .form-check label::before {
  content: "";
  margin-right: 10px;
}

.ps-checkout .form-check label span {
  font-weight: 600;
  color: #103178;
}

.ps-checkout .form-check label img {
  max-width: 100px;
  margin: 0 5px;
}

.ps-checkout .form-check input:focus {
  box-shadow: none;
}

.ps-checkout .ps-title {
  color: #103178;
  font-weight: 600;
  font-size: 14px;
}

.ps-checkout .ps-product__price {
  color: #103178;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  min-width: 100px;
  text-align: right;
}

.ps-checkout .ps-product__name {
  color: #103178;
  font-size: 14px;
}

.ps-checkout .ps-product__name span {
  font-weight: 600;
}

.ps-checkout .ps-checkout__payment {
  padding-top: 15px;
}

.ps-checkout .ps-btn {
  padding: 10px 25px;
  text-transform: initial;
  font-weight: 500;
  height: 44px;
  font-size: 16px;
  margin-top: 15px;
}

.ps-checkout .payment-method {
  margin-bottom: 15px;
  position: relative;
}

.ps-checkout .payment-method .ps-note {
  background-color: #f0f2f5;
  color: #5b6c8f;
  border-radius: 4px;
  padding: 18px 25px;
  margin-top: 10px;
  position: relative;
}

.ps-checkout .payment-method .ps-note::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f0f2f5;
}

.ps-checkout .paypal-method {
  margin-bottom: 15px;
}

.ps-checkout .paypal-method label {
  margin-top: 0;
}

@media (min-width: 767px) {
  .ps-checkout .ps-checkout__form {
    padding: 15px 20px 20px;
  }
}

@media (min-width: 992px) {
  .ps-checkout .ps-checkout__title {
    font-size: 45px;
    line-height: 50px;
  }
}

@media (min-width: 1280px) {
  .ps-checkout .ps-checkout__title {
    font-size: 50px;
    line-height: 60px;
  }
  .ps-checkout .ps-checkout__heading {
    font-size: 30px;
    line-height: 40px;
  }
  .ps-checkout .ps-input {
    font-size: 16px;
    height: 46px;
  }
  .ps-checkout .ps-input:hover {
    font-size: 16px;
  }
  .ps-checkout .ps-checkout__form {
    padding: 25px 30px 30px;
    margin-bottom: 90px;
  }
  .ps-checkout .ps-checkout__order {
    margin-bottom: 90px;
  }
  .ps-checkout .ps-btn {
    height: 46px;
    font-size: 18px;
  }
}

.scroll-top {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 60px;
  right: 20px;
  padding: 0;
}

.scroll-top i {
  color: white;
  font-size: 22px;
  line-height: 40px;
  font-weight: 600;
}

.scroll-top:focus {
  outline: none;
  box-shadow: none;
}

.scroll-top:hover {
  background-color: #103178;
}

@media (min-width: 768px) {
  .scroll-top {
    bottom: 20px;
  }
}

.ps-compare .ps-compare__content {
  padding: 20px 0 40px;
}

.ps-compare--product .ps-compare__table {
  padding: 0;
}

.ps-compare--product .ps-compare__header {
  background-color: #103178;
  padding: 14px 20px;
}

.ps-compare--product .ps-compare__header h2 {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0;
}

.ps-compare--product .ps-table th {
  color: #103178;
  font-weight: 600;
  width: 150px;
  font-size: 14px;
  padding: 20px;
  border-color: #f0f2f5;
}

.ps-compare--product .ps-table td {
  padding: 20px;
  font-size: 14px;
  color: #5b6c8f;
  min-width: 220px;
}

.ps-compare--product .ps-table .ps-product__list li {
  color: #5b6c8f;
  padding: 8px 0;
  font-size: 14px;
}

.ps-compare--product .ps-table .ps-product__list li:first-child {
  padding-top: 0;
}

.ps-compare--product .ps-table .ps-product__list li:last-child {
  padding-bottom: 0;
}

.ps-compare--product .ps-table .ps-product__text {
  color: #5b6c8f;
  font-size: 14px;
  margin-bottom: 0;
}

.ps-compare--product .ps-table .ps-check-line::before {
  content: "\f00c";
  color: #12a05c;
  font-size: 75%;
  display: inline-block;
  font-weight: 900;
  font: normal normal normal 14px/1 FontAwesome;
  margin: -3px 10px 0 0;
}

.ps-compare--product .ps-table .ps-product__price {
  color: #103178;
  font-weight: 600;
  font-size: 18px;
}

.ps-compare--product .ps-table .ps-product__price.sale {
  color: #fd8d27;
}

.ps-compare--product .ps-table .ps-product__del {
  color: #9babcd;
  margin-left: 5px;
  text-decoration: line-through;
  font-weight: 400;
  font-size: 15px;
  margin-left: 0;
  margin-right: 10px;
}

.ps-compare--product .ps-table .ps-product__title {
  line-height: 20px;
  margin-bottom: 12px;
  color: #5b6c8f;
  font-size: 16px;
}

.ps-compare--product .ps-table .ps-product__meta {
  margin-bottom: 12px;
}

.ps-compare--product .ps-table .ps-btn {
  text-transform: initial;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 15px;
  width: auto;
  min-width: 145px;
  margin-bottom: 10px;
}

.ps-compare--product .ps-table .ps-product__thumbnail {
  margin-bottom: 20px;
}

.ps-compare--product .ps-table .ps-product__thumbnail img {
  width: 250px;
}

.ps-compare--product .ps-table .ps-product__thumbnail figure {
  text-align: center;
}

.ps-compare--product .ps-table .ps-product__remove {
  padding: 5px 0 10px;
  text-align: center;
}

.ps-compare--product .ps-table .ps-product__remove a {
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.ps-compare--product .ps-table .ps-product__remove i {
  font-size: 16px;
  line-height: 26px;
  color: #103178;
}

.ps-noti {
  background-color: #103178;
  position: relative;
}

.ps-noti p {
  color: #ffc800;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 35px 8px 0;
  text-align: center;
}

.ps-noti__close {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #ffc800 !important;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.ps-countdown {
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.ps-countdown__content {
  height: 56px;
  margin: 0 -8px;
  display: inline-block;
}

.ps-countdown__number {
  font-size: 22px;
}

.ps-countdown__number span {
  min-width: 30px;
  height: 40px;
  line-height: 40px;
  background-color: #fd8d27;
  color: white;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 1px;
  font-weight: 600;
}

.ps-countdown__number span.first-1st {
  display: none;
}

.ps-countdown__block {
  position: relative;
  margin: 8px;
  float: left;
  width: auto;
}

.ps-countdown__block:not(:last-child)::after {
  content: ":";
  display: inline-block;
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: -12px;
  font-weight: bold;
}

.ps-countdown__ref {
  font-size: 12px;
  text-align: center;
  color: #103178;
  display: none;
}

.ps-social {
  padding: 0;
  margin-top: 30px;
  list-style: none;
}

.ps-social li {
  margin: 5px 9px;
  display: inline-block;
}

.ps-social__link {
  color: #5b6c8f;
  position: relative;
  font-size: 20px;
}

.ps-social__link .ps-tooltip {
  color: #fff;
  position: absolute;
  content: "";
  top: -30px;
  left: 50%;
  margin-left: -30px !important;
  height: 20px;
  opacity: 0;
  visibility: hidden;
  padding: 2px 5px;
  width: 60px;
  font-size: 10px;
  transition: 350ms ease 0s;
  text-align: center;
  line-height: 16px;
  white-space: nowrap;
}

.ps-social__link .ps-tooltip::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  margin-left: -3px;
  border-top: 6px solid #103178;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.ps-social__link.facebook .ps-tooltip {
  background-color: #3b5998;
}

.ps-social__link.facebook .ps-tooltip::before {
  border-top-color: #3b5998;
}

.ps-social__link.instagram .ps-tooltip {
  background-color: #d95766;
}

.ps-social__link.instagram .ps-tooltip::before {
  border-top-color: #d95766;
}

.ps-social__link.youtube .ps-tooltip {
  background-color: #cc181e;
}

.ps-social__link.youtube .ps-tooltip::before {
  border-top-color: #cc181e;
}

.ps-social__link.pinterest .ps-tooltip {
  background-color: #cb2027;
}

.ps-social__link.pinterest .ps-tooltip::before {
  border-top-color: #cb2027;
}

.ps-social__link.linkedin .ps-tooltip {
  background-color: #176dba;
}

.ps-social__link.linkedin .ps-tooltip::before {
  border-top-color: #176dba;
}

.ps-social__link.twitter .ps-tooltip {
  background-color: #55acee;
}

.ps-social__link.twitter .ps-tooltip::before {
  border-top-color: #55acee;
}

.ps-social__link.reddit-alien .ps-tooltip {
  background-color: #f84301;
}

.ps-social__link.reddit-alien .ps-tooltip::before {
  border-top-color: #f84301;
}

.ps-social__link.envelope .ps-tooltip {
  background-color: #fbb102;
}

.ps-social__link.envelope .ps-tooltip::before {
  border-top-color: #fbb102;
}

.ps-social__link.whatsapp .ps-tooltip {
  background-color: #00a901;
}

.ps-social__link.whatsapp .ps-tooltip::before {
  border-top-color: #00a901;
}

.ps-social__link:hover .ps-tooltip {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.ps-social__link:hover.facebook {
  color: #3b5998;
}

.ps-social__link:hover.instagram {
  color: #d95766;
}

.ps-social__link:hover.youtube {
  color: #cc181e;
}

.ps-social__link:hover.pinterest {
  color: #cb2027;
}

.ps-social__link:hover.linkedin {
  color: #176dba;
}

.ps-social__link:hover.whatsapp {
  color: #fbb102;
}

.ps-social__link:hover.envelope {
  color: #00a901;
}

.ps-social--color li {
  margin: 0 4px 8px;
}

.ps-social--color .ps-social__link {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  display: block;
  border-radius: 4px;
}

.ps-social--color .ps-social__link.facebook {
  background-color: #3b5998;
}

.ps-social--color .ps-social__link.pinterest {
  background-color: #cb2027;
}

.ps-social--color .ps-social__link.linkedin {
  background-color: #176dba;
}

.ps-social--color .ps-social__link.twitter {
  background-color: #55acee;
}

.ps-social--color .ps-social__link.reddit-alien {
  background-color: #f84301;
}

.ps-social--color .ps-social__link.whatsapp {
  background-color: #00a901;
}

.ps-social--color .ps-social__link.envelope {
  background-color: #fbb102;
}

.ps-social--color .ps-social__link:hover {
  color: white;
}

.ps-social--color .ps-social__email,
.ps-social--color .ps-social__whatsapp {
  display: none;
}

.sub-toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transform-style: preserve-3d;
  font-size: 10px;
  font-weight: 400;
  width: 15px;
  text-align: right;
}

.mega-menu {
  background-color: #fff;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  z-index: 100;
  box-shadow: 0 5px 5px #f0f2f5;
}

.mega-menu > * {
  width: 100%;
  min-width: 200px;
  padding: 10px 15px;
}

.mega-menu h4 {
  font-weight: 600;
  font-size: 18px;
  color: #103178;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.mega-menu .has-badge {
  background-color: #12a05c;
  color: white;
  font-size: 10px;
  padding: 1px 2px;
  line-height: 14px;
  text-align: center;
  min-width: 35px;
  border-radius: 4px;
  position: absolute;
  left: 70px;
  top: 0;
}

.mega-menu .has-badge::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 6px solid #12a05c;
  border-bottom: 6px solid transparent;
  transform: rotate(15deg);
  position: absolute;
  left: 2px;
  bottom: -5px;
}

.mega-menu .sub-menu--mega {
  background-color: transparent;
}

.mega-menu .sub-menu--mega li a {
  display: block;
  line-height: 24px;
  padding: 5px 5px 5px 0;
  font-size: 15px;
  color: #103178;
  background-color: transparent;
  position: relative;
}

.mega-menu .sub-menu--mega li a:first-letter {
  text-transform: capitalize;
}

.mega-menu .sub-menu--mega li a:hover {
  color: #fd8d27;
}

.mega-menu .sub-menu--bold li a {
  font-weight: 600;
}

.mega-menu .ps-promo {
  margin-bottom: 30px;
}

.mega-menu__link {
  display: block;
}

.mega-menu__thumnail {
  position: relative;
  width: 100%;
  transition: 800ms ease 0s;
}

.mega-menu__thumnail-text {
  position: absolute;
  z-index: 2;
  left: 40px;
  top: 40px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
}

.mega-menu__product {
  border-radius: 4px;
  border: 3px solid #fd8d27;
  padding-top: 20px;
}

.mega-menu__product .ps-countdown__days {
  display: none;
}

.mega-menu .sub-menu--image {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px;
}

.mega-menu .sub-menu--image li {
  min-width: 14.28%;
  padding: 5px 5px 10px;
  position: relative;
}

.mega-menu .sub-menu--image li .image-title {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin: 20px 0 25px;
  color: #103178;
}

.mega-menu .sub-menu--image li a {
  display: block;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f0f2f5;
}

.mega-menu .sub-menu--image li img {
  max-width: calc(100% + 2px);
  width: calc(100% + 2px);
  margin-right: -1px;
  transition: 350ms ease;
}

.mega-menu .sub-menu--image li .has-badge {
  top: -20px;
  left: auto;
  right: 10px;
  font-size: 12px;
  padding: 2px 10px;
}

.mega-menu .sub-menu--image li .has-badge::before {
  border-top-width: 5px;
  border-left-width: 10px;
  border-bottom-width: 10px;
  bottom: -7px;
}

.mega-menu .product-list .col-12 {
  border: 1px solid #f0f2f5;
}

.mega-menu .product-list .col-12:not(:first-child) {
  border-left: none;
}

.mega-menu__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-width: 460px;
  margin-left: -15px;
  margin-right: -15px;
}

.mega-menu__column {
  padding-left: 15px;
  padding-right: 15px;
  min-width: 120px;
}

.mega-menu .ps-branch {
  margin-top: 45px;
}

@media (min-width: 1200px) {
  .mega-menu {
    transition: all 0.4s ease;
  }
}

@media (min-width: 1280px) {
  .mega-menu .sub-menu--mega li a {
    font-size: 16px;
  }
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 240px;
  z-index: 1000;
  transition: all 0.25s ease;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 0.95);
  visibility: hidden;
  opacity: 0;
}

.sub-menu:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  display: none;
}

.sub-menu > li {
  display: block;
}

.sub-menu > li > a {
  display: inline-block;
  padding: 10px 20px;
  width: 100%;
  font-size: 17px;
  color: #103178;
  text-transform: capitalize;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.sub-menu > li > a:hover {
  color: #103178;
}

.sub-menu > li.menu-item-has-children {
  position: relative;
}

.sub-menu > li.menu-item-has-children > .sub-toggle {
  display: none;
}

.sub-menu > li.menu-item-has-children > .sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
}

.sub-menu > li.menu-item-has-children > .sub-menu:before {
  display: none;
}

.sub-menu > li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.menu > li {
  display: inline-block;
  padding-right: 5px;
}

.menu > li > a {
  position: relative;
  display: inline-block;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #103178;
  text-transform: capitalize;
  transform-style: preserve-3d;
}

.menu > li > a:hover {
  color: #fd8d27;
}

.menu > li > a > i {
  margin-right: 10px;
}

.menu > li:first-child {
  padding-left: 0;
}

.menu > li:last-child {
  padding-right: 0;
}

.menu > li.menu-item-has-children {
  position: relative;
}

.menu > li.menu-item-has-children .sub-toggle {
  margin-left: 5px;
  display: none;
}

.menu > li.menu-item-has-children .sub-menu {
  visibility: hidden;
  opacity: 0;
  transform: scale3d(1, 1, 0) translateY(30px);
}

.menu > li.menu-item-has-children:hover .sub-toggle {
  color: #ffffff;
}

.menu > li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: scale3d(1, 1, 1) translateY(0);
}

.menu > li.has-mega-menu .mega-menu {
  position: absolute;
  top: 99%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transform: scale3d(1, 1, 0) translateY(30px);
}

.menu > li.has-mega-menu:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  transform: scale3d(1, 1, 1) translateY(0);
}

@media (min-width: 992px) {
  .menu > li {
    padding-right: 15px;
  }
  .menu > li > a {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .menu > li > a {
    font-size: 17px;
  }
}

.menu--mobile .sub-menu {
  position: relative;
  display: none;
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
  transition: all 0s ease;
  border: none;
  margin: 15px 0 10px 30px;
}

.menu--mobile .sub-menu li {
  position: relative;
  padding: 10px 0;
}

.menu--mobile .sub-menu li a {
  font-weight: 600;
  padding: 0;
}

.menu--mobile .sub-menu li:first-child {
  padding-top: 0;
}

.menu--mobile .sub-menu li.active > .sub-toggle {
  transform: rotate(180deg);
}

.menu--mobile .sub-menu .sub-menu a {
  font-size: 15px;
  font-weight: 400;
}

.menu--mobile .sub-toggle {
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  z-index: 20;
  display: inline-block;
}

.menu--mobile > li {
  position: relative;
  padding: 10px 0;
}

.menu--mobile > li > a {
  position: relative;
  display: block;
  line-height: 20px;
  font-weight: 600;
  font-size: 17px;
  color: #103178;
  text-transform: capitalize;
}

.menu--mobile > li:first-child {
  padding-top: 0;
}

.menu--mobile > li.active > .sub-toggle {
  transform: rotate(180deg);
}

.ps-menu--slidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  transform-style: preserve-3d;
  transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  overflow-y: auto;
  background-color: white;
  padding: 40px 35px;
  display: none;
}

.ps-menu--slidebar.active {
  display: block;
}

.ps-menu--slidebar .ps-menu__footer {
  margin-bottom: 60px;
}

.ps-menu--slidebar .ps-menu__item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #f0f2f5;
  color: #5b6c8f;
}

.ps-menu--slidebar .ps-language-currency {
  display: table;
}

.ps-menu--slidebar .ps-language-currency > li {
  display: table-cell;
  padding-right: 25px;
  font-size: 14px;
  position: relative;
  min-width: 100px;
}

.ps-menu--slidebar .ps-language-currency > li a {
  color: #5b6c8f;
}

.ps-menu--slidebar .ps-language-currency > li.active .sub-toggle {
  transform: rotate(180deg);
}

.ps-menu--slidebar .ps-language-currency .sub-menu {
  position: relative;
  display: none;
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
  transition: all 0s ease;
  border: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.ps-menu--slidebar .ps-language-currency .sub-menu li a {
  color: #5b6c8f;
  font-size: 14px;
  padding: 5px 10px;
}

@media (min-width: 768px) {
  .ps-menu--slidebar {
    display: none;
  }
}

.menu-top .nav-item {
  display: inline-block;
  line-height: 32px;
  border-right: 1px solid #d9dee8;
}

.menu-top .nav-item a {
  font-size: 14px;
  padding: 0 15px;
  color: #5b6c8f;
}

.menu-top .nav-item:last-child {
  border-right: none;
}

.menu-top .nav-item:first-child a {
  padding-left: 0;
}

.ps-landing {
  background-color: #f0f2f5;
}

.ps-landing--block {
  padding-bottom: 80px;
}

.ps-landing--block .ps-landing__title {
  color: #103178;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}

.ps-landing--block .ps-landing__content {
  overflow: hidden;
}

.ps-landing--block .ps-landing__item {
  margin-left: -35px;
  margin-right: -35px;
  padding-bottom: 20px;
}

.ps-landing--block .ps-landing__item img {
  width: 100%;
}

.ps-landing--block .ps-landing__item.no-margin {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 40px;
}

.ps-landing--block .ps-landing__item.no-margin .ps-landing__text {
  margin-top: 25px;
}

.ps-landing--block .ps-landing__text {
  text-align: center;
  margin-top: -35px;
  margin-bottom: 0;
  padding: 0 15px;
}

.ps-landing--block .ps-landing__text a {
  color: #103178;
  font-weight: 600;
  font-size: 14px;
}

@media (min-width: 768px) {
  .ps-landing--block .ps-landing__title {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .ps-landing--block .col-lg-2dot4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1280px) {
  .ps-landing--block .ps-landing__title {
    font-size: 40px;
  }
  .ps-landing--block .ps-landing__text {
    font-size: 15px;
  }
}

.ps-landing--footer {
  background: linear-gradient(180deg, #0f3484 0%, #4b6cb3 100%);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.ps-landing--footer .ps-landing__round {
  position: absolute;
  right: 0;
  top: 0;
}

.ps-landing--footer .ps-landing__title {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
}

.ps-landing--footer .ps-landing__button {
  text-align: center;
}

.ps-landing--footer .ps-landing__button .ps-btn {
  width: auto;
  font-size: 16px;
}

.ps-landing--footer .ps-landing__content {
  text-align: center;
}

.ps-landing--footer .ps-landing__content img {
  width: 100%;
}

@media (min-width: 768px) {
  .ps-landing--footer .ps-landing__title {
    font-size: 50px;
  }
  .ps-landing--footer .ps-landing__button .ps-btn {
    font-size: 18px;
    padding: 14px 50px;
  }
  .ps-landing--footer .ps-landing__content img {
    width: 70%;
  }
}

@media (min-width: 1280px) {
  .ps-landing--footer .ps-landing__title {
    font-size: 60px;
  }
  .ps-landing--footer .ps-landing__button .ps-btn {
    padding: 16px 50px;
    font-size: 21px;
  }
  .ps-landing--footer .ps-landing__content img {
    width: 50%;
  }
}

.ps-portfolio {
  padding-bottom: 80px;
}

.ps-portfolio .ps-portfolio__title {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 30px;
  color: #103178;
  font-weight: 600;
}

.ps-portfolio .nav-tabs {
  border: none;
  margin: 10px 0;
}

.ps-portfolio .nav-tabs li {
  margin: 0 35px 20px 0;
}

.ps-portfolio .nav-tabs li a {
  font-size: 22px;
  line-height: 26px;
  color: #103178;
  padding: 0;
  border: none;
  position: relative;
}

.ps-portfolio .nav-tabs li a::after {
  content: "";
  width: 0;
  height: 2px;
  background: #103178;
  transition: all 0.35s ease 0s;
  position: absolute;
  left: 0;
  bottom: 0;
}

.ps-portfolio .nav-tabs li a:hover::after,
.ps-portfolio .nav-tabs li a.active::after {
  width: 100%;
}

.ps-portfolio .ps-blog--latset {
  margin-bottom: 60px;
}

.ps-portfolio .ps-blog--latset .ps-blog__title {
  font-size: 18px;
}

.ps-portfolio .ps-portfolio__button {
  text-align: center;
}

.ps-portfolio .ps-portfolio__button .ps-btn {
  padding: 9px 25px;
  width: auto;
}

@media (min-width: 1280px) {
  .ps-portfolio .nav-tabs > li a {
    font-size: 24px;
  }
  .ps-portfolio .ps-portfolio__title {
    font-size: 50px;
    line-height: 60px;
  }
}

.ps-portfolio--detail {
  padding-bottom: 30px;
}

.ps-portfolio--detail .ps-section--blog {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ps-portfolio--detail .ps-section--blog .ps-section__title {
  font-size: 30px;
  line-height: 40px;
}

.ps-portfolio--detail .ps-portfolio__thunmnail {
  padding-bottom: 80px;
}

.ps-portfolio--detail .ps-portfolio__thunmnail img {
  border-radius: 4px;
  width: 100%;
}

.ps-portfolio--detail .ps-portfolio__thunmnail a {
  display: block;
  margin-bottom: 10px;
}

.ps-portfolio--detail .ps-portfolio__content {
  padding-bottom: 80px;
}

.ps-portfolio--detail .ps-portfolio__title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 30px;
  color: #103178;
  font-weight: 600;
}

.ps-portfolio--detail .ps-portfolio__subtitle {
  font-size: 20px;
  color: #103178;
  margin-bottom: 30px;
  line-height: 30px;
}

.ps-portfolio--detail .ps-portfolio__des {
  margin-bottom: 30px;
  font-size: 16px;
  color: #5b6c8f;
}

.ps-portfolio--detail .ps-portfolio__item {
  font-size: 16px;
  color: #103178;
}

.ps-portfolio--detail .ps-portfolio__label {
  font-weight: 600;
}

.ps-portfolio--detail .ps-portfolio__text {
  margin-left: 10px;
}

.ps-portfolio--detail .ps-portfolio__text a {
  text-decoration: underline;
}

.ps-portfolio--detail .ps-review {
  background-color: #f0f2f5;
  margin-bottom: 30px;
}

@media (min-width: 1280px) {
  .ps-portfolio--detail .ps-section--blog .ps-section__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-portfolio--detail .ps-portfolio__title {
    font-size: 40px;
    line-height: 50px;
  }
  .ps-portfolio--detail .ps-portfolio__subtitle {
    font-size: 24px;
  }
}

.ps-coming {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
}

.ps-coming .container {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}

.ps-coming .ps-logo {
  position: absolute;
  top: 30px;
  left: 15px;
}

.ps-coming .ps-logo img {
  max-width: 144px;
}

.ps-coming__title {
  color: white;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 20px;
}

.ps-coming__text {
  font-size: 24px;
  margin-bottom: 24px;
  color: white;
}

.ps-coming .ps-countdown {
  text-align: left;
}

.ps-coming .ps-countdown__block::after {
  color: white;
}

.ps-coming .ps-countdown__ref {
  display: block;
  color: white;
  font-size: 14px;
  margin-top: 8px;
}

.ps-coming .ps-countdown__content {
  height: 80px;
}

.ps-coming .ps-countdown__number {
  font-size: 20px;
}

.ps-coming .ps-countdown__number span {
  min-width: 26px;
}

@media (min-width: 768px) {
  .ps-coming__title {
    font-size: 70px;
    line-height: 80px;
  }
  .ps-coming .ps-countdown__number span {
    min-width: 30px;
    height: 44px;
    line-height: 44px;
  }
  .ps-coming .ps-countdown__content {
    height: 100px;
  }
  .ps-coming .ps-countdown__block:not(:last-child)::after {
    top: 6px;
  }
}

@media (min-width: 1280px) {
  .ps-coming .ps-countdown__number {
    font-size: 26px;
  }
  .ps-coming .ps-countdown__number span {
    min-width: 40px;
    height: 60px;
    line-height: 60px;
  }
  .ps-coming .ps-countdown__block:not(:last-child)::after {
    top: 15px;
  }
  .ps-coming .ps-logo img {
    max-width: 220px;
  }
}

.ps-coming--v1 .ps-coming__title,
.ps-coming--v1 .ps-coming__text {
  color: #103178;
}

.ps-coming--v1 .ps-countdown__block::after {
  color: #103178;
}

.ps-coming--v1 .ps-countdown__ref {
  display: none;
}

/*4. MODULES */
.ps-header {
  background-color: white;
  display: none;
}

.ps-header .ps-header__middle {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.ps-header .ps-header__middle .container {
  display: table;
}

.ps-header .ps-logo,
.ps-header .ps-header__right,
.ps-header .ps-menu--sticky {
  display: table-cell;
  vertical-align: middle;
}

.ps-header .ps-header__menu .menu > li {
  margin-right: 10px;
}

.ps-header .ps-header__menu .menu > li:first-child > a {
  color: white;
  background-color: #103178;
  border-radius: 40px;
  padding: 10px 25px;
}

.ps-header .ps-header__menu .menu > li .sub-toggle {
  display: none;
}

.ps-header .ps-middle__text {
  color: #103178;
  font-size: 15px;
  display: inline-flex;
  float: right;
  height: 46px;
  align-items: center;
  margin-right: 15px;
}

.ps-header .ps-middle__text strong {
  margin-left: 5px;
}

.ps-header .ps-header__top {
  padding: 5px 0;
  border-bottom: 1px solid #f0f2f5;
}

.ps-header .ps-header__text {
  line-height: 30px;
  font-size: 16px;
  color: #5b6c8f;
}

.ps-header .ps-header__text strong {
  font-weight: 500;
}

.ps-header .ps-logo {
  height: 80px;
  padding-right: 50px;
}

.ps-header .ps-logo img {
  max-width: 216px;
}

.ps-header .ps-logo .sticky-logo {
  display: none;
}

.ps-header .ps-top__menu {
  float: right;
}

.ps-header .ps-middle__social {
  display: inline-block;
  float: right;
}

.ps-header .ps-middle__social .ps-social li {
  margin: 5px;
}

.ps-header .ps-menu--sticky {
  display: none;
  height: 46px;
  color: #103178;
  font-size: 22px;
  text-align: center;
  position: absolute;
  right: 15px;
  width: 46px;
  top: 25px;
  z-index: 10;
}

.ps-header .ps-header__search {
  min-width: 500px;
  display: none;
}

.ps-header .ps-language-currency {
  display: inline-flex;
  padding-right: 25px;
  margin-right: 10px;
  position: relative;
  float: right;
}

.ps-header .ps-language-currency::after {
  content: "";
  width: 1px;
  height: 26px;
  background-color: #d9dee8;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -13px;
}

.ps-header .ps-language-currency .ps-dropdown-value {
  font-size: 14px;
  color: #5b6c8f;
  margin-right: 20px;
  padding: 12px 0;
}

.ps-header .ps-language-currency .ps-dropdown-value:last-child {
  margin-right: 0;
}

.ps-header .ps-language-currency .ps-dropdown-value:hover {
  color: #fd8d27;
}

.ps-header .ps-language-currency .ps-dropdown-value::after {
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 9px;
  margin-left: 10px;
}

.ps-header .ps-header__icons {
  display: inline-block;
  padding: 0;
  margin: 0;
  float: right;
}

.ps-header .ps-header__icons li {
  display: inline-block;
  position: relative;
}

.ps-header .ps-header__icons .ps-header__item {
  position: relative;
  width: 46px;
  height: 46px;
  font-size: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fd8d27;
}

.ps-header .ps-header__icons .ps-header__item .badge {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  font-size: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #103178;
  color: white;
}

.ps-header .ps-header__icons .ps-header__item i {
  font-weight: 500;
}

.ps-header .ps-header__icons .ps-header__item:hover {
  color: #103178;
}

.ps-header .header__search-mini {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.ps-header .ps-top__social {
  float: right;
}

.ps-header .ps-top__social .ps-social__link {
  font-size: 14px;
}

.ps-header .ps-top__social .ps-social li {
  margin: 0 8px;
  line-height: 32px;
}

.ps-header .ps-header__nav {
  display: inline-block;
  padding: 0;
  margin: 0;
  float: right;
}

.ps-header .ps-header__nav .has-mega-menu:nth-child(6),
.ps-header .ps-header__nav .has-mega-menu:nth-child(7),
.ps-header .ps-header__nav .has-mega-menu:nth-child(8) {
  display: none;
}

.ps-header .ps-header__nav .has-mega-menu:nth-child(5) {
  padding-right: 0;
}

.ps-header .ps-header__nav .has-mega-menu:first-child {
  margin-right: 25px;
  color: white;
}

.ps-header .ps-header__nav .has-mega-menu:first-child > a {
  color: white;
  padding: 8px 25px 7px;
  background-color: #103178;
  border-radius: 40px;
}

.ps-header.header--mobile {
  display: flex;
}

.ps-header.ps-header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.ps-header.ps-header--sticky .ps-noti,
.ps-header.ps-header--sticky .ps-navigation {
  display: none;
}

.ps-header.ps-header--sticky .ps-logo img {
  display: none;
}

.ps-header.ps-header--sticky .ps-logo .sticky-logo {
  display: block;
  width: 144px;
}

.ps-header.ps-header--sticky .ps-header__right {
  padding-right: 60px;
}

.ps-header.ps-header--sticky .ps-menu--sticky {
  display: table-cell;
}

.ps-header.ps-header--sticky .ps-header__middle {
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 4px 2px -2px #f0f2f5;
}

.ps-header.ps-header--sticky .ps-header__top {
  display: none;
}

.ps-header.ps-header--sticky::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 15px;
  width: 100%;
  box-shadow: 0 4px 2px -2px #f0f2f5;
}

.ps-header .ps-navigation {
  position: relative;
  border-top: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
}

.ps-header .ps-navigation .container {
  display: table;
}

.ps-header .ps-navigation__right,
.ps-header .ps-navigation__left {
  vertical-align: middle;
  display: table-cell;
  line-height: 30px;
}

.ps-header .ps-navigation__right {
  line-height: 30px;
  text-align: right;
  font-size: 16px;
  color: #5b6c8f;
  display: none;
  padding: 10px 0;
}

.ps-header .ps-navigation__right strong {
  font-weight: 500;
}

.ps-header .ps-top__right .ps-header__text {
  display: none !important;
}

@media (min-width: 768px) {
  .ps-header {
    display: block;
  }
}

@media (min-width: 1280px) {
  .ps-header .ps-header__top {
    display: none;
  }
  .ps-header .ps-header__search {
    display: inline-block;
    float: left;
  }
  .ps-header.ps-header--sticky .ps-header__right {
    padding-right: 0;
  }
  .ps-header .ps-menu--sticky {
    width: 65px;
    padding-right: 20px;
    position: initial;
  }
  .ps-header .ps-navigation__right {
    display: table-cell;
  }
  .ps-header .ps-top__right .menu-top {
    text-align: right;
  }
  .ps-header .ps-top__right .menu-top .nav-item:last-child {
    border-right: 1px solid #d9dee8;
  }
  .ps-header .ps-top__right .ps-top__social {
    border-right: 1px solid #d9dee8;
    padding: 0 10px;
  }
  .ps-header .ps-language-currency {
    padding-right: 40px;
    margin-right: 30px;
  }
  .ps-header .ps-header__icons .open-search {
    display: none;
  }
}

.ps-header--1.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--1 .ps-header__search {
    margin-left: 30px;
  }
}

.ps-header--mobile {
  display: block;
  border-bottom: 1px solid #f0f2f5;
}

.ps-header--mobile .ps-logo {
  height: 66px;
}

.ps-header--mobile .ps-logo img {
  width: 144px;
  display: block;
}

.ps-header--mobile .ps-header__middle {
  padding: 0 !important;
}

.ps-header--mobile.ps-header--sticky .ps-logo img {
  display: block;
}

.ps-header--mobile .ps-header__right .ps-header__item {
  color: #103178;
}

.ps-header--mobile .ps-header__left {
  display: table-cell;
  vertical-align: middle;
}

@media (min-width: 480px) {
  .ps-header--mobile .ps-logo img {
    width: 160px;
  }
}

@media (min-width: 768px) {
  .ps-header--mobile {
    display: none;
  }
}

.ps-search--result {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 50;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 50px;
  width: 100%;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.ps-search--result .ps-result__content {
  width: 100%;
  margin: auto;
}

.ps-search--result .ps-result__content .col-12 {
  margin-bottom: 30px;
}

.ps-search--result .ps-result__viewall {
  text-align: center;
  margin-top: 20px;
}

.ps-search--result .ps-result__viewall a {
  font-size: 18px;
  line-height: 26px;
  color: #103178;
  text-decoration: underline;
  font-weight: 600;
}

.ps-search--result.active {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1279px) {
  .ps-search--result .ps-result__content {
    max-width: 1270px;
  }
}

.ps-header--2 .ps-header__top {
  display: block;
  padding: 0;
  background-color: #f0f2f5;
}

.ps-header--2 .ps-header__top .container {
  display: table;
}

.ps-header--2 .ps-header__top .ps-header__text,
.ps-header--2 .ps-header__top .ps-top__right {
  display: table-cell;
}

.ps-header--2 .ps-header__top .ps-header__text {
  display: none;
  font-size: 14px;
  width: 32%;
}

.ps-header--2 .ps-logo {
  height: 56px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ps-header--2 .ps-logo img {
  height: 36px;
}

.ps-header--2 .ps-language-currency {
  float: right;
  padding-right: 0;
  margin-right: 0;
}

.ps-header--2 .ps-language-currency::after {
  display: none;
}

.ps-header--2 .ps-language-currency .ps-dropdown-value {
  padding: 7px 15px 4px;
}

.ps-header--2 .ps-language-currency .ps-dropdown-value:first-child {
  border-right: 1px solid #d9dee8;
  margin-right: 0;
}

.ps-header--2 .ps-language-currency .ps-dropdown-value:last-child {
  padding-right: 0;
}

.ps-header--2 .ps-header__middle {
  padding: 0;
}

.ps-header--2 .ps-header__middle .container {
  padding-bottom: 64px;
}

.ps-header--2 .ps-header__middle .ps-header__search {
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
}

.ps-header--2 .ps-header__middle .ps-middle__text {
  margin-right: 20px;
}

.ps-header--2 .ps-menu--sticky {
  top: 15px;
}

.ps-header--2 .ps-navigation {
  border-bottom: none;
}

.ps-header--2.ps-header--sticky .ps-header__middle .container {
  padding-bottom: 0;
}

.ps-header--2.ps-header--sticky .ps-header__middle .ps-header__search {
  display: none;
}

.ps-header--2 .has-mega-menu:nth-child(9) {
  display: none;
}

@media (min-width: 960px) {
  .ps-header--2 .has-mega-menu:nth-child(9) {
    display: inline-block;
  }
}

@media (min-width: 1280px) {
  .ps-header--2 .ps-header__top .ps-header__text {
    display: table-cell;
  }
  .ps-header--2 .ps-header__middle .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ps-header--2 .ps-header__middle .ps-header__search {
    display: inline-block;
    width: auto;
    min-width: 360px;
    position: initial;
    float: right;
    margin-right: 60px;
  }
  .ps-header--2 .ps-logo {
    height: 80px;
  }
  .ps-header--2 .ps-logo img {
    height: 54px;
  }
  .ps-header--2.ps-header--sticky .ps-logo .sticky-logo {
    height: 36px;
  }
  .ps-header--2.ps-header--sticky .ps-header__middle {
    padding: 0;
  }
  .ps-header--2.ps-header--sticky .ps-header__middle .ps-header__search {
    display: table-cell;
  }
  .ps-header--2.ps-header--sticky .ps-menu--sticky {
    position: absolute;
    padding-right: 0;
    right: 0;
    top: 35px;
  }
  .ps-header--2.ps-header--sticky .container {
    padding-right: 60px;
  }
}

.ps-header--2.ps-header--mobile .ps-header__search {
  min-width: 50%;
}

.ps-header--2.ps-header--mobile .ps-logo {
  padding-right: 10px;
}

.ps-header--2.ps-header--mobile .ps-search--result {
  width: 100vw;
  left: -15px;
  z-index: 50;
  top: 110%;
}

.ps-header--2.ps-header--mobile .ps-language-currency .ps-dropdown-value:first-child {
  border-right: none;
}

.ps-header--2 .has-mega-menu:last-child {
  display: none;
}

.ps-header--3 .ps-noti {
  background-color: white;
  border-bottom: 1px solid #d9dee8;
}

.ps-header--3 .ps-noti .ps-noti__close i {
  color: #103178;
}

.ps-header--3 .ps-noti p {
  text-align: left;
  color: #103178;
}

.ps-header--3 .ps-header__top {
  display: block;
  background-color: #103178;
  padding: 0;
}

.ps-header--3 .ps-header__top .container {
  display: table;
}

.ps-header--3 .ps-header__top .ps-top__right {
  display: table-cell;
  color: white;
}

.ps-header--3 .ps-header__top .ps-top__right .ps-header__text {
  display: inline-block !important;
  float: right;
}

.ps-header--3 .ps-header__top .ps-header__text {
  font-size: 14px;
  color: white;
  display: none;
  padding-right: 15px;
}

.ps-header--3 .ps-header__top .ps-language-currency {
  display: none;
}

.ps-header--3 .ps-top__social {
  display: none;
}

.ps-header--3 .ps-top__social .ps-social__link {
  color: white;
}

.ps-header--3 .menu-top {
  float: right;
}

.ps-header--3 .menu-top .nav-item {
  border-color: #284686;
}

.ps-header--3 .menu-top .nav-item:last-child {
  border-color: #284686 !important;
}

.ps-header--3 .menu-top .nav-item a {
  color: white;
}

.ps-header--3 .menu-top .nav-item:first-child a {
  padding-left: 15px;
  border-left: 1px solid #284686;
}

.ps-header--3 .ps-header__search {
  min-width: 340px;
}

.ps-header--3 .ps-header__search form {
  width: 100%;
}

.ps-header--3 .ps-header__middle {
  padding-bottom: 0;
}

.ps-header--3 .ps-header__middle .container {
  position: initial;
}

.ps-header--3 .ps-header__middle .ps-header__menu,
.ps-header--3 .ps-header__middle .ps-header__search {
  display: none;
}

.ps-header--3 .ps-header3-search-mobile .ps-header__menu,
.ps-header--3 .ps-header3-search-mobile .ps-header__search,
.ps-header--3 .ps-header3-search-mobile .ps-menu__sticky {
  display: table-cell;
  vertical-align: middle;
}

.ps-header--3 .ps-header3-search-mobile .ps-search__content {
  display: table;
  position: initial;
  padding: 0;
}

.ps-header--3 .ps-header3-search-mobile .ps-header__menu {
  width: 20%;
}

.ps-header--3 .ps-header3-search-mobile .ps-menu__sticky {
  width: 32%;
  display: none;
}

.ps-header--3 .ps-header3-search-mobile .ps-menu__sticky a {
  color: white;
  font-size: 14px;
  font-weight: 500;
  background-color: #103178;
  border-radius: 40px;
  padding: 10px 15px;
  display: inline-block;
}

.ps-header--3 .ps-header3-search-mobile .ps-menu__sticky a i {
  color: white;
  margin-right: 5px;
}

.ps-header--3 .ps-header3-search-mobile .menu > li {
  padding: 10px 0 20px;
}

.ps-header--3 .ps-logo {
  padding-right: 0;
}

.ps-header--3 .ps-logo img {
  max-width: 144px;
}

.ps-header--3 .ps-language-currency {
  padding-right: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.ps-header--3.ps-header--sticky .ps-header3-search-mobile,
.ps-header--3.ps-header--sticky .ps-middle__social,
.ps-header--3.ps-header--sticky .ps-menu--sticky {
  display: none;
}

.ps-header--3.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

.ps-header--3.ps-header--sticky .ps-header__middle .ps-header__menu {
  float: right;
  display: table-cell;
}

@media (min-width: 1280px) {
  .ps-header--3 .ps-logo {
    padding-right: 50px;
  }
  .ps-header--3 .ps-logo img {
    max-width: 216px;
  }
  .ps-header--3 .ps-middle__social {
    display: none;
  }
  .ps-header--3 .ps-language-currency {
    padding-right: 40px;
    margin-right: 30px;
  }
  .ps-header--3 .ps-top__right .ps-top__social {
    display: block;
    border-right: none;
    padding-right: 0;
  }
  .ps-header--3 .ps-header__middle .container {
    padding-bottom: 0;
  }
  .ps-header--3 .ps-header__middle .ps-header__menu,
  .ps-header--3 .ps-header__middle .ps-header__search {
    display: inline-flex;
    float: left;
  }
  .ps-header--3 .ps-header__middle .ps-header3-search-mobile {
    display: none;
  }
}

.ps-header--3.ps-header--mobile .ps-header3-search-mobile .ps-header__search {
  min-width: 180px;
}

.ps-header--3.ps-header--mobile .ps-header3-search-mobile .ps-header__menu {
  display: none !important;
}

.ps-header--3.ps-header--mobile .ps-header3-search-mobile .ps-search__content {
  padding-top: 0;
}

.ps-header--3.ps-header--mobile .ps-header__right .ps-header__item {
  color: #fd8d27;
}

.ps-header--4 .ps-header__right .ps-header__item {
  color: #fd8d27;
}

.ps-header--4 .ps-header__left .ps-header__item {
  color: #103178;
}

.ps-header--4 .ps-header__left .ps-header__icons {
  float: left;
}

.ps-header--4 .ps-logo {
  padding-right: 0;
}

.ps-header--4 .ps-logo a {
  display: block;
}

.ps-header--4 .ps-logo a img {
  margin: auto;
}

.ps-header--4 .ps-noti {
  border-bottom: 1px solid #d9dee8;
  background-color: white;
}

.ps-header--4 .ps-noti__close,
.ps-header--4 .ps-noti p {
  color: #103178;
}

.ps-header--4 .ps-noti p {
  text-align: left;
}

.ps-header--4 .ps-header__middle .ps-header__menu {
  display: table-cell;
  float: right;
  margin-right: 5px;
}

.ps-header--4 .ps-header__middle .menu > li,
.ps-header--4 .ps-header__middle .ps-header__icons {
  padding: 18px 0;
}

.ps-header--4 .ps-header__middle .ps-language-currency {
  padding: 18px 15px 18px 0;
}

@media (min-width: 1280px) {
  .ps-header--4 .ps-header__middle .ps-header__search {
    margin-right: 60px;
    display: table-cell;
    float: right;
  }
  .ps-header--4 .ps-header__middle .ps-header__menu {
    margin-right: 0;
  }
  .ps-header--4 .ps-header__middle .ps-header__menu .menu > li {
    padding: 5px 25px;
  }
}

.ps-header--5 {
  background-color: #103178;
}

.ps-header--5 .ps-noti {
  border-bottom: 1px solid #284686;
}

.ps-header--5 .ps-noti p {
  text-align: left;
  color: #fd8d27;
}

.ps-header--5 .ps-navigation,
.ps-header--5 .ps-header__top {
  border-color: #284686;
}

.ps-header--5 .ps-header__text,
.ps-header--5 .ps-middle__text {
  color: white;
}

.ps-header--5 .ps-middle__text {
  display: none;
}

.ps-header--5 .ps-logo {
  height: 76px;
}

.ps-header--5 .ps-logo img {
  height: 36px;
  width: auto;
}

.ps-header--5 .ps-header__middle {
  padding: 0;
}

.ps-header--5 .menu > li::before {
  color: white;
}

.ps-header--5 .menu > li > a {
  color: white;
}

.ps-header--5 .ps-header__icons > li:first-child {
  display: none;
}

.ps-header--5 .ps-header__icons .ps-header__item .badge {
  background-color: #fd8d27;
  color: white;
}

.ps-header--5 .ps-language-currency .ps-dropdown-value {
  color: #9babcd;
}

.ps-header--5 .ps-header__search {
  min-width: 240px;
  max-width: 275px;
  margin-right: 0;
}

.ps-header--5 .ps-navigation__right {
  color: #9babcd;
}

.ps-header--5.ps-header--sticky .ps-menu--sticky {
  display: block;
  position: absolute;
  right: 0;
  right: 15px;
  color: white;
}

.ps-header--5.ps-header--sticky .ps-header__icons > li:first-child {
  display: inline-block;
}

.ps-header--5.ps-header--mobile .ps-header__top {
  background-color: white;
  display: block;
}

.ps-header--5.ps-header--mobile .ps-header__top .ps-header__icons {
  float: initial;
  display: flex;
}

.ps-header--5.ps-header--mobile .ps-header__top .ps-header__icons > li {
  display: table-cell;
  vertical-align: middle;
  line-height: 32px;
  text-align: center;
  flex: 1;
}

.ps-header--5.ps-header--mobile .ps-header__top .ps-header__icons > li:first-child #close-menu-top {
  display: none;
}

.ps-header--5.ps-header--mobile .ps-header__top .ps-header__icons > li.active #close-menu-top {
  display: inline-flex;
}

.ps-header--5.ps-header--mobile .ps-header__top .ps-header__icons > li.active #open-menu-top {
  display: none;
}

.ps-header--5.ps-header--mobile.slidebar-active {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.ps-header--5.ps-header--mobile.slidebar-active .ps-header__middle,
.ps-header--5.ps-header--mobile.slidebar-active .ps-noti {
  display: none;
}

.ps-header--5.ps-header--mobile.slidebar-active .ps-header__top {
  border-color: #f0f2f5;
}

.ps-header--5.ps-header--mobile .ps-language-currency {
  margin-right: 0;
  padding-right: 0;
}

.ps-header--5.ps-header--mobile .ps-language-currency::after {
  display: none;
}

@media (min-width: 1280px) {
  .ps-header--5 .ps-logo {
    height: 80px;
  }
  .ps-header--5 .ps-logo img {
    height: 54px;
  }
  .ps-header--5 .ps-middle__text {
    display: inline-flex;
    float: left;
    margin-right: 20px;
  }
  .ps-header--5 .ps-header__block {
    display: table-cell;
    vertical-align: middle;
  }
  .ps-header--5 .ps-header__middle {
    padding: 10px 0;
  }
  .ps-header--5.ps-header--sticky .ps-menu--sticky {
    display: table-cell;
    position: initial;
  }
  .ps-header--5.ps-header--sticky .ps-header__icons > li:first-child {
    display: none;
  }
  .ps-header--5.ps-header--sticky .ps-logo img {
    height: 36px;
  }
}

.ps-header--6 .ps-header__middle {
  padding-top: 0;
  padding-bottom: 10px;
}

.ps-header--6 .ps-header__middle .ps-header__menu {
  display: table-cell;
  float: right;
  margin-right: 5px;
}

.ps-header--6 .ps-header__middle .ps-header__menu .menu > li {
  padding: 3px 25px;
}

.ps-header--6 .ps-header__middle .ps-header__menu .menu > li .mega-menu {
  top: 48%;
}

.ps-header--6 .ps-noti {
  background-color: white;
  border-bottom: 1px solid #f0f2f5;
}

.ps-header--6 .ps-noti p,
.ps-header--6 .ps-noti .ps-noti__close {
  color: #103178;
}

.ps-header--6 .ps-noti p {
  text-align: left;
}

.ps-header--6 .ps-header3-search-mobile .ps-header__menu {
  display: none;
}

.ps-header--6 .ps-header3-search-mobile .ps-search__content {
  padding-top: 0;
}

.ps-header--6 .ps-logo {
  height: 76px;
}

.ps-header--6.ps-header--mobile .ps-header__right .ps-header__item {
  color: #fd8d27;
}

.ps-header--6.ps-header--mobile .ps-header__left .ps-header__icons {
  float: left;
}

.ps-header--6.ps-header--mobile .ps-logo {
  padding-right: 0;
  text-align: center;
}

.ps-header--6.ps-header--mobile .ps-logo img {
  margin: auto;
}

.ps-header--6.ps-header--mobile .ps-header__search {
  display: block;
  min-width: 100%;
  margin-bottom: 15px;
}

.ps-header--6.ps-header--mobile.ps-header--sticky .ps-header__search {
  display: none;
}

.ps-header--6.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--6 .ps-logo {
    height: 80px;
  }
  .ps-header--6 .ps-header__middle {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .ps-header--6 .ps-header__middle .ps-header__search {
    margin-right: 60px;
    display: table-cell;
    float: right;
  }
  .ps-header--6 .ps-header__middle .ps-header__menu {
    margin-right: 0;
  }
  .ps-header--6 .ps-header__middle .ps-header__menu .menu > li {
    padding: 5px 25px;
  }
  .ps-header--6 .ps-header__middle .ps-header__menu .menu > li .mega-menu {
    top: 99%;
  }
}

.ps-header--7 {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: transparent;
}

.ps-header--7 .ps-header__top {
  background-color: #103178;
}

.ps-header--7 .ps-header__top .ps-header__text {
  color: white;
  display: table-cell;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.ps-header--7 .ps-header__top .ps-top__right {
  text-align: right;
}

.ps-header--7 .menu-top .nav-item {
  border-color: #284686 !important;
}

.ps-header--7 .menu-top .nav-item a {
  color: white;
}

.ps-header--7 .ps-top__right .ps-top__social {
  border-color: #284686;
  display: none;
}

.ps-header--7 .ps-top__right .ps-social__link {
  color: white;
}

.ps-header--7 .ps-top__right .ps-language-currency {
  display: none;
}

.ps-header--7 .ps-top__right .ps-dropdown-value {
  color: white;
  border-color: #284686 !important;
}

.ps-header--7 .ps-noti {
  background-color: white;
}

.ps-header--7 .ps-noti p {
  color: #103178;
}

.ps-header--7 .ps-header__nav .menu > li {
  display: none;
  padding: 12px 0;
}

.ps-header--7 .ps-header__nav .menu > li:first-child {
  display: inline-block;
}

.ps-header--7 .ps-header__icons {
  padding: 10px 0;
}

.ps-header--7 .ps-header__icons .open-search {
  display: inline-flex;
}

.ps-header--7 .ps-menu--sticky {
  color: #fd8d27;
}

.ps-header--7 .ps-header__middle {
  background-color: transparent;
}

.ps-header--7 .ps-header__middle .ps-language-currency {
  padding: 15px 10px 10px 0;
}

.ps-header--7 .ps-header__middle .ps-language-currency .ps-language {
  border: none;
}

.ps-header--7 .ps-header__middle .ps-language-currency .ps-currency {
  border-right: 1px solid #d9dee8;
  padding-right: 5px;
}

.ps-header--7 .ps-header__middle .container {
  position: initial;
  padding-bottom: 0;
}

.ps-header--7 .ps-navigation {
  display: none;
}

.ps-header--7.ps-header--sticky {
  background-color: white;
}

.ps-header--7.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--7.ps-header--sticky .ps-header__middle .container {
    padding-right: 15px;
  }
  .ps-header--7.ps-header--sticky .ps-menu--sticky {
    display: none;
  }
  .ps-header--7 .ps-header__nav {
    float: left;
  }
  .ps-header--7 .ps-header__nav .menu > li {
    display: inline-block;
    margin-right: 15px !important;
  }
  .ps-header--7 .ps-header__icons {
    padding: 17px 0 16px;
  }
  .ps-header--7 .ps-logo {
    padding-top: 5px;
  }
  .ps-header--7 .ps-header__middle .ps-language-currency {
    display: none;
  }
  .ps-header--7 .ps-navigation {
    display: none !important;
  }
  .ps-header--7 .ps-header__top .ps-header__text {
    width: 32%;
  }
  .ps-header--7 .ps-header__top .ps-top__right {
    text-align: left;
  }
  .ps-header--7 .ps-header__top .ps-top__right .ps-language-currency,
  .ps-header--7 .ps-header__top .ps-top__right .ps-top__social {
    display: inline-flex;
  }
}

.ps-header--8 .ps-navigation {
  border-top: none;
  border-bottom: 1px solid #f0f2f5;
}

.ps-header--8 .has-mega-menu:last-child {
  display: inline-block;
}

.ps-header--8 .has-mega-menu:nth-child(8),
.ps-header--8 .has-mega-menu:nth-child(9) {
  display: none;
}

.ps-header--8 .ps-noti p {
  text-align: left;
}

.ps-header--8 .ps-header__top .ps-top__right {
  text-align: right;
}

.ps-header--8 .ps-language-currency {
  text-align: left;
}

.ps-header--8 .ps-header__middle .ps-header__search {
  display: none;
  margin-right: 0;
}

.ps-header--8 .ps-header__middle .container {
  padding-bottom: 0;
}

.ps-header--8 .ps-logo {
  width: 25%;
}

.ps-header--8.ps-header--mobile .ps-header__item {
  color: #fd8d27;
}

.ps-header--8.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--8 .ps-logo {
    width: 28%;
  }
  .ps-header--8 .ps-header__middle .ps-header__search {
    display: inline-block;
    float: left;
    min-width: 590px;
  }
  .ps-header--8 .ps-header__middle .ps-middle__text {
    display: none;
  }
  .ps-header--8.ps-header--sticky .container {
    padding-right: 15px;
  }
  .ps-header--8.ps-header--sticky .ps-menu--sticky {
    position: initial;
    text-align: left;
  }
  .ps-header--8.ps-header--sticky .ps-header__right {
    padding-left: 30px;
  }
}

.ps-header--9 {
  background-color: transparent;
  position: relative;
}

.ps-header--9 .ps-noti {
  background-color: white;
}

.ps-header--9 .ps-noti p,
.ps-header--9 .ps-noti .ps-noti__close i {
  color: #222;
}

.ps-header--9 .ps-header__top {
  background-color: #222;
  border-color: #222;
}

.ps-header--9 .ps-header__top .ps-header__text,
.ps-header--9 .ps-header__top a {
  color: white;
}

.ps-header--9 .ps-header__top .nav-item,
.ps-header--9 .ps-header__top .ps-language,
.ps-header--9 .ps-header__top .ps-top__social,
.ps-header--9 .ps-header__top .ps-dropdown-value {
  border-color: #555555 !important;
}

.ps-header--9 .ps-header__top .ps-top__right {
  text-align: right;
}

.ps-header--9 .ps-header__top .ps-language-currency,
.ps-header--9 .ps-header__top .ps-top__social {
  display: none;
}

.ps-header--9 .ps-header__top .ps-header__text {
  display: table-cell;
  width: 55%;
}

.ps-header--9 .ps-language-currency .ps-dropdown-value {
  color: white;
}

.ps-header--9 .ps-header__icons {
  padding: 6px 0;
}

.ps-header--9 .ps-header__icons .open-search {
  display: inline-flex;
}

.ps-header--9 .ps-header__icons .ps-header__item {
  color: white;
}

.ps-header--9 .ps-header__icons .ps-header__item .badge {
  background-color: white;
  color: #222;
}

.ps-header--9 .ps-header__middle {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ps-header--9 .ps-header__middle .container {
  padding-bottom: 0;
  padding-top: 0;
}

.ps-header--9 .ps-header__middle .ps-language-currency {
  padding-right: 20px;
  margin-right: 10px;
  padding: 6px 25px 6px 0;
}

.ps-header--9 .ps-header__middle .ps-language-currency::after {
  display: block;
  margin-top: -10px;
}

.ps-header--9 .ps-header__middle .ps-language-currency .ps-dropdown-value {
  border: none;
}

.ps-header--9 .ps-logo img {
  display: none;
}

.ps-header--9 .ps-logo .logo-white {
  display: inline-block;
}

.ps-header--9 .ps-header__menu .menu > li {
  display: inline-block !important;
  padding: 6px 0;
}

.ps-header--9 .ps-header__menu .menu > li > a {
  background-color: transparent !important;
}

.ps-header--9 .mega-menu {
  box-shadow: none;
}

.ps-header--9 .mega-menu h4 {
  color: #222;
}

.ps-header--9 .mega-menu .sub-menu--mega li a {
  color: #222;
}

.ps-header--9.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

.ps-header--9.ps-header--sticky .ps-header__middle {
  background-color: white;
}

.ps-header--9.ps-header--sticky .ps-header__middle .ps-language-currency {
  display: none;
}

.ps-header--9.ps-header--sticky .ps-header__icons .ps-header__item {
  color: #222;
}

.ps-header--9.ps-header--sticky .ps-logo .logo-white {
  display: none;
}

.ps-header--9.ps-header--sticky .ps-header__menu .menu > li > a {
  color: #fd8d27;
}

.ps-header--9.ps-header--sticky.ps-header--mobile .ps-header__middle .container {
  padding-right: 15px;
}

.ps-header--9.ps-header--sticky.ps-header--mobile .ps-header__right {
  padding-right: 0;
}

@media (min-width: 768px) {
  .ps-header--9.ps-header--sticky .ps-header__icons .ps-header__item {
    color: #fd8d27;
  }
  .ps-header--9.ps-header--sticky .ps-header__icons .ps-header__item .badge {
    background-color: #222;
    color: white;
  }
}

@media (min-width: 1280px) {
  .ps-header--9 .ps-header__top .ps-top__right {
    text-align: left;
  }
  .ps-header--9 .ps-header__top .ps-language-currency,
  .ps-header--9 .ps-header__top .ps-top__social {
    display: inline-flex;
  }
  .ps-header--9 .ps-header__top .ps-header__text {
    width: 32%;
  }
  .ps-header--9 .ps-header__middle .ps-language-currency {
    display: none;
  }
  .ps-header--9 .ps-header__middle .container {
    padding-right: 15px;
    position: initial;
  }
  .ps-header--9 .ps-logo {
    width: 25%;
  }
  .ps-header--9 .ps-header__menu .menu > li {
    display: inline-block;
    background-color: transparent;
  }
  .ps-header--9.ps-header--sticky .ps-header__middle {
    padding: 0;
  }
  .ps-header--9.ps-header--sticky .ps-header__menu .menu > li::before,
  .ps-header--9.ps-header--sticky .ps-header__menu .menu > li > a {
    color: #222;
  }
}

.ps-header--10 {
  position: relative;
}

.ps-header--10 .ps-noti {
  background-color: white;
}

.ps-header--10 .ps-noti p,
.ps-header--10 .ps-noti .ps-noti__close i {
  color: #103178;
}

.ps-header--10 .ps-header__top {
  background-color: #103178;
  border-color: #103178;
}

.ps-header--10 .ps-logo img {
  display: inline-block;
}

.ps-header--10 .ps-logo img.logo-white {
  display: none;
}

.ps-header--10 .ps-header__menu .menu > li {
  display: inline-block !important;
  padding: 6px 0;
}

.ps-header--10 .ps-header__menu .menu > li > a {
  color: #103178 !important;
  background-color: transparent !important;
}

.ps-header--10 .ps-header__top .ps-header__text,
.ps-header--10 .ps-header__top a {
  color: white;
}

.ps-header--10 .ps-header__top .nav-item,
.ps-header--10 .ps-header__top .ps-language,
.ps-header--10 .ps-header__top .ps-top__social,
.ps-header--10 .ps-header__top .ps-dropdown-value {
  border-color: #555555 !important;
}

.ps-header--10 .ps-header__top .ps-top__right {
  text-align: right;
}

.ps-header--10 .ps-header__top .ps-language-currency,
.ps-header--10 .ps-header__top .ps-top__social {
  display: none;
}

.ps-header--10 .ps-header__top .ps-header__text {
  display: table-cell;
  width: 55%;
}

.ps-header--10 .ps-header__icons {
  padding: 5px 0;
}

.ps-header--10 .ps-header__icons .ps-header__item {
  color: #103178;
}

.ps-header--10 .ps-header__icons .ps-header__item .badge {
  background-color: #103178;
  color: white;
}

.ps-header--10 .ps-header__middle {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  width: 100%;
}

.ps-header--10 .ps-header__middle .container {
  padding-bottom: 0;
  padding-right: 15px;
  position: initial;
}

.ps-header--10 .ps-header__middle .ps-language-currency {
  padding: 12px 0;
  margin-right: 10px;
}

.ps-header--10.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

.ps-header--10.ps-header--sticky .ps-header__middle {
  background-color: white;
  padding: 0;
}

.ps-header--10.ps-header--sticky .ps-header__middle .container {
  padding-top: 0;
}

.ps-header--10.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--10 .ps-header__top .ps-language-currency,
  .ps-header--10 .ps-header__top .ps-top__social {
    display: inline-block;
  }
  .ps-header--10 .ps-header__top .ps-language-currency {
    padding-top: 6px;
  }
  .ps-header--10 .ps-header__top .ps-header__text {
    width: 32%;
  }
  .ps-header--10 .ps-language-currency .ps-dropdown-value {
    color: white;
  }
  .ps-header--10 .ps-header__middle .ps-language-currency {
    display: none;
  }
  .ps-header--10 .ps-logo {
    width: 25%;
  }
  .ps-header--10 .ps-header__menu .menu > li {
    display: inline-block;
    background-color: transparent;
  }
}

.ps-header--11 {
  position: relative;
}

.ps-header--11 .ps-noti p,
.ps-header--11 .ps-noti .ps-noti__close i {
  color: #ffc800;
}

.ps-header--11 .ps-header__middle {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  width: 100%;
  padding-bottom: 0;
}

.ps-header--11 .ps-header__middle .container {
  padding-bottom: 0;
  position: initial;
}

.ps-header--11 .ps-header__icons {
  padding: 16px 0;
}

.ps-header--11 .ps-header__icons .open-search {
  display: inline-flex;
  color: #103178;
}

.ps-header--11 .ps-logo img {
  max-width: 200px;
}

.ps-header--11 .ps-language-currency {
  padding: 18px 25px 17px 0;
}

.ps-header--11 .ps-header__menu .menu > li {
  background-color: transparent;
  margin-right: 15px;
  display: none;
  padding: 18px 0;
}

.ps-header--11 .ps-header__menu .menu > li:first-child {
  display: inline-block;
}

.ps-header--11 .ps-header__menu .menu > li > a {
  color: #103178 !important;
  padding: 10px 0 !important;
  background-color: transparent !important;
}

.ps-header--11.ps-header--sticky .ps-header__middle {
  background-color: white;
  padding: 0;
}

.ps-header--11.ps-header--sticky .ps-header__middle .container {
  padding-top: 0;
}

.ps-header--11.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 768px) {
  .ps-header--11 .ps-logo {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .ps-header--11 .ps-header__menu .menu > li {
    display: inline-block;
  }
  .ps-header--11 .ps-logo img {
    max-width: 216px;
  }
}

.ps-header--12 {
  position: relative;
}

.ps-header--12 .ps-header__icons .ps-header__item {
  color: #333;
}

.ps-header--12 .ps-header__icons .ps-header__item .badge {
  background-color: #00605a;
}

.ps-header--12 .ps-language-currency .ps-dropdown-value {
  color: #333;
}

.ps-header--12 .ps-header__menu .menu > li::before,
.ps-header--12 .ps-header__menu .menu > li > a {
  color: #333 !important;
}

.ps-header--12 .ps-header__middle {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  width: 100%;
}

.ps-header--12 .mega-menu .mega-menu__column h4 {
  color: #333;
}

.ps-header--12 .mega-menu .sub-menu--mega > li > a {
  color: #333;
}

.ps-header--12 .mega-menu .image-title {
  color: #333;
}

.ps-header--12.ps-header--sticky.ps-header--mobile .ps-header__right {
  padding-right: 0;
}

.ps-header--12.ps-header--sticky .ps-header__middle {
  background-color: white;
}

.ps-header--12.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

.ps-header--13 .ps-noti {
  background-color: #00a198;
}

.ps-header--13 .ps-noti p,
.ps-header--13 .ps-noti .ps-noti__close i {
  color: white;
}

.ps-header--13 .ps-navigation {
  border-bottom: none;
}

.ps-header--13 .ps-navigation .menu > li::before,
.ps-header--13 .ps-navigation .menu > li > a {
  color: #333;
}

.ps-header--13 .ps-navigation .mega-menu .mega-menu__column h4 {
  color: #333;
}

.ps-header--13 .ps-navigation .mega-menu .sub-menu--mega > li > a {
  color: #333;
}

.ps-header--13 .ps-navigation .mega-menu .image-title {
  color: #333;
}

.ps-header--13 .ps-navigation .ps-navigation__right {
  color: #333;
}

.ps-header--13 .ps-navigation .ps-product--standard .ps-product__title {
  color: #333;
}

.ps-header--13 .ps-navigation .ps-product--standard .ps-product__price {
  color: #333;
}

.ps-header--13 .ps-navigation .ps-product--standard .ps-product__price.sale {
  color: #fd8d27;
}

.ps-header--13 .ps-search-table .input-group-append i {
  color: #333;
}

.ps-header--13 .ps-search-table .ps-input {
  color: #333;
}

.ps-header--13 .ps-search-table .ps-input::-moz-placeholder {
  color: #333;
}

.ps-header--13 .ps-search-table .ps-input:-ms-input-placeholder {
  color: #333;
}

.ps-header--13 .ps-search-table .ps-input::placeholder {
  color: #333;
}

.ps-header--13 .ps-language-currency .ps-dropdown-value,
.ps-header--13 .ps-menu--sticky {
  color: #333;
}

.ps-header--13 .ps-header__icons .ps-header__item {
  color: #333;
}

.ps-header--13 .ps-header__icons .ps-header__item .badge {
  background-color: #00605a;
}

.ps-header--13.ps-header--mobile .ps-header__left .ps-header__icons {
  float: left;
}

.ps-header--13.ps-header--mobile .ps-logo {
  padding-right: 0;
}

.ps-header--13.ps-header--mobile .ps-logo img {
  margin: auto;
}

.ps-header--13.ps-header--mobile.ps-header--sticky .ps-header__right {
  padding-right: 0;
}

@media (min-width: 1280px) {
  .ps-header--13 .ps-header__search {
    margin-left: 30px;
  }
}

.ps-header--14 {
  position: relative;
}

.ps-header--14 .ps-header__icons .ps-header__item {
  color: #333;
}

.ps-header--14 .ps-header__icons .ps-header__item .badge {
  background-color: #00605a;
}

.ps-header--14 .ps-language-currency .ps-dropdown-value {
  color: #333;
}

.ps-header--14 .ps-language-currency::after {
  background-color: #bdbdbd;
}

.ps-header--14 .ps-header__menu .menu > li::before,
.ps-header--14 .ps-header__menu .menu > li > a {
  color: #333 !important;
}

.ps-header--14 .ps-header__middle {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  width: 100%;
}

.ps-header--14 .mega-menu .mega-menu__column h4 {
  color: #333;
}

.ps-header--14 .mega-menu .sub-menu--mega > li > a {
  color: #333;
}

.ps-header--14 .mega-menu .image-title {
  color: #333;
}

.ps-header--14.ps-header--sticky.ps-header--mobile .ps-header__right {
  padding-right: 0;
}

.ps-header--14.ps-header--sticky .ps-header__middle {
  background-color: white;
}

.ps-navigation--footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  background-color: white;
  border-top: 1px solid #f0f2f5;
  display: flex;
  padding: 5px 0;
}

.ps-navigation--footer .ps-nav__item {
  flex: 1;
  text-align: center;
  font-size: 22px;
}

.ps-navigation--footer .ps-nav__item a {
  color: #fd8d27;
  display: inline-flex;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ps-navigation--footer .ps-nav__item i {
  font-weight: 600;
}

.ps-navigation--footer .ps-nav__item .badge {
  position: absolute;
  top: 4px;
  right: 2px;
  width: 18px;
  height: 18px;
  font-size: 9px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #103178;
  color: white;
}

.ps-navigation--footer .ps-nav__item #close-menu {
  display: none;
}

.ps-navigation--footer .ps-nav__item.active #close-menu {
  display: inline-flex;
}

.ps-navigation--footer .ps-nav__item.active #open-menu {
  display: none;
}

@media (min-width: 768px) {
  .ps-navigation--footer {
    display: none;
  }
}

.ps-footer {
  padding-bottom: 55px;
}

.ps-footer__middle {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .ps-footer {
    padding-bottom: 0;
  }
}

.ps-footer--block .ps-block__title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  color: #103178;
  font-weight: 600;
}

.ps-footer--block .ps-block__list {
  margin-bottom: 35px;
}

.ps-footer--block .ps-block__list li {
  padding: 6px 0;
  font-size: 16px;
}

.ps-footer--block .ps-block__list li a {
  display: block;
  color: #5b6c8f;
}

.ps-footer--block .ps-block__list li a:hover {
  color: #fd8d27;
}

.ps-footer--bottom {
  margin-top: 10px;
  padding: 20px 0;
  border-top: 1px solid #f0f2f5;
}

.ps-footer--bottom p {
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #103178;
}

.ps-footer--bottom .payment-light {
  display: none;
}

@media (min-width: 768px) {
  .ps-footer--bottom p {
    margin: 0;
  }
}

.ps-footer--top {
  margin-bottom: 50px;
}

.ps-footer--top .row {
  border: 1px solid #f0f2f5;
}

.ps-footer--top p {
  margin-bottom: 0;
  padding: 10px 15px;
}

.ps-footer--top .col-12:not(:last-child) {
  border-bottom: 1px solid #f0f2f5;
}

.ps-footer--top .ps-footer__link {
  color: #103178;
  font-weight: 600;
  font-size: 16px;
}

.ps-footer--top .ps-footer__link i {
  margin-right: 10px;
  font-weight: 600;
}

.ps-footer--top .ps-footer__link:hover {
  color: #fd8d27;
}

@media (min-width: 768px) {
  .ps-footer--top .col-12:not(:last-child) {
    border-right: 1px solid #f0f2f5;
    border-bottom: none;
  }
}

.ps-footer--address p {
  font-size: 16px;
  line-height: 26px;
  color: #5b6c8f;
}

.ps-footer--address p a {
  text-decoration: underline;
  font-size: 14px;
}

.ps-footer--address .ps-logo {
  margin-bottom: 30px;
}

.ps-footer--address .ps-logo img {
  max-width: 144px;
}

.ps-footer--address .ps-logo .logo-white,
.ps-footer--address .ps-logo .logo-black,
.ps-footer--address .ps-logo .logo-white-all,
.ps-footer--address .ps-logo .logo-green {
  display: none;
}

.ps-footer--address .ps-footer__title {
  display: none;
  margin-bottom: 20px;
  color: #103178;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 1024px) {
  .ps-footer--address .ps-logo img {
    max-width: 144px;
  }
}

.ps-footer--contact {
  padding-top: 40px;
}

.ps-footer--contact .ps-footer__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  color: #103178;
}

.ps-footer--contact .ps-footer__fax {
  margin-bottom: 5px;
  line-height: 36px;
  font-size: 22px;
  font-weight: 600;
  color: #103178;
}

.ps-footer--contact .ps-footer__fax i {
  color: #fd8d27;
  margin-right: 15px;
  font-size: 20px;
  font-weight: bold;
}

.ps-footer--contact .ps-footer__work {
  margin-bottom: 18px;
  padding-left: 40px;
  font-size: 14px;
  color: #5b6c8f;
}

.ps-footer--contact hr {
  margin-bottom: 18px;
}

.ps-footer--contact .ps-footer__email {
  font-weight: bold;
  color: #103178;
  font-size: 16px;
}

.ps-footer--contact .ps-footer__email i {
  margin-right: 15px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .ps-footer--contact {
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .ps-footer--contact .ps-footer__fax {
    font-size: 24px;
  }
  .ps-footer--contact .ps-footer__fax i {
    font-size: 22px;
  }
}

.ps-footer--banner .ps-promo__banner {
  min-height: 200px;
  border-radius: 0;
  -o-object-position: left;
  object-position: left;
}

.ps-footer--banner .ps-promo__name {
  color: white;
  font-size: 40px;
}

.ps-footer--banner .ps-promo__desc {
  color: white;
  font-size: 20px;
}

@media (min-width: 768px) {
  .ps-footer--banner .ps-promo__name {
    font-size: 50px;
  }
  .ps-footer--banner .ps-promo__desc {
    font-size: 22px;
  }
}

@media (min-width: 1280px) {
  .ps-footer--banner .ps-promo__name {
    font-size: 60px;
  }
  .ps-footer--banner .ps-promo__desc {
    font-size: 24px;
  }
}

.ps-footer--1 .ps-footer--top .row {
  background-color: #f0f2f5;
}

.ps-footer--1 .ps-footer--top .col-12 {
  border-color: rgba(16, 49, 120, 0.1);
}

.ps-footer--1 .ps-footer--address {
  border-right: 1px solid #f0f2f5;
}

.ps-footer--1 .ps-footer--contact {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .ps-footer--1 .ps-footer--contact {
    padding-bottom: 0;
  }
}

.ps-footer--2 .ps-logo {
  display: none;
}

.ps-footer--2 .ps-footer__title {
  display: block;
}

.ps-footer--2 .ps-footer--contact .ps-footer__fax i {
  display: none;
}

.ps-footer--2 .ps-footer--contact .ps-footer__work {
  padding-left: 0;
  margin-bottom: 27px;
}

.ps-footer--2 .ps-footer--contact hr {
  display: none;
}

.ps-footer--2 .ps-footer--contact .ps-footer__email {
  border-radius: 40px;
  min-width: 150px;
  display: inline-block;
  padding: 4px 25px;
  border: 1px solid #f0f2f5;
  background-color: white;
}

.ps-footer--2 .ps-footer--contact .ps-footer__email i {
  display: none;
}

.ps-footer--2 .ps-footer--top {
  border: 1px solid #f0f2f5;
}

.ps-footer--2 .ps-footer--top .row {
  border: none;
}

@media (min-width: 960px) {
  .ps-footer--2 .ps-footer__category {
    padding-bottom: 30px;
  }
}

.ps-footer--newsletter .ps-footer__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #103178;
  font-weight: 600;
}

.ps-footer--newsletter p {
  font-size: 16px;
  line-height: 26px;
  color: #5b6c8f;
  margin-bottom: 20px;
}

@media (min-width: 1280) {
  .ps-footer--newsletter .ps-footer__title {
    font-size: 30px;
    line-height: 40px;
  }
}

.ps-footer--3 .ps-footer__middle {
  padding-top: 30px;
}

.ps-footer--3 .ps-footer__box {
  background-color: #f0f2f5;
  border-radius: 4px;
  margin-top: 20px;
  padding: 40px;
  margin-bottom: 45px;
}

.ps-footer--3 .ps-footer--contact {
  padding-top: 0;
}

.ps-footer--3 .ps-footer--contact .ps-footer__title {
  display: none;
}

.ps-footer--3 .ps-footer--contact .ps-footer__fax i {
  display: none;
}

.ps-footer--3 .ps-footer--contact .ps-footer__work {
  padding-left: 0;
  margin-bottom: 30px;
}

.ps-footer--3 .ps-footer--contact hr {
  display: none;
}

.ps-footer--3 .ps-footer--contact .ps-footer__email {
  background-color: white;
  padding: 5px 25px;
  border-radius: 40px;
}

.ps-footer--3 .ps-footer--address {
  padding-top: 30px;
}

.ps-footer--3 .ps-footer--address .ps-logo {
  display: none;
}

.ps-footer--3 .ps-footer--newsletter {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .ps-footer--3 .ps-footer__box {
    padding: 20px 15px;
  }
  .ps-footer--3 .ps-footer--contact .ps-footer__email {
    padding: 5px 10px;
  }
  .ps-footer--3 .ps-footer--address,
  .ps-footer--3 .ps-footer--newsletter {
    padding-top: 0;
  }
}

@media (min-width: 960px) {
  .ps-footer--3 .ps-footer__box {
    padding: 40px;
  }
  .ps-footer--3 .ps-footer--contact .ps-footer__email {
    padding: 5px 25px;
  }
}

.ps-footer--listpage .ps-footer__list {
  margin-bottom: 35px;
}

.ps-footer--listpage .ps-footer__list li {
  padding: 6px 0;
  font-size: 16px;
}

.ps-footer--listpage .ps-footer__list li a {
  display: block;
  color: #5b6c8f;
}

.ps-footer--listpage .ps-footer__list li a:hover {
  color: #fd8d27;
}

.ps-footer--4 {
  background-color: #f0f2f5;
}

.ps-footer--4 .ps-footer--bottom {
  border-color: #d9dee8;
}

.ps-footer--4 .ps-footer--top {
  background-color: white;
}

.ps-footer--5 {
  background-color: #103178;
}

.ps-footer--5 .ps-footer--top {
  background-color: white;
}

.ps-footer--5 .ps-footer--block .ps-block__title,
.ps-footer--5 .ps-footer--block .ps-block__list a {
  color: white;
}

.ps-footer--5 .ps-footer--listpage .ps-footer__list a {
  color: white;
}

.ps-footer--5 .ps-footer--bottom p,
.ps-footer--5 .ps-footer__title,
.ps-footer--5 .ps-footer__email {
  color: white;
}

.ps-footer--5 .ps-footer__box {
  background-color: rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
  border-radius: 4px;
  margin-bottom: 40px;
}

.ps-footer--5 .ps-footer--contact .ps-footer__fax {
  color: white;
}

.ps-footer--5 .ps-footer--contact .ps-footer__fax i {
  display: none;
}

.ps-footer--5 .ps-footer--contact .ps-footer__work {
  padding-left: 0;
  color: #9babcd;
}

.ps-footer--5 .ps-footer--contact hr {
  display: none;
}

.ps-footer--5 .ps-footer--contact .ps-footer__email {
  border: 1px solid #5b6c8f;
  padding: 4px 20px;
  border-radius: 40px;
}

.ps-footer--5 .ps-footer--contact .ps-footer__email:hover {
  background-color: white;
  color: #103178;
}

.ps-footer--5 .ps-footer--address .ps-logo {
  display: none;
}

.ps-footer--5 .ps-footer--address .ps-social__link,
.ps-footer--5 .ps-footer--address p {
  color: #9babcd;
}

.ps-footer--5 .ps-footer--newsletter p {
  color: #9babcd;
}

.ps-footer--5 .ps-footer--bottom {
  border-color: #5b6c8f;
}

.ps-footer--5 .ps-footer--bottom img {
  display: none;
}

.ps-footer--5 .ps-footer--bottom .payment-light {
  display: inline-block;
}

@media (min-width: 768px) {
  .ps-footer--5 .ps-footer__box {
    padding-top: 40px;
  }
}

@media (min-width: 1280px) {
  .ps-footer--5 .ps-footer__box {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ps-footer--8 {
  background-color: #103178;
  padding-top: 30px;
}

.ps-footer--8 .ps-footer--top .row {
  border-color: #284686;
}

.ps-footer--8 .ps-footer--top .col-12 {
  border-color: #284686;
}

.ps-footer--8 .ps-footer--top .ps-footer__link {
  color: #fd8d27;
}

.ps-footer--8 .ps-footer--bottom {
  border-color: #284686;
}

.ps-footer--8 .ps-footer--bottom img {
  display: none;
}

.ps-footer--8 .ps-footer--bottom .payment-light {
  display: inline-block;
}

.ps-footer--8 .ps-footer--bottom p {
  color: #9babcd;
}

.ps-footer--8 .ps-footer--address p,
.ps-footer--8 .ps-footer--address a {
  color: #9babcd;
}

.ps-footer--8 .ps-footer--address .ps-logo img {
  display: none;
}

.ps-footer--8 .ps-footer--address .ps-logo .logo-white {
  display: inline-block;
}

.ps-footer--8 .ps-footer--contact {
  border-left: 1px solid #284686;
  padding-left: 40px;
}

.ps-footer--8 .ps-footer--contact .ps-footer__title,
.ps-footer--8 .ps-footer--contact .ps-footer__fax {
  color: white;
}

.ps-footer--8 .ps-footer--contact .ps-footer__work,
.ps-footer--8 .ps-footer--contact .ps-footer__email {
  color: #9babcd;
}

.ps-footer--8 .ps-footer--contact hr {
  border-color: #284686;
}

.ps-footer--8 .ps-footer--block .ps-block__title {
  color: white;
}

.ps-footer--8 .ps-footer--block .ps-block__list li a {
  color: white;
}

.ps-footer--9 .ps-footer--address .ps-logo img {
  display: none;
}

.ps-footer--9 .ps-footer--address .ps-logo .logo-black {
  display: inline-block;
}

.ps-footer--9 .ps-footer--address .ps-social__link {
  color: #222;
}

.ps-footer--9 .ps-footer--contact .ps-footer__title,
.ps-footer--9 .ps-footer--contact .ps-footer__fax,
.ps-footer--9 .ps-footer--contact .ps-footer__email {
  color: #222;
}

.ps-footer--9 .ps-footer--block .ps-block__title {
  color: #222;
}

.ps-footer--9 .ps-footer--bottom p {
  color: #222;
}

.ps-footer--9 .ps-footer--top .row {
  background-color: #f5f5f5;
}

.ps-footer--9 .ps-footer--top .ps-footer__link {
  color: #222;
}

.ps-footer--9 .ps-footer--top .col-12 {
  border-color: #d9dbde;
}

.ps-footer--12 {
  background-color: #333;
  padding-top: 80px;
}

.ps-footer--12 .ps-footer--address {
  border-right: 1px solid #4b4b4b;
}

.ps-footer--12 .ps-footer--address .ps-logo img {
  display: none;
}

.ps-footer--12 .ps-footer--address .ps-logo .logo-white-all {
  display: inline-block;
}

.ps-footer--12 .ps-footer--address p,
.ps-footer--12 .ps-footer--address a,
.ps-footer--12 .ps-footer--address .ps-social__link {
  color: white;
}

.ps-footer--12 .ps-footer--contact .ps-footer__title,
.ps-footer--12 .ps-footer--contact .ps-footer__fax,
.ps-footer--12 .ps-footer--contact .ps-footer__email,
.ps-footer--12 .ps-footer--contact .ps-footer__work {
  color: white;
}

.ps-footer--12 .ps-footer--contact hr {
  border-color: #4b4b4b;
}

.ps-footer--12 .ps-footer--block .ps-block__title {
  color: white;
}

.ps-footer--12 .ps-footer--block .ps-block__list a {
  color: white;
}

.ps-footer--12 .ps-footer--bottom {
  border-top-color: #4b4b4b;
}

.ps-footer--12 .ps-footer--bottom p {
  color: white;
}

.ps-footer--12 .ps-footer--bottom img {
  display: none;
}

.ps-footer--12 .ps-footer--bottom .payment-light {
  display: inline-block;
}

.ps-footer--12 .ps-footer--top .ps-footer__link {
  color: white;
}

.ps-footer--12 .ps-footer--top .col-12,
.ps-footer--12 .ps-footer--top .row {
  border-color: #4b4b4b;
}

.ps-footer--13 {
  background-color: #f5f5f5;
}

.ps-footer--13 .ps-footer--address .ps-logo img {
  display: none;
}

.ps-footer--13 .ps-footer--address .ps-logo .logo-green {
  display: inline-block;
}

.ps-footer--13 .ps-footer--address .ps-social__link {
  color: #333;
}

.ps-footer--13 .ps-footer--contact .ps-footer__title,
.ps-footer--13 .ps-footer--contact .ps-footer__fax,
.ps-footer--13 .ps-footer--contact .ps-footer__email {
  color: #333;
}

.ps-footer--13 .ps-footer--block .ps-block__title {
  color: #333;
}

.ps-footer--13 .ps-footer--bottom p {
  color: #333;
}

.ps-footer--13 .ps-footer--top .row {
  background-color: transparent;
  border-color: #d9d9d9;
}

.ps-footer--13 .ps-footer--top .ps-footer__link {
  color: #333;
}

.ps-footer--13 .ps-footer--top .col-12 {
  border-color: #d9d9d9;
}

.ps-footer--14 {
  background-color: white;
}

/*5. HELPERS */
.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.ht-84 {
  height: 84px;
}

html .bg--parallax {
  position: relative;
  z-index: 10;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50%;
}

.bg--cover {
  background-position: 50% 50% !important;
  background-size: cover !important;
}

.bg--top {
  background-position: 0% 0% !important;
  background-size: cover !important;
}

.bg--top-right {
  background-position: 100% 100% !important;
  background-size: cover !important;
}

.bg--top-left {
  background-position: 0% 100% !important;
  background-size: cover !important;
}

.bg--bottom {
  background-position: 100% 100% !important;
  background-size: cover !important;
}

.ps-comment--post ul,
.ps-section--block-grid ul,
.ps-bought .ps-bought__thumbnail ul,
.ps-widget .ps-widget__block .ps-widget__content ul,
.ps-table--oriented ul,
.ps-popup--select ul,
.ps-shopping .ps-shopping__content ul,
.ps-section--category ul,
.ps-reviews--3 ul,
.ps-wishlist ul,
.ps-compare--product .ps-table ul,
.ps-menu--slidebar ul,
.ps-header ul,
.ps-footer__middle ul,
.ps-comment--post ol,
.ps-section--block-grid ol,
.ps-bought .ps-bought__thumbnail ol,
.ps-widget .ps-widget__block .ps-widget__content ol,
.ps-table--oriented ol,
.ps-popup--select ol,
.ps-shopping .ps-shopping__content ol,
.ps-section--category ol,
.ps-reviews--3 ol,
.ps-wishlist ol,
.ps-compare--product .ps-table ol,
.ps-menu--slidebar ol,
.ps-header ol,
.ps-footer__middle ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
